import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core'
import { Router, NavigationExtras } from '@angular/router'

import { TrackingService, TrackingEventName } from '@builder/tracking'
import { CurrentUser } from '@builder/users'
import { FeaturesService } from '@builder/common/features/features.service'
import { FEATURE_SIMPLE_COURSE_CREATE } from '@builder/common/features/feature-flag'
@Component({
  selector: 'search-form',
  templateUrl: 'search-form.component.html',
  styleUrls: ['search-form.component.less']
})
export class SearchFormComponent {
  @Input('searchTerm') public searchTerm: string
  @Input('navExtras') public navExtras: NavigationExtras = {}

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef

  public simpleCreateCourseIsEnabled: boolean = false

  constructor(
    private router: Router,
    private trackingService: TrackingService,
    private user: CurrentUser,
    private features: FeaturesService
  ) {
    this.simpleCreateCourseIsEnabled = this.features.isOn(
      FEATURE_SIMPLE_COURSE_CREATE
    )
  }

  public onFormSubmit(event: Event): void {
    event.preventDefault()

    this.searchInput.nativeElement.blur()

    this.trackingService.trigger('search-library' as TrackingEventName, {
      search: this.searchTerm
    })
    sessionStorage.setItem('savedSearchQuery-' + this.user.id, this.searchTerm)

    this.router.navigate(
      ['/search'],
      Object.assign(this.navExtras, { queryParams: { q: this.searchTerm } })
    )
  }

  public clearSearchField(): void {
    this.searchTerm = ''
    sessionStorage.removeItem('savedSearchQuery-' + this.user.id)
    this.router.navigate(['/search'])
  }
}
