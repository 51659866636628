import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { CurrentUser } from "@builder/users";
import { AppTheme } from "../../util/themeSupports";
import { WithDestroy } from "@builder/common/mixins/with-destroy";
import { FeaturesService } from "@builder/common/features/features.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { Locale } from "../../lang/locale";

import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import {
  state,
  style,
  transition,
  trigger,
  animate,
} from "@angular/animations";
import { Observable } from "rxjs";
import { ProfileUrlsService } from "@builder/common/profile-urls/profile-urls.service";
import { FEATURE_USER_PROFILE } from "@builder/common/features/feature-flag";

@Component({
  selector: "account-menu",
  templateUrl: "./account-menu.component.html",
  styleUrls: ["./account-menu.component.less"],
  animations: [
    trigger("accountMenuToggle", [
      state("open", style({ transform: "translateX(0)" })),
      state("closed", style({ transform: "translateX(-100%)" })),
      transition("open <=> closed", [animate("200ms 100ms ease-out")]),
    ]),
  ],
})
export class AccountMenuComponent extends WithDestroy() {
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;

  public isMobileView: boolean = false;
  public userProfileIsEnabled: boolean = false;
  public showAccountMenu: boolean = false;
  public support_site_url;
  public account_details_link: string;
  public notification_settings_link: string;

  constructor(
    public theme: AppTheme,
    public currentUser: CurrentUser,
    public router: Router,
    public locale: Locale,
    private features: FeaturesService,
    private bo: BreakpointObserver,
    appTheme: AppTheme,
    private profileUrls: ProfileUrlsService,
  ) {
    super();

    this.support_site_url = appTheme.value("support_site_url");
    this.userProfileIsEnabled = this.features.isOn(FEATURE_USER_PROFILE);
    const lc: Observable<BreakpointState> = this.bo.observe([
      "(max-width:768px)",
    ]);

    lc.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.isMobileView = result.matches;
    });
  }

  accountAnimationDone(_: any) {
    this.toggleActivatedPanel();
  }

  accountAnimationStart(_: any) {
    this.toggleActivatedPanel();
  }

  toggleAccountMenu() {
    this.showAccountMenu = !this.showAccountMenu;
    this.toggleActivatedPanel();
  }

  toggleActivatedPanel() {
    if (this.showAccountMenu) {
      document.documentElement.classList.add("activated-panel");
    } else {
      document.documentElement.classList.remove("activated-panel");
    }
  }

  ngOnDestroy(): void {
    document.documentElement.classList.remove("activated-panel");
  }

  navigateToAccountDetails() {
    window.location.href = this.profileUrls.accountDetails;
  }

  navigateToNotificationSettings() {
    window.location.href = this.profileUrls.notificationsSettings;
  }
}
