import { Component, Input } from "@angular/core";

import {
  NativePlayerComponent,
  NATIVE_VIDEO_PLAYER_TEMPLATE,
} from "@builder/common/media/video/player/nativePlayer";
import { IPlayer } from "@builder/common/media/video/player/player.base";

@Component({
  selector: "gdrive-player",
  template: NATIVE_VIDEO_PLAYER_TEMPLATE,
  styleUrls: ["nativePlayer.less"],
})
export class GDrivePlayerComponent
  extends NativePlayerComponent
  implements IPlayer
{
  public setSource(url: string): void {
    const uri: URL = new URL(url);
    const id: string = uri.searchParams.get("id");
    const apiKey: string = this.options.apiKey;
    if (!id) {
      console.warn(
        "The Google Drive video you have specified does not seem to have an @id parameter with it",
      );
      return;
    }
    const newUrl: string =
      "https://www.googleapis.com/drive/v3/files/" +
      id +
      "/?key=" +
      apiKey +
      "&alt=media";
    super.setSource(newUrl);
  }
}
