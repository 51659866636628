<div [style.max-width]="maxWidth">
  <h1 mat-dialog-title i18n="whatsNew.title|What's New@@whatsNewTitle">
    What's New
  </h1>

  <div mat-dialog-content class="whats-new-dialogue">
    <div
      class="whats-new-item"
      [ngClass]="{
        'whats-new-item-badge': !this.userPreferences.get(
          'OverviewProductTourOpened'
        )
      }"
    >
      <div class="whats-new-item-text">
        <h6
          i18n="
            whatsNewOverviewTour.title|Header for Overview Tour in the What's
            New section@@whatsNewOverviewTourTitle"
        >
          Take a quick tour of MyAlpha
        </h6>
        <p
          i18n="
            whatsNewOverviewTour.description|Description for Overview Tour in
            the What's New section@@whatsNewOverviewTourDescription"
        >
          We’d love to show you around - it’ll be fast, promise.<br />
          You’ll see where useful features are, so you get off to a flying
          start.
        </p>
      </div>
      <a
        mat-flat-button
        (click)="onConfirm()"
        color="primary"
        class="whats-new-item-button"
        i18n="
          productTour.button|Product Tour - Take a Tour Buttonn@@takeTourButton"
      >
        Take a tour
      </a>
    </div>
  </div>

  <button (click)="close()" id="dialogClose">
    <ab-icon name="close"></ab-icon>
  </button>
</div>
