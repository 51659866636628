import * as React from 'react'
import { FunctionComponent } from 'react'
// import { Button } from '@alpha-international/alpha-ui'

const Example: FunctionComponent<HTMLElement> = () => {
  return (
    <div className='m-8 p-8 border-solid border-2 border-indigo-600 rounded-md drop-shadow-sm'>
      <div className='px-4 sm:px-0'>
        <h3 className='text-base font-semibold leading-7 text-gray-900'>
          Applicant Information
        </h3>
        <p className='mt-1 max-w-2xl text-sm leading-6 text-gray-500'>
          Personal details and application.
        </p>
      </div>
      <div className='mt-6 border-t border-gray-100'>
        <dl className='divide-y divide-gray-100'>
          <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
            <dt className='text-sm font-medium leading-6 text-gray-900'>
              Full name
            </dt>
            <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
              Margot Foster
            </dd>
          </div>
          <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
            <dt className='text-sm font-medium leading-6 text-gray-900'>
              About
            </dt>
            <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
              Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
              incididunt cillum culpa consequat. Excepteur qui ipsum aliquip
              consequat sint. Sit id mollit nulla mollit nostrud in ea officia
              proident. Irure nostrud pariatur mollit ad adipisicing
              reprehenderit deserunt qui eu.
              <br />
              <br />
              {/* <Button
                label='Button'
                onClick={() => {
                  console.log('click AlphaUI Button')
                }}
                primary
              /> */}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

export default Example
