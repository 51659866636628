<button
  [disabled]="disabled"
  [attr.variant]="variant"
  [attr.color]="color"
  [attr.size]="size"
  (mousedown)="onMouseDown()"
  (click)="onClick()"
>
  <ng-content></ng-content>
</button>
