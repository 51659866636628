<div>
  <p class="text-wrap" i18n="common.cookie|Cookie Consent@@cookieConsent">
    We use cookies to make your experience of our website better. In compliance
    with E-Privacy Regulations, we are required to inform you that by continuing
    to browse on this site you are consenting to the use of these cookies.
  </p>

  <a
    id="viewLink"
    class="active"
    *ngIf="hasCookiePolicyLink"
    link="terms-and-conditions"
    i18n="users.message|View Terms Conditions@@viewTCLink"
    >View our terms &amp; conditions and privacy policy here.</a
  >
</div>

<button
  mat-flat-button
  color="primary"
  id="dismissBanner"
  data-qa-name="auth-button-dismissBanner"
  (click)="onAgree()"
>
  <span
    class="btn-label"
    i18n="dismiss.cookie|Cookie dismiss button@@cookieDismiss"
    >Dismiss</span
  >
</button>
