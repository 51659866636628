<h2
  mat-dialog-title
  i18n="
    searchFiltersDialog.text|Search Filters Dialog - Filter Search
    Results@@searchFiltersDialogTitle"
>
  Filter Search Results
</h2>

<ab-icon
  class="search-filters-dialog-close"
  name="close"
  (click)="close()"
></ab-icon>

<div mat-dialog-content>
  <search-filter
    [filterDefaults]="selectedFilters"
    (filters)="handleValueChange($event)"
  ></search-filter>
</div>

<div mat-dialog-actions align="center">
  <button
    (click)="save()"
    mat-flat-button
    color="primary"
    i18n="
      searchFiltersDialog.text|Search Filters Dialog - Apply
      Filters@@searchFiltersDialogAction"
  >
    Apply Filters
  </button>
</div>
