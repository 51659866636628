import { parseISO } from "date-fns";

export class Post {
  public id: number;
  public title: string;
  public content: string;
  public excerpt: string;
  public slug: string;
  public name: string;
  public parent: number;
  public type: string;

  public blog_id: number;

  public edit_url: string;

  /**
   * Date
   */
  public set date(val) {
    if (val instanceof Date) {
      this._date = val;
    } else {
      this._date = parseISO(val as any);
    }
  }
  public get date(): Date {
    return this._date;
  }
  private _date: Date;

  constructor(data: any = {}) {
    Object.assign(this, { ...data });
  }

  public toJSON() {
    return Object.assign({}, this, {
      date: new Date(this.date),
    });
  }
}
