import * as React from 'react'
import { FunctionComponent } from 'react'
import {
  IoCalendarOutline as DateIcon,
  IoTimeOutline as TimeIcon,
  IoLocationOutline as AddressIcon
} from 'react-icons/io5'
import { MdEdit as EditIcon } from 'react-icons/md'
import { HiDuplicate as DuplicateIcon } from 'react-icons/hi'
import { Button } from '@alpha-international/alpha-ui'
import ProductCardDetail from './product-card-detail'
import ProductCardPill from './product-card-pill'

export interface ProductCardProps {
  title: string
  date: number
  address: string
  showPrivateTag?: boolean
  showAdminTag?: boolean
  showDuplicateButton?: boolean
  image: string
  handleClick: () => {}
}
// new Date(dateobject ).toLocaleDateString()
const ProductCard: FunctionComponent<ProductCardProps> = ({
  title,
  date,
  address,
  showPrivateTag,
  showAdminTag,
  showDuplicateButton,
  image,
  handleClick = () => {
    console.log('ProductCard handleClick')
  }
}) => {
  const formattedDate = new Date(date).toLocaleDateString(navigator.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long'
  })

  const formattedTime = new Date(date).toLocaleDateString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit'
  })

  return (
    <div className='flex px-8 py-6 gap-4 rounded-lg bg-alpha-white border border-alpha-oatmeal-10'>
      <div className='flex flex-col justify-between gap-4'>
        <div className='flex gap-2'>
          {title ? (
            <h3 className='mt-0 mb-[3px] leading-none'>{title}</h3>
          ) : null}
          {showPrivateTag ? <ProductCardPill variant='private' /> : null}
          {showAdminTag ? <ProductCardPill variant='administrator' /> : null}
        </div>
        <div className='flex flex-col gap-[3px]'>
          {date ? (
            <ProductCardDetail icon={DateIcon} text={formattedDate} />
          ) : null}
          {date ? (
            <ProductCardDetail icon={TimeIcon} text={formattedTime} />
          ) : null}
          {address ? (
            <ProductCardDetail icon={AddressIcon} text={address} />
          ) : null}
        </div>
        <div className='flex justify-between content-center font-body'>
          <Button
            onClick={handleClick}
            className='flex gap-2 justify-center text-sm rounded-[8px] items-center font-medium'
          >
            <EditIcon size={'1rem'} color='white' />
            {$localize`:common.button.label|Preview Alpha - Edit Details@@editDetails:Edit details`}
          </Button>
          {showDuplicateButton ? (
            <Button
              onClick={handleClick}
              size='small'
              className='flex gap-2 justify-center text-sm font-medium rounded-[8px] items-center !text-mya-link bg-transparent hover:bg-slate-100'
            >
              <DuplicateIcon size={'1rem'} />
              {$localize`:common.button|Duplicate Button@@duplicateButton:Duplicate`}
            </Button>
          ) : null}
        </div>
      </div>
      <div
        className='h-52	w-52 aspect-square'
        style={{
          backgroundImage: image ? `url(${image})` : '',
          backgroundColor: '#D6D2D0'
        }}
      ></div>
    </div>
  )
}

export default ProductCard
