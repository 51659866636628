import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { CurrentUser } from '@builder/users'
import { Resource } from '@builder/resources/resource'
import { AppTheme } from '@builder/common/util/themeSupports'
import { AppInjector } from '@builder/app.injector'
import { Product } from '@builder/products/product'
import { SITE_URL } from '@builder/common/baseHref.provider'
import { FeaturesService } from '@builder/common/features/features.service'
import {
  FEATURE_AYS_LAUNCH,
  FEATURE_CINDERELLA
} from '@builder/common/features/feature-flag'
import { BRIGHTCOVE } from '@builder/common/media/video/videoService'
import { Locale } from '@builder/common/lang/locale'
import { TrackingEventName, TrackingService } from '@builder/tracking'
import { VideoService } from '@builder/common/media/video'
import { ReactService } from '@builder/common/react-wrapper/react.service'
interface IDownload {
  name: string
  width: number
  height: number
  link: string
  expires: string
  fps: number
  md5: string
  size: number
  type: string
  quality: string
  rendition: string
}
@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private url = 'wp-json/wp/v2/resource'
  public resourceMap: Map<string, Resource>
  private productInfo
  private productIdTitleMap
  public isAysLaunchEnabled: boolean
  public isCinderellaEnabled: boolean
  // set if there's only 1 audio language available
  public audioLanguage: string

  public videoOptions = []
  public selectedVideoOption: any = null
  public showOptions: boolean
  // current set of file download options
  public downloadOptions: any
  // currently selected video download option
  public currentDownload: IDownload
  // are we loading download links?
  public loading = false
  // if we are requesting an expired download and it no longer exists
  public notFoundError = false

  constructor(
    private http: HttpClient,
    private user: CurrentUser,
    private appTheme: AppTheme,
    private features: FeaturesService,
    private locale: Locale,
    private trackingService: TrackingService,
    private _videosService: VideoService,
    private reactService: ReactService,

    @Inject(SITE_URL) private siteUrl: string
  ) {
    this.resourceMap = new Map()
    this.productInfo = this.appTheme.value('preview_resources').products
    this.features
      .whenChanged(FEATURE_AYS_LAUNCH)
      .subscribe((status) => (this.isAysLaunchEnabled = status.enabled))

    this.features
      .whenChanged(FEATURE_CINDERELLA)
      .subscribe((status) => (this.isCinderellaEnabled = status.enabled))

    this.productIdTitleMap = this.productInfo.reduce((a, b) => {
      return {
        ...a,
        [b.id]: b.title
      }
    }, {})
  }

  /**
   * Map resources within buckets into Resource classes and add them to our map
   */
  public mapResources(resources) {
    return resources.map((resource) => {
      if (resource.buckets) {
        resource.buckets.forEach((bucket) => {
          bucket.items = bucket.items.map((r) => {
            const res = new Resource(r)
            this.resourceMap.set(res.blog_id + '-' + res.id, res)
            return res
          })
        })

        return resource
      } else {
        const res = new Resource(resource)
        this.resourceMap.set(res.blog_id + '-' + res.id, res)
        return res
      }
    })
  }

  /**
   * Get resources
   * @param params
   */
  public getResources(params: any = {}): Observable<Resource[]> {
    return this.http
      .get<any[]>(this.url, {
        params
      })
      .pipe(
        map((resources) => this.mapResources(resources)),
        tap((resources) => {
          resources.forEach((resource) => {
            if (resource.buckets) {
              resource.buckets.forEach((b) =>
                b.items.forEach((r) => {
                  this.assignResourceErrors(r)
                  this.assignResourceProduct(r)
                })
              )
            } else {
              this.assignResourceErrors(resource)
              this.assignResourceProduct(resource)
            }
          })
        })
      )
  }

  public getMappedResource(id: number, blogId: number = 0): Resource {
    const found = this.resourceMap.get(blogId + '-' + id)
    return found
  }

  public updateResourceViewCount(id: number, blog_id: number): Observable<any> {
    return this.http.patch(
      'wp-json/wp/v2/blog/' + blog_id + '/post/' + id + '/viewcount',
      {}
    )
  }

  public updateResourceDownloadCount(
    id: number,
    blog_id: number
  ): Observable<any> {
    return this.http.patch(
      'wp-json/wp/v2/blog/' + blog_id + '/post/' + id + '/downloadcount',
      {}
    )
  }

  public assignResourceErrors(resource: Resource): void {
    const errors: any = {}

    /**
     * If the user is not currently running a Product in use by a resource, mark it with an error which will prompt the user
     */
    if (resource.products && resource.products.length) {
      const userHasResourceProduct = !this.user.alphaStats
        ? false
        : resource.products.find((rp) =>
            this.user.alphaStats.activeProducts.find(
              (ap) => ap.id === rp.id && ap.blog_id === rp.blog_id
            )
          )

      if (
        !userHasResourceProduct && // they aren't running the product
        (resource.categories.includes('talks') ||
          resource.categories.includes('team-training')) // and it's either a talk or team-training
      ) {
        const product = this.productInfo.find(
          (p) =>
            p.id === resource.products[0].id &&
            p.blog_id === resource.products[0].blog_id
        )

        errors.productAccess = {
          title: product ? product.title : 'Missing Product Name',
          slug: product ? product.slug : null
        }
      }
    }

    if (Object.keys(errors).length) {
      resource.error = errors
    }
  }

  public assignAccessResourceErrors(
    resource: Resource,
    userActiveProducts: Product[]
  ): void {
    resource.error = null

    const errors: any = {}

    /**
     * If the user is not currently running a Product in use by a resource, mark it with an error which will prompt the user
     */
    if (resource.products && resource.products.length) {
      const userHasResourceProduct = resource.products.some((rp) =>
        userActiveProducts.some(
          (ap) => ap.id === rp.id && ap.blog_id === rp.blog_id
        )
      )

      if (
        !userHasResourceProduct && // they aren't running the product
        (resource.categories.includes('talks') ||
          resource.categories.includes('team-training')) // and it's either a talk or team-training
      ) {
        const product = this.productInfo.find(
          (p) =>
            p.id === resource.products[0].id &&
            p.blog_id === resource.products[0].blog_id
        )

        errors.productAccess = {
          title: product ? product.title : 'Missing Product Name',
          slug: product ? product.slug : null
        }
      }
    }

    if (Object.keys(errors).length) {
      resource.error = errors
    }
  }

  public assignResourceProduct(resource: Resource): void {
    if (resource.products && resource.products.length) {
      resource.products = resource.products.map((obj) => {
        return {
          ...obj,
          title: this.productIdTitleMap[obj.id]
        }
      })
    }
  }

  downloadDocument(url: string, title: string) {
    const a = document.createElement('a')
    const hrefUrl = url.startsWith('/')
      ? this.siteUrl + url
      : this.siteUrl + new URL(url).pathname

    a.href = hrefUrl
    const extension = hrefUrl.split('/').pop().split('.').pop()
    a.download = title + '.' + extension
    a.target = '_blank'
    a.click()
  }

  onDownload(resource: Resource): void {
    const {
      id,
      blog_id,
      title,
      attachedDocument: { url }
    } = resource
    this.updateResourceDownloadCount(id, blog_id).subscribe()
    this.downloadDocument(url, title)
  }

  getIsAysLaunchEnabledFlag() {
    return this.isAysLaunchEnabled
  }

  public selectDownload(downloadOption): void {
    this.currentDownload = downloadOption
    this.notFoundError = false
  }

  openReactVideoPlayerModal(inputResource: Resource | any) {
    const resource = { ...inputResource }
    try {
      if (!resource.video?.language) {
        this._videosService.getVideo(resource).subscribe((video) => {
          resource.video = video

          this.renderReactVideoPlayerModal(resource)
        })
      } else {
        this.renderReactVideoPlayerModal(resource)
      }
    } catch (e) {
      console.error(e)
    }
  }

  renderReactVideoPlayerModal(resource: Resource | any) {
    const { provider } = resource.video

    if (!provider) {
      console.error('A valid video provider is not selected.')
      return
    }

    if (provider === BRIGHTCOVE && !this.getIsAysLaunchEnabledFlag) {
      console.error('Brightcove videos are not currently enabled.')
      return
    }

    if (!this.isCinderellaEnabled) {
      resource.relatedDocuments = null
    }

    this.reactService.render('VideoPlayerModal', {
      ...resource,
      downloadTrackingByResolutionAndTitle: (resolution, episodeName) =>
        this.trackingService.trigger(TrackingEventName.DownloadVideo, {
          resolution,
          episodeName
        }),
      fetchDownloadLinkById: (id) => this._videosService.fetchDownloadLink(id),
      localeTranslate: (id, defaultValue) =>
        this.locale.translate(id, defaultValue),
      resourceClickHandler: (resource) => this.onDownload(resource)
    })
  }
}

/**
 * @decorator FetchResource
 *
 * Tap into the RestPathMatch decorator, taking it's blog_id, id, and returning a Resource object
 */
export function FetchResource(): MethodDecorator {
  return function (
    componentInstance: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const resourceService = AppInjector.get(ResourceService)

    const nextValue = descriptor.value
    descriptor.value = function (...args) {
      const ci = this
      const id = parseInt(args[1], 10)
      const blog_id = parseInt(args[0], 10)
      const resource = resourceService.getMappedResource(id, blog_id)
      nextValue && nextValue.apply(ci, [resource])
    }

    return descriptor
  }
}
