import * as React from 'react'
import { createContext, useState, Dispatch, SetStateAction } from 'react'
import VideoPlayerModalComponent from './video-player-modal-component'
import { processVideoProps, VideoProps } from './video-player-utils'
import { ResourceClickHandlerContext } from '../context'

export const VideoPlayerModalContext = createContext<VideoProps | null>(null)
export const VideoPlayerModalShareUrlContext = createContext<{
  shareUrl: string
  setShareUrl: Dispatch<SetStateAction<string>>
}>(null)

const VideoPlayerModal = ({ resourceClickHandler, ...otherProps }) => {
  let processedProps
  try {
    processedProps = processVideoProps(otherProps)
  } catch (error) {
    throw error
  }
  const [shareUrl, setShareUrl] = useState('')

  return (
    <ResourceClickHandlerContext.Provider value={resourceClickHandler}>
      <VideoPlayerModalContext.Provider value={processedProps}>
        <VideoPlayerModalShareUrlContext.Provider
          value={{
            shareUrl,
            setShareUrl
          }}
        >
          <VideoPlayerModalComponent />
        </VideoPlayerModalShareUrlContext.Provider>
      </VideoPlayerModalContext.Provider>
    </ResourceClickHandlerContext.Provider>
  )
}

export default VideoPlayerModal
