import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { MatDialog } from "@angular/material/dialog";

import { Observable } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

import { Alpha } from "../alphas/alpha";
import { CurrentUser, User } from "../users/user";
import { TrackingService } from "@builder/tracking";

import { AuthHttp } from "../http";

import { ProfileStorageProvider } from "@builder/users/local-profile.provider";

import { TeamWelcomeComponent } from "./team-welcome/team-welcome.component";

@Injectable()
export class TeamService  {
  private _teamEndpoint = "wp-json/wp/v2/team";
  private _alphaEndpoint = "wp-json/wp/v2/alpha";

  constructor(
    private currentUser: CurrentUser,
    private http: HttpClient,
    private authHttp: AuthHttp,
    private router: Router,
    private profileProvider: ProfileStorageProvider,
    public dialog: MatDialog,
    private trackingService: TrackingService,
  ) {}

  /**
   * Verify /team/ invite urls
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params.id;

    this.profileProvider.clear();

    return this.http.get(`${this._teamEndpoint}/validate/${id}`).pipe(
      catchError((err) => {
        // if there is a redirect uri requested ( sso ), navigate to it.
        if (err.status === 302 && err.error?.data?.url) {
          document.location = err.error.data.url;
          return null;
        }

        /**
         * If the user has incomplete details ( subscriber or missing name fields ) open a dialog to collect required information
         */
        return this.dialog
          .open(TeamWelcomeComponent, {
            width: "480px",
            disableClose: true,
            data: err.error,
          })
          .afterClosed();
      }),

      /**
       * After dialog success, redirect to signon, or their next active alpha
       */
      switchMap((user) =>
        this.router.navigate(["/user/signon"], {
          queryParams: { userlogin: user.email },
          state: { redirect: user.redirect },
        }),
      ),
    );
  }

  /**
   * Get Team Members for an Alpha
   */
  public getTeamMembersByAlpha(alpha: Alpha): Observable<any> {
    return this.authHttp.get(`${this._alphaEndpoint}/${alpha.id}/team`);
  }

  /**
   * Add Team Members to an Alpha
   */
  public addTeamMemberToAlpha(
    alpha: Alpha,
    team_members: { email: string; role: string }[],
    options: any = {},
  ): Observable<any> {
    return this.authHttp.post(`${this._alphaEndpoint}/${alpha.id}/team`, {
      team_members,
      options,
    });
  }

  /**
   * Remove a Team Member from an Alpha
   * @param email
   */
  public removeTeamMemberFromAlpha(
    alpha: Alpha,
    user_id: number,
  ): Observable<any> {
    return this.authHttp.delete(`${this._alphaEndpoint}/${alpha.id}/team`, {
      body: { user_id },
    });
  }

  /**
   * Update the role for a team member
   */
  public updateTeamMemberRole(
    alpha_id: number,
    user_id: number,
    role_id: number,
  ): Observable<any> {
    return this.authHttp.post(
      `${this._alphaEndpoint}/${alpha_id}/team/${user_id}/role`,
      { role_id },
    );
  }

  /**
   * Send a team member their invitation
   * @param alpha
   * @param email
   */
  public sendTeamMemberInvitationToAlpha(
    alpha: Alpha,
    teamMember: User,
  ): Observable<any> {
    return this.authHttp.post(
      `${this._alphaEndpoint}/${alpha.id}/team/${teamMember.id}/resend-invitation`,
      {},
    );
  }
}
