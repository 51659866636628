import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { BuilderMaterialModule } from '@builder/common/ng-material'
import { TrackingModule } from '@builder/tracking/tracking.module'
import { SearchFormModule, PopularSearchTermsModule } from '@builder/search'
import { BuilderIconModule } from '../icons/ab-icon.module'
import { AlphaLocaleModule } from '../lang/locale.module'

import { OldMobileMenuComponent } from './old-mobile-menu.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AlphaLocaleModule,
    BuilderMaterialModule,
    BuilderIconModule,
    AlphaLocaleModule,
    SearchFormModule,
    PopularSearchTermsModule,
    TrackingModule
  ],
  declarations: [OldMobileMenuComponent],
  exports: [OldMobileMenuComponent]
})
export class OldMobileMenuModule {}
