import { HttpParams } from "@angular/common/http";
import { isObject, isArray } from "@builder/common/util";

/**
 * Form encode an object for url gets
 * @param obj
 * @param prefix
 */
const prepare = (obj, prefix = ""): string => {
  const parts: Array<string> = [];

  for (const prop in obj) {
    const value: any = obj[prop];
    if (isObject(value)) {
      for (const childProp in value) {
        parts.push(prefix + prop[childProp] + "=" + prepare(prop[childProp]));
      }
    } else if (isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        const key: string = prop + "[" + i + "]";
        parts.push(prefix + prepare(value[i], key));
      }
    } else {
      const propKey: string = prefix !== "" ? "[" + prop + "]" : prop;
      parts.push(prefix + propKey + "=" + encodeURIComponent(value));
    }
  }

  return parts.join("&");
};

export class HttpFormParams extends HttpParams {
  constructor(obj: object) {
    const str: string = prepare(obj);

    super({
      fromString: str,
    });
  }
}
