<div>
  <nav data-qa-name="manageAlphas-container-nav">
    <a
      routerLinkActive="menu-item-current"
      *ngIf="currentUser.loggedIn()"
      [routerLink]="['/dashboard']"
      class="menu-item"
      data-qa-name="manageAlphas-link-dashboard"
    >
      <ab-icon name="house" size="16"></ab-icon>
      <span i18n="common.words|Dashboard@@commonWordDashboard">Dashboard</span>
    </a>
    <a
      routerLinkActive="menu-item-current"
      *ngIf="currentUser.loggedIn()"
      [routerLink]="['/alphas']"
      class="menu-item"
      data-qa-name="manageAlphas-link-myAlphaCourses"
    >
      <ab-icon name="computer"></ab-icon>
      <span i18n="alpha.myAlpha|My Alphas Courses@@MyAlphasCourses"
        >My Alpha Courses</span
      >
    </a>

    <a
      routerLinkActive="menu-item-current"
      [routerLink]="['/preview-resources']"
      class="menu-item"
      data-qa-name="manageAlphas-link-previewResources"
      [attr.data-driver]="'overview1'"
    >
      <ab-icon name="tvstand"></ab-icon>
      <span
        i18n="common.resources|Preview Resources Label@@previewResourcesLabel"
        >Preview Resources</span
      >
    </a>

    <a
      routerLinkActive="menu-item-current"
      *ngIf="
        !theme.supports('disable_training') && locale.trainingCenter.enable
      "
      [routerLink]="['/training']"
      class="menu-item"
      data-qa-name="manageAlphas-link-learningCenter"
      [attr.data-driver]="'overview3'"
    >
      <ab-icon name="book"></ab-icon>
      <span i18n="training|Learning Centre Title@@learningCenterTitle"
        >Learning Centre</span
      >
    </a>

    <a
      class="menu-item"
      *ngIf="theme.value('support_site_url')"
      [href]="theme.value('support_site_url')"
      target="_blank"
      data-qa-name="manageAlphas-link-helpAndSupport"
      [attr.data-driver]="'overview7'"
    >
      <ab-icon name="support"></ab-icon>
      <span i18n="common.labels|Menu Support Label@@commonLabelsHelpnSupport"
        >Help and support</span
      >
    </a>

    <a
      class="menu-item"
      *ngFor="let page of additionalMenuItems"
      [routerLink]="['/' + page.slug]"
      data-qa-name="manageAlphas-link-additional-menu-item"
    >
      <ab-icon [name]="page.icon"></ab-icon>
      <a [innerHTML]="page.title"></a>
    </a>

    <div>
      <div class="menu-search-divider"></div>

      <a
        [class.menu-item-current]="isSearchPage"
        (click)="toggleSearch()"
        class="menu-item"
        id="menu-search"
        data-qa-name="manageAlphas-link-search"
        [attr.data-driver]="'overview4'"
      >
        <ab-icon name="search"></ab-icon>
        <span i18n="common.words|Search@@commonWordSearch">Search</span>
      </a>
    </div>
  </nav>

  <ng-container *ngIf="currentUser.loggedIn() && !coraEnabled">
    <a
      mat-flat-button
      *ngIf="!simpleCreateCourseIsEnabled"
      id="navCreateAlphaButton"
      color="primary"
      [routerLink]="['/alphas/new/step/1']"
      [state]="{ ack: true }"
      data-qa-name="manageAlphas-button-createAnAlpha"
      i18n="common.buttons|Create an Alpha@@buttonCreateAlpha"
      track-event="create-alpha-click"
      [attr.data-driver]="'overview8'"
    >
      Create an Alpha</a
    >

    <a
      mat-flat-button
      *ngIf="simpleCreateCourseIsEnabled"
      id="navCreateAlphaButton"
      color="primary"
      [routerLink]="['/preview-resources']"
      [state]="{ ack: true }"
      data-qa-name="manageAlphas-button-selectACourse"
      i18n="common.buttons|Select a course button@@selectCourseButton"
      track-event="create-alpha-click"
      [attr.data-driver]="'overview8'"
    >
      Select a course</a
    >
  </ng-container>
</div>

<p class="version" i18n="common.version|Version and year@@versionYear">
  Version {{ versionAndYear // i18n: (ph="version_and_year") }}
</p>
