import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '../../environments/environment';

@Injectable()
export class Insights {
    private angularPlugin:AngularPlugin = new AngularPlugin();
    private appInsights:ApplicationInsights;
 
    constructor(private router: Router) {

      if ( environment.APP_INSIGHT_CON_STRING ) {
        this.appInsights = new ApplicationInsights({
          config: {
              connectionString: environment.APP_INSIGHT_CON_STRING,
              disableFetchTracking: true,
              disableAjaxTracking: true,
              disableXhr: true,
              extensions: [this.angularPlugin],
              extensionConfig: {
                  [this.angularPlugin.identifier]: {
                      router: this.router,
                      errorServices: [new ErrorHandler()],
                  },
              },
          },
        });
        this.appInsights.addTelemetryInitializer( t => {
          return ! [ "PageviewPerformanceData", "PageView", "PageviewData" ].includes( t.baseType);
        });
        this.appInsights.loadAppInsights();
      }      
    }
 
    trackEvent(name: string): void {
      if ( this.appInsights ) {
        this.appInsights.trackEvent({ name });
      }
    }
 
    trackTrace(message: string): void {
      if ( this.appInsights ) {
        this.appInsights.trackTrace({ message });
      }
    }

    trackException(exception: Error, severityLevel?: number) {
      if ( this.appInsights ) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
      }
    }

    
	/**
	 * Track event where the currentUser object is incomplete using App Insights service
	 * ADP-2435: Tracking incomplete profile issue
	 */
	public trackProfileException(profile): void {
		let userObjectString = "";
		try {
      userObjectString = JSON.stringify(profile);
      const err = new Error("Profile object is invalid. Profile: " + userObjectString);
      this.trackException(err);
      this.trackTrace(err.stack);

		} catch (e) {
      const err = new Error("Profile object is invalid. Unable to stringify currentUser object. May be undefined.", { cause: e });
      this.trackException(err);
      this.trackTrace(err.stack);
		}
	}
    
}