import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "signon-error",
  template: `<p class="code-{{ error.code }}" [innerHTML]="error.message"></p>`,
  styles: [
    `
      :host {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
      }
    `,
  ],
})
export class SignonErrorComponent {
  public error: { code: string; message: string };

  constructor(
    public dialogRef: MatDialogRef<SignonErrorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { error: { code: string; message: string } },
  ) {
    this.error = data.error;
  }
}
