<ng-template #progressTemplate let-pct="pct">
  <div class="progress-indicator">
    <ng-container *ngIf="pct < 1">
      <span
        class="progress-indicator-text"
        i18n="common.words|Progress indication@@progressIndicator"
        >Progress:</span
      >
      &nbsp;<span class="progress-indicator-value">{{ pct | percent }}</span>
    </ng-container>

    <ng-container *ngIf="pct === 1">
      <span
        class="progress-indicator-text"
        i18n="common.words|Completed@@commonWordsCompleted"
        >Completed</span
      >
    </ng-container>
  </div>
</ng-template>

<span class="spinner">
  <mat-progress-spinner
    id="background"
    [diameter]="diameter"
    color="accent"
    [strokeWidth]="strokeWidth"
    [value]="100"
  >
  </mat-progress-spinner>

  <mat-progress-spinner
    id="fill"
    [diameter]="diameter"
    color="accent"
    [strokeWidth]="strokeWidth"
    [value]="value * 100"
  >
  </mat-progress-spinner>
</span>

<span
  *ngIf="!value && showNotStarted"
  class="progress-indicator-not-started"
  i18n="common.words|Not Started@@commonWordsNotStarted"
  >Not Started</span
>

<ng-template
  *ngIf="!showNotStarted || value"
  [ngTemplateOutlet]="progressTemplate"
  [ngTemplateOutletContext]="{ pct: value }"
>
</ng-template>
