// import { SignonOptions } from "@builder/signon/signon/signon-options";
import { ProfileStorageProvider } from "@builder/users/local-profile.provider";

export interface ISignonOptions {
  isAdminQA?: boolean;
  skipSalesForce?: boolean;
}

// @Injectable()
export class SignonOptions implements ISignonOptions {
  public isAdminQA: boolean;
  public skipSalesForce: boolean;

  constructor(data: any = {}) {
    Object.assign(this, data);
  }
}

/**
 * Settings provider to get settings from embedded js vars
 */
export const signonOptionsProviderFactory = (
  profileProvider: ProfileStorageProvider,
) => {
  const t: string = profileProvider.getToken();
  let options: any = {};
  // The 2 SignonOptions (isAdminQA, skipSalesforce) have been deprecated.
  // if ( t ) {
  // 	const dec = new JwtHelper().decodeToken( t );
  // 	options = dec && dec.data.options ? dec.data.options : {};
  // }
  return new SignonOptions(options);
};

export const SIGNON_OPTIONS = [
  {
    provide: SignonOptions,
    useFactory: signonOptionsProviderFactory,
    deps: [ProfileStorageProvider],
  },
];
