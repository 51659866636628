<button
  matButton
  matBadge="8"
  matBadgePosition="before"
  matBadgeColor="accent"
  (click)="openWhatsNewModal()"
  [attr.data-driver]="'overview9'"
  class="whats-new-btn header-button"
>
  <span
    *ngIf="!this.userPreferences.get('OverviewProductTourOpened')"
    class="new-items-badge"
    >{{ newItemsCount }}</span
  >
  <div class="header-button-icon">
    <ab-icon name="notification" size="20"></ab-icon>
  </div>
</button>
