import * as React from 'react'
import { FunctionComponent } from 'react'
import { Loader } from '@alpha-international/alpha-ui'
import { Resource } from '@builder/resources'
import ResourceList from '../resource-list'
import TabContentContainer from '../tab-content-container'
import { ResourceClickHandlerContext } from '../context'

const PRODUCT_RESOURCES_NAME = 'talks'
const OTHER_RESOURCES_NAME = 'toolbox'

interface Bucket {
  items: Resource[]
  label: string
  name: string
}

interface Materials {
  buckets: Bucket[]
  description: string
  label: string
  name: string
}

export interface SeriesMaterialsPageProps {
  materialsResponse: Materials[]
  seriesName: string
  resourceClickHandler: () => {}
}

const SeriesMaterialsPage: FunctionComponent<SeriesMaterialsPageProps> = ({
  materialsResponse,
  seriesName,
  resourceClickHandler
}) => {
  if (!materialsResponse || !seriesName) {
    return (
      <div className='w-full flex justify-center align-center lg:py-80 md:py-60 sm:py-40 py-20'>
        <Loader />
      </div>
    )
  }

  const extractAndMapResourceList = ({
    type,
    thumbnail,
    title,
    relatedDocuments,
    attachedDocument,
    description,
    linkURL
  }) => ({
    type,
    image: thumbnail?.url,
    title,
    description,
    relatedDocuments,
    attachedDocument,
    linkURL
  })

  const featuredResources = materialsResponse
    .find((bucket) => PRODUCT_RESOURCES_NAME === bucket.name)
    ?.buckets?.at(0)
    ?.items.map(extractAndMapResourceList)

  const otherResourceBuckets = materialsResponse
    .find((bucket) => OTHER_RESOURCES_NAME === bucket.name)
    ?.buckets?.map(({ label, items }) => ({
      title: label,
      resources: items.map(extractAndMapResourceList)
    }))

  return (
    <ResourceClickHandlerContext.Provider value={resourceClickHandler}>
      <TabContentContainer>
        <ResourceList
          title={seriesName}
          resources={featuredResources}
          isFeatured
        />
        {otherResourceBuckets?.length
          ? otherResourceBuckets.map(({ title, resources }) => (
              <ResourceList key={title} title={title} resources={resources} />
            ))
          : null}
      </TabContentContainer>
    </ResourceClickHandlerContext.Provider>
  )
}

export default SeriesMaterialsPage
