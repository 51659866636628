import {
  Component,
  forwardRef,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { AlphaFieldOptions } from "../fieldOptions";

import { find } from "../../util";

@Component({
  selector: "team-roles-select",
  templateUrl: "team-role-select.component.html",
  styleUrls: ["team-role-select.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TeamRoleSelectComponent),
      multi: true,
    },
  ],
})
export class TeamRoleSelectComponent implements ControlValueAccessor {
  @ViewChild("selectField", { static: true }) select: ElementRef;

  public selectedRole: number;

  public roles: Array<any>;

  public placeholderOptionIsDisabled: boolean;

  constructor(
    private _fieldOptions: AlphaFieldOptions,
    private _changeDetector: ChangeDetectorRef,
  ) {
    this.roles = [];

    // Get the localities
    this._fieldOptions.teamRoles().subscribe((result) => {
      this.roles = result;
      this._changeDetector.markForCheck();
    });
  }

  setDisabledState(disabled) {
    this.select.nativeElement.disabled = disabled;
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  writeValue(value: any) {
    this.selectedRole = value;
    this._changeDetector.markForCheck();
    this.placeholderOptionIsDisabled = Boolean(this.selectedRole);
  }

  roleChanged(event) {
    const roleId = parseInt(event.target.value);
    const role = find(this.roles, { value: roleId }) || null;
    this.selectedRole = roleId;
    this.placeholderOptionIsDisabled = Boolean(this.selectedRole);
    this.propagateChange(role);
  }
}
