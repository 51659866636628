import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpResponse } from '@angular/common/http'

import { Term } from '@builder/common/models/term'
import { Playlist } from '../playlists'
import { PlaylistService } from '../playlists/playlist-service'
import { Lesson } from '../lessons'
import { ProfileStorageProvider } from '@builder/users/local-profile.provider'
import { PostAlphaSurvey } from '@builder/alphas/surveys/post-alpha-survey'
import { AppTheme } from '@builder/common'
import { FeaturesService } from '@builder/common/features/features.service'
import {
  FEATURE_USER_PROFILE,
  FEATURE_CORA
} from '@builder/common/features/feature-flag'
import { CurrentUser } from '@builder/users'
/**
 * Training Entry Component
 */
@Component({
  selector: 'training-dashboard',
  templateUrl: 'training-dashboard.component.html',
  host: {
    '[class.profile-updated]': 'userProfileIsEnabled'
  },
  styleUrls: ['training-dashboard.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrainingDashboardComponent {
  public modules: Array<Playlist>

  public popularVideos: Array<Lesson>
  public popularArticles: Array<Lesson>
  public categories: Array<Term>
  public userSurveys: Array<PostAlphaSurvey>

  public totalModules: number

  public loadingMore: boolean
  public userFirstName: string

  public supportsRecommendations: boolean
  public userProfileIsEnabled: boolean = false
  public coraEnabled: boolean = false

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private service: PlaylistService,
    private cd: ChangeDetectorRef,
    private userProfile: ProfileStorageProvider,
    appTheme: AppTheme,
    private features: FeaturesService,
    public user: CurrentUser
  ) {
    this.supportsRecommendations = appTheme.supports('surveyRecommendations')

    this.userFirstName = this.userProfile.getProfile().first_name

    this.route.data.subscribe((params) => {
      if (params.userSurveys) {
        this.userSurveys = params.userSurveys.filter(
          (u) => u.complete && u.survey.hasRecommendations
        )
      }

      if (params.modulesResponse) {
        this.modules = params.modulesResponse.modules
        this.totalModules = params.modulesResponse.totalItems
      }

      if (params.popularResources) {
        this.popularVideos = params.popularResources.videos
        this.popularArticles = params.popularResources.articles
      }

      if (params.categories) {
        this.categories = params.categories
      }

      this.cd.markForCheck()
    })

    this.userProfileIsEnabled = this.features.isOn(FEATURE_USER_PROFILE)
    this.coraEnabled = this.features.isOn(FEATURE_CORA)
  }

  loadMoreModules() {
    if (this.loadingMore) {
      return
    }

    this.loadingMore = true

    this.service
      .list({
        per_page: 100,
        offset: this.modules.length
      })
      .subscribe((response: HttpResponse<Playlist[]>) => {
        this.modules = this.modules.concat(response.body)

        this.loadingMore = false

        this.cd.detectChanges()
      })
  }
}
