import { Component, Inject } from "@angular/core";
import { TrackingService } from "../tracking-service";

import { IntercomService, intercomSettingsProvider } from "./intercom.service";

import {
  TrackingEvent,
  UpdatedAlpha,
  CreatedAlpha,
  UserLogin,
  UserLogout,
  UserSave,
  TokenValidated,
  PageView,
} from "../";
import { Alpha } from "@builder/alphas";
import { SITE_URL } from "@builder/common/baseHref.provider";

/**
 * This class handles subscribing to app events and passing those to the fb pixel service class
 */
@Component({
  selector: "intercom",
  template: "",
  providers: [intercomSettingsProvider, IntercomService],
})
export class IntercomComponent {
  constructor(
    private service: IntercomService,
    @Inject(SITE_URL) private siteUrl: string,
    trackingService: TrackingService,
  ) {
    // intercom object
    if (!window["Intercom"]) {
      window["Intercom"] = () => {};
    }

    // listen to the alpha service for alpha events
    trackingService.events.subscribe((event) => this.onTrackingEvent(event));
  }

  /**
   * On an alpha event
   */
  private onTrackingEvent(event: TrackingEvent): void {
    // console.info( event );

    /**
     * Alpha Created
     */
    if (event instanceof CreatedAlpha) {
      const alpha: Alpha = event.data.alpha;

      this.service.trackEvent("trackEvent", "Created an Alpha", {
        "Alpha Name": {
          value: alpha.title,
          url: `${this.siteUrl}alphas/edit/${alpha.id}`,
        },
        "Start Date": alpha.date,
        "Course Type": alpha.product.title,
        Organization: alpha.organization.name,
      });
    }

    /**
     * Alpha Updated
     */
    if (event instanceof UpdatedAlpha) {
      this.service.trackEvent("trackEvent", "Updated Schedule", {});
    }

    /**
     * Page View
     */
    if (event instanceof PageView) {
      // Visited User Profile
      if (event.data.url === "/user/profile") {
        this.service.trackEvent("trackEvent", "Visited Profile Page", {});
        this.service.update();
      }
    }

    /**
     * Token Validated or User Logged In
     */
    if (event instanceof TokenValidated || event instanceof UserLogin) {
      this.service.boot(event.data.user);
    }

    /**
     * User Logged Out
     */
    if (event instanceof UserLogout) {
      this.service.shutdown();
    }

    /**
     * User Saved
     */
    if (event instanceof UserSave) {
      // not sure why we update AND boot...
      this.service.update(event.data.user);

      this.service.boot(event.data.user);

      // custom event
      this.service.trackEvent("trackEvent", "Updated Profile", {});
    }
  }
}
