import { Injectable } from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpRequest,
  HttpParams,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { ProfileStorageProvider } from "@builder/users/local-profile.provider";
import { HttpFormParams } from "./form-params";
import { Locale } from "@builder/common/lang/locale";

/**
 * AuthHttp sets 'withCredentials' to true for all requests
 * Thats it
 */
@Injectable()
export class AuthHttp extends HttpClient {
  get<T>(url: string, options?): Observable<any> {
    options = options || {};
    options.withCredentials = true;

    return super.get(url, options);
  }

  put<T>(url: string, body: any | null, options?): Observable<any> {
    options = options || {};
    options.withCredentials = true;

    return super.put(url, body, options);
  }

  post<T>(url: string, body: any | null, options?): Observable<any> {
    options = options || {};
    options.withCredentials = true;

    return super.post(url, body, options);
  }

  delete(url: string, options?): Observable<any> {
    options = options || {};
    options.withCredentials = true;

    return super.delete(url, options);
  }
}

/**
 * Add RT MultiLingual headers to requests
 */
@Injectable()
export class RESTLanguageHeaders implements HttpInterceptor {
  constructor(private locale: Locale) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      setHeaders: {
        "Rtml-Language": this.locale.code,
        "Rtml-Require-Selection": this.locale.code,
      },
    });

    return next.handle(cloned);
  }
}

/**
 * Add Timezone offset headers to requests
 */
@Injectable()
export class TimeZoneOffsetHeaders implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      setHeaders: {
        TZO: (new Date().getTimezoneOffset() / 60).toString(),
      },
    });

    return next.handle(cloned);
  }
}

/**
 * Transforms a get request with an object as params into form encoded
 * i.e. { playlists: [{blog_id:1, post_id: 5}], per_page : 10}
 * becomes :
 *    playlists[0][blog_id]=1
 *    playlists[0][post_id]=5
 *    per_page=10
 */
@Injectable()
export class FormEncodingInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // if not get, just move on
    if (req.method !== "GET") {
      return next.handle(req);
    }

    /**
     * Handle params passed as object or string
     */
    if (req.params) {
      let httpParams: HttpParams;

      if (typeof req.params === "string") {
        httpParams = new HttpParams({ fromString: req.params });
      } else if (!(req.params instanceof HttpParams)) {
        httpParams = new HttpFormParams(req.params);
      }

      if (httpParams) {
        return next.handle(req.clone({ params: httpParams }));
      }
    }

    return next.handle(req);
  }
}

/**
 * AuthInterceptor handles requests as they go out, transforming them for some ease of use, and auth token stuff
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private profileProvider: ProfileStorageProvider) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let request: HttpRequest<any> = req.clone();

    // set the content type to application/json on posting methods
    if (
      (req.method === "PUT" ||
        req.method === "POST" ||
        req.method === "DELETE") &&
      req.responseType === "json"
    ) {
      request = request.clone({
        headers: request.headers.set("Content-Type", "application/json"),
      });
    }

    // handle the jwt token
    const token = this.profileProvider.getToken();

    // if credentials are needed, enforce them
    if (req.withCredentials && !token) {
      // @todo
    }

    if (token) {
      // Clone the request to add the new header.
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token),
      });
    }

    /**
     * Handle params passed as object or string
     */
    if (req.method === "GET" && req.params) {
      let httpParams: HttpParams;

      if (typeof req.params === "string") {
        httpParams = new HttpParams({ fromString: req.params });
      } else if (!(req.params instanceof HttpParams)) {
        httpParams = new HttpParams();

        Object.keys(req.params).forEach((key) => {
          httpParams = httpParams.append(key, req.params[key]);
        });
      }

      if (httpParams) {
        request = request.clone({ params: httpParams });
      }
    }

    return next.handle(request);
  }
}

/**
 * Add X-Requested-By header
 */
@Injectable()
export class RequestedByInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      setHeaders: {
        "X-Requested-By": "Builder;Builder-2.0",
      },
    });

    return next.handle(cloned);
  }
}

export let requestedByInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: RequestedByInterceptor,
  multi: true,
};

export let authInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};

export let formEncodingInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: FormEncodingInterceptor,
  multi: true,
};

export let languageCookieInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: RESTLanguageHeaders,
  multi: true,
};

export let tzoInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: TimeZoneOffsetHeaders,
  multi: true,
};
