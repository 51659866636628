import { Component } from "@angular/core";

import { FBPixelService, fbpixelSettingsProvider } from "./fb-pixel.service";

/**
 * This class handles subscribing to app events and passing those to the fb pixel service class
 */
@Component({
  selector: "fb-pixel",
  template: "",
  providers: [fbpixelSettingsProvider, FBPixelService],
})
export class FBPixelComponent {
  constructor(service: FBPixelService) {}
}
