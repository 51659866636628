import {
  Component,
  Injectable,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Observable, Subscription } from "rxjs";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { VideoService } from "@builder/common/media/video";
import { ResourceService } from "../../resource.service";
import { ResourceAccessDeniedComponent } from "./access-denied.component";
import { WithDestroy } from "@builder/common/mixins/with-destroy";
import { takeUntil } from "rxjs/operators";

interface ModalData {
  resource;
  skipPreview: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ResourceModalDialog extends WithDestroy() {
  private dialogRef: MatDialogRef<
    ResourceModalComponent | ResourceAccessDeniedComponent
  >;

  constructor(
    private videoService: VideoService,
    private resourceService: ResourceService,
    private dialog: MatDialog,
  ) {
    super();
  }

  open(resource, data = {}, options: any = {}): void {
    if (resource.error) {
      this.showResourceError(resource);
    } else {
      this.loadResource(resource, data, options);
    }
  }

  private showResourceError(resource): void {
    this.dialogRef = this.dialog.open(ResourceAccessDeniedComponent, {
      closeOnNavigation: true,
      data: resource.error,
    });
  }

  private _openModal(resource, data = {}, modalOptions = {}) {
    const opts: any = Object.assign(
      {
        width: window.innerWidth < 769 ? "100vw" : "80vw",
        // maxWidth: '1024px',
        closeOnNavigation: true,
        disableClose: false,
        autoFocus: false,
        panelClass: "resource-modal",
        data: Object.assign(data, { resource }),
      },
      modalOptions,
    );

    if (!opts.data.skipPreview && resource.id && resource.blog_id) {
      this.resourceService
        .updateResourceViewCount(resource.id, resource.blog_id)
        .subscribe();
    }

    if (resource.type === "video" && opts.data.skipPreview) {
      opts.width = "";
    }

    document.documentElement.classList.add("hide-overflow");

    this.dialogRef = this.dialog.open(ResourceModalComponent, opts);

    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((closed) => {
        document.documentElement.classList.remove("hide-overflow");
      });
  }

  public loadResource(item: any, data = {}, options = {}) {
    if (item.type === "video") {
      this.videoService.getVideo(item).subscribe((video) => {
        item.video = video;
        this._openModal(item, data, options);
      });
    } else if (item.canPreview) {
      this._openModal(item, data, options);
    } else {
      this.resourceService.onDownload(item);
    }
  }
}

/**
 * Resource modal Component
 */
@Component({
  selector: "resource-modal",
  template: `
    <resource-download
      [isMobile]="isMobile"
      (stepChange)="onStepChange($event)"
      [resource]="resource"
      [skipPreview]="skipPreview"
      (closing)="dialogRef.close()"
    >
    </resource-download>
  `,

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceModalComponent implements OnDestroy {
  public resource;
  public skipPreview: boolean;
  private subscription: Subscription;
  private currentStepIndex = 0;

  public isMobile: boolean;

  /**
   * Constructor
   * @param sanitizer
   */
  constructor(
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ResourceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private bo: BreakpointObserver,
  ) {
    this.resource = data.resource;
    this.skipPreview = data.skipPreview;

    dialogRef.afterOpened().subscribe((o) => {
      const lc: Observable<BreakpointState> = this.bo.observe([
        "(max-width:767px),(max-height:567px)",
      ]);
      this.subscription = lc.subscribe((result) => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
        this.sizeModal();
        this.cd.markForCheck();
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private sizeModal(): void {
    if (this.isMobile) {
      this.dialogRef.updateSize("100vw", "100vh");
    } else {
      const width =
        this.currentStepIndex === 0 && !this.skipPreview ? "80vw" : "";
      this.dialogRef.updateSize(width);
    }
  }

  public onStepChange(event: StepperSelectionEvent) {
    this.currentStepIndex = event.selectedIndex;
    this.sizeModal();
  }
}
