import { Component, ChangeDetectionStrategy, Input } from '@angular/core'

import { Lesson } from '@builder/training/lessons/lesson-class'
import { Playlist } from '@builder/training/playlists/playlist-class'

@Component({
  selector: 'lesson-card',
  templateUrl: 'lesson-card.component.html',
  styleUrls: ['lesson-card.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LessonCardComponent {
  @Input() lesson: Lesson

  @Input() playlist: Playlist

  public get iconName(): string {
    if (this.lesson.type === 'link') {
      return 'link'
    } else if (this.lesson.type === 'article') {
      return 'article'
    } else {
      return 'play'
    }
  }
}
