import { ElementRef } from "@angular/core";
import { fromEvent } from "rxjs";
import { debounceTime, filter, map } from "rxjs/operators";

export interface CanScrollToBottom {
  $element: ElementRef;
}

/**
 * @decorator RestPathMatch
 *
 * @param path string
 */
export function ScrollEvent(
  config: { threshold: number; repeat?: boolean },
  params: string[] = [],
): MethodDecorator {
  return function (
    componentInstance: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) {
    let triggered = false;
    let subscriber$;

    const mapEvent = (element: HTMLElement) => {
      const scrollY = window.scrollY,
        rect = element.getBoundingClientRect(),
        scrollH = rect.height - document.documentElement.clientHeight,
        pct = scrollY / scrollH;

      return { pct };
    };

    /**
     * ngOnInit for the component, register the path regexp on the path interceptor
     */
    const ngOnInit = componentInstance.constructor.prototype.ngOnInit;
    componentInstance.constructor.prototype.ngOnInit = function (...args) {
      const compI = this;

      subscriber$ = fromEvent(window, "scroll")
        .pipe(
          debounceTime(300),
          map((e) => mapEvent(compI.$element.nativeElement)),
          filter((e) => e.pct > config.threshold),
          map((e) => (params.includes("$event") ? [e] : [])),
        )
        .subscribe((p) => descriptor.value.apply(compI, p));

      ngOnInit && ngOnInit.apply(this, args);
    };

    /**
     * ngOnDestroy, cleanup
     */
    const ngOnDestroy = componentInstance.constructor.prototype.ngOnDestroy;
    componentInstance.constructor.prototype.ngOnDestroy = function (...args) {
      subscriber$.unsubscribe();
      triggered = false;

      ngOnDestroy && ngOnDestroy.apply(this, args);
    };
    return descriptor;
  };
}
