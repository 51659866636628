import { Component, Input } from "@angular/core";
import { Playlist } from "@builder/training/playlists/playlist-class";

/**
 * Playlist Card Component
 */
@Component({
  selector: "playlist-card",
  templateUrl: "playlist-card.component.html",
  styleUrls: ["playlist-card.component.less"],
})
export class PlaylistCardComponent {
  @Input() public playlist: Playlist;

  @Input("image-placement") imagePlacement: any;

  @Input() link = true;

  @Input() routerLinkBase: string = "/training";
}
