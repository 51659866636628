import * as React from 'react'
import { FunctionComponent } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import ErrorNotification from './error-notification'

type ErrorBoundaryProps = {
  componentProps: any
  component?: FunctionComponent | undefined
}

const ErrorBoundary: FunctionComponent<ErrorBoundaryProps> = (
  props: ErrorBoundaryProps
) => {
  const { componentProps, component } = props

  const fallbackRender = ({ error, resetErrorBoundary }) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    return <ErrorNotification error={error} unmount={componentProps.unmount} />
  }

  // Render children components as usual
  const Component = component
  return (
    <ReactErrorBoundary fallbackRender={fallbackRender}>
      <Component {...componentProps} />
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
