import { Component } from "@angular/core";
import { ActivatedRoute, Data } from "@angular/router";

/**
 * This component now only shows errors with the verification
 * We used to collect data as well, but there is no user action needed here so it's just to display errors with the verification process
 */
@Component({
  selector: "verify-user-form",
  templateUrl: "verify.html",
  styleUrls: ["../styles/signon.common.less", "verify.component.styles.less"],
})
export class VerifyUserComponent {
  public errorMessage: string;

  constructor(private route: ActivatedRoute) {
    this.route.data.subscribe((data: Data) => {
      // verified an error
      if (data.verification.error) {
        this.errorMessage = data.verification.error.message;
      }
    });
  }
}
