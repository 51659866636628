import { Post } from '@builder/common/models/post'
import { Resource } from '@builder/resources/resource'

interface ImageSrcSet {
  url: string
  height: number
  width: number
}

interface FeaturedImage extends ImageSrcSet {
  sizes: any
}

export interface Contributor {
  id: number
  title: string
  excerpt: string
  image: FeaturedImage
}

/**
 * Product
 */
export class Product extends Post {
  public image: FeaturedImage
  public heroImage: FeaturedImage
  public content_blocks: Array<{ type: string; data: any }>

  public language: string
  public languages: Array<{ code: string; label: string }>

  public supportingTools: Array<Resource>
  public previewEpisodes: Array<{
    id: number
    duration: number
    title: string
    url: string
  }>

  public duration: { sessionCount: number; time: number }

  public availableLanguages: Array<string>
  public siteLanguages: Array<string>

  public promotionalVideo: {
    description: string
    url: string
    videoProvider?: string
  }

  public presentersText: string
  public contributorsText: string

  public presenters: Array<Contributor>
  public contributors: Array<Contributor>

  public whatsIncluded: {
    title: string
    text: string
    icons: Array<{
      title: string
      icon: string
      name: string
    }>
  }
  public status: string
}
