import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { FeaturesService } from "@builder/common/features/features.service";
import { FEATURE_SIMPLE_COURSE_CREATE } from "@builder/common/features/feature-flag";

/**
 * Simple Course Create integration
 * Add script to page when feature is enabled
 */
@Injectable({
  providedIn: "root",
})
export class SimpleCourseCreate {
  constructor(
    features: FeaturesService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    features
      .whenChanged(FEATURE_SIMPLE_COURSE_CREATE)
      .subscribe(status => {
        if ( status.enabled ) {
          this.document.body.classList.add("uk-pilot");
        } else {
          this.document.body.classList.remove("uk-pilot");
        }
      });
  }
}
