<ng-container *ngIf="!isMobileView; else mobileLanguageSelection">
  <div
    class="language-toggle header-button"
    (click)="toggleLanguageMenu()"
    [matMenuTriggerFor]="matLangMenu"
    data-qa-name="auth-dropdown-language"
    [attr.data-driver]="'overview6'"
  >
    <div class="header-button-icon">
      <ab-icon name="language" size="24"></ab-icon>
    </div>
    <div class="language-toggle-divider"></div>
    <div class="language-toggle-text">
      <span
        class="language-toggle-text-selected"
        data-qa-name="auth-menu-selectedLanguage"
        >{{ locale.code | languageInfo }}</span
      >
      <ab-icon
        name="chevron-down"
        size="12"
        [class.toggle]="showLanguageMenu"
      ></ab-icon>
    </div>
  </div>

  <mat-menu
    #matLangMenu="matMenu"
    xPosition="before"
    class="language-menu mat-menu-before"
    (closed)="toggleLanguageMenu()"
    data-qa-name="auth-menu-languages"
  >
    <div
      mat-menu-item
      class="language-menu-search"
      (click)="$event.stopPropagation()"
      [disableRipple]="true"
    >
      <mat-form-field class="language-menu-search-input">
        <ab-icon matPrefix name="search"></ab-icon>
        <input
          class="search-container"
          matInput
          [(ngModel)]="languageSearchTerm"
          appearance="outline"
          autocomplete="off"
          #searchInput
          id="languageSearchTerm"
          name="languageSearchTerm"
          subscriptSizing="false"
          (input)="filteredLanguages()"
          data-qa-name="auth-input-search"
        />
      </mat-form-field>
    </div>
    <div mat-menu-item class="language-menu-list" [disableRipple]="true">
      <div
        class="language-menu-option"
        *ngFor="let language of languages"
        [language-link]="language.code"
        [title]="language.code"
        data-qa-name="auth-matMenuItem-language"
      >
        {{ language.native_name }}
      </div>
    </div>
  </mat-menu>
</ng-container>

<ng-template #mobileLanguageSelection>
  <div
    class="language-toggle header-button"
    (click)="toggleLanguageMenu()"
    data-qa-name="auth-dropdown-mobileLanguage"
    [attr.data-driver]="'overview6'"
  >
    <div class="header-button-icon">
      <ab-icon name="language" size="24"></ab-icon>
    </div>
  </div>

  <div
    class="language-menu-mobile"
    [@languageMenuToggle]="showLanguageMenu ? 'open' : 'closed'"
    (@languageMenuToggle.done)="languageAnimationDone($event)"
    (@languageMenuToggle.start)="languageAnimationStart($event)"
  >
    <div class="language-mobile-grey-box"></div>
    <div class="language-mobile-title">
      <ab-icon
        (click)="toggleLanguageMenu()"
        class="language-mobile-back-button"
        name="chevron-left"
        size="18"
        data-qa-name="auth-icon-mobileLanguages"
      ></ab-icon>
      <h1
        class="mya-h1"
        i18n="
          userHeaderLanguage.text|User Header - Site
          language@@userHeaderSiteLanguage"
      >
        Site language
      </h1>
    </div>

    <div class="language-mobile-search">
      <ab-icon name="search" class="language-mobile-search-icon"></ab-icon>
      <input
        class="language-mobile-search-input"
        [(ngModel)]="languageSearchTerm"
        autocomplete="off"
        #searchInput
        id="languageSearchTerm"
        name="languageSearchTerm"
        (input)="filteredLanguages()"
        data-qa-name="auth-input-languageSearch"
      />
    </div>
    <mat-radio-group class="language-mobile-options-group">
      <div
        [ngClass]="{ selected: language.code === locale.code }"
        class="language-mobile-options"
        *ngFor="let language of mobileLanguages"
        [language-link]="language.code"
        [title]="language.code"
      >
        <label>{{ language.native_name }}</label>
        <mat-radio-button
          value="{{ language.native_name }}"
          [checked]="language.code === locale.code"
          data-qa-name="auth-radio-languages"
        ></mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
</ng-template>
