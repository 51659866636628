import { NgModule } from '@angular/core'
import { Route, RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'

import { BuilderMaterialModule } from '@builder/common/ng-material'
import { VideoPlayerModule } from '@builder/common/media/video/video-player.module'
import { CommonPipesModule } from '@builder/common/pipes/common-pipes.module'
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module'
import { ModalOverlayModule } from '@builder/common/modal'
import { GoToTextModule, RelatedTextModule } from '@builder/common/text'
import { AlphaLocaleModule } from '@builder/common/lang/locale.module'
import { UserService } from '@builder/users/service'

import { PlaylistNavBarModule } from '@builder/training/playlists/nav-bar/nav-bar.module'
import { RelatedPlaylistsModule } from '@builder/training/playlists/related/related-playlists.module'
import { TrainingDirectivesModule } from '@builder/training/directives/training-directives.module'

import { LessonDetailComponent } from './lesson-detail.component'
import { LessonDetailResolver } from './lesson-detail.resolvers'
import { LessonCardModule } from '../card/lesson-card.module'
import { LessonDetailTitleResolver } from './lesson-detail.title.resolver'
import { SearchResultComponent } from '@builder/search/results/search-result/search-result.component'
import { ReactWrapperComponent } from '@builder/common/react-wrapper/react-wrapper.component'

/**
 * Routes
 */
export const LESSON_DETAIL_ROUTE: Route = {
  path: 'article/:id',
  title: LessonDetailTitleResolver,
  component: LessonDetailComponent,
  canActivate: [UserService],
  resolve: {
    lesson: LessonDetailResolver
  }
}
export const PLAYLIST_LESSON_DETAIL_ROUTE: Route = {
  path: ':id/:lessonId',
  title: LessonDetailTitleResolver,
  component: LessonDetailComponent,
  canActivate: [UserService],
  resolve: {
    playlistAndLesson: LessonDetailResolver
  },
  runGuardsAndResolvers: 'always'
}
export const CATEGORY_LESSON_DETAIL_ROUTE: Route = {
  path: 'category/:categoryid/lesson/:id',
  component: LessonDetailComponent,
  canActivate: [UserService],
  resolve: {
    lesson: LessonDetailResolver
  }
}

/**
 * Module
 */
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BuilderMaterialModule,
    AlphaLocaleModule,
    ModalOverlayModule,
    LessonCardModule,
    BuilderIconModule,
    GoToTextModule,
    RelatedTextModule,
    VideoPlayerModule,
    CommonPipesModule,
    PlaylistNavBarModule,
    RelatedPlaylistsModule,
    TrainingDirectivesModule,
    SearchResultComponent,
    ReactWrapperComponent
  ],
  declarations: [LessonDetailComponent],
  providers: [],
  exports: [LessonDetailComponent]
})
export class LessonDetailModule {}
