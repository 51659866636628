import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { BuilderMaterialModule } from "@builder/common/ng-material";
import { BuilderIconModule } from "@builder/common/icons/ab-icon.module";
import { VideoPlayerModule } from "@builder/common/media/video/video-player.module";
import { CommonPipesModule } from "@builder/common/pipes/common-pipes.module";
import { AlphaLocaleModule } from "@builder/common/lang/locale.module";
import { ClipboardCopyModule } from "@builder/common/ui/clipboard/clipboard-copy.module";

import { ResourceDownloadComponent } from "./resource-download.component";
import { ResourcePreviewComponent } from "../resource-preview/resource-preview.component";
import { ResourceDownloadOptionsComponent } from "../resource-download-options/resource-download-options.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BuilderMaterialModule,
    AlphaLocaleModule,
    BuilderIconModule,
    VideoPlayerModule,
    CommonPipesModule,
    ClipboardCopyModule,
  ],
  declarations: [
    ResourceDownloadComponent,
    ResourcePreviewComponent,
    ResourceDownloadOptionsComponent,
  ],
  exports: [ResourceDownloadComponent],
})
export class ResourceDownloadModule {}
