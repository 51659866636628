import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  OnInit,
} from "@angular/core";
import { Router, Navigation } from "@angular/router";
import { takeUntil } from "rxjs/operators";

import { AppTheme } from "@builder/common/util/themeSupports";
import { TrackingService, UserLogin } from "@builder/tracking";
import { WithDestroy } from "@builder/common/mixins/with-destroy";
import { FeaturesService } from "@builder/common/features/features.service";
import { FEATURE_USER_PROFILE } from "@builder/common/features/feature-flag";

@Component({
  selector: "signon-component",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "signon.component.html",
  styleUrls: ["signon.component.less"],
})
export class SignonComponent extends WithDestroy() implements OnInit {
  public canClose: boolean;

  private referingNavigation: Navigation;
  public userProfileFlag: boolean = false;
  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private themeApp: AppTheme,
    private trackingService: TrackingService,
    private features: FeaturesService,
  ) {
    super();
    this.userProfileFlag = features.isOn(FEATURE_USER_PROFILE);
    this.referingNavigation = this.router.getCurrentNavigation();

    /**
     * Listen to User Events for login, redirect or trigger reload
     */
    this.trackingService.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event instanceof UserLogin) {
          if (
            this.referingNavigation &&
            this.referingNavigation.previousNavigation
          ) {
            delete this.referingNavigation.previousNavigation.extractedUrl
              .queryParams.team_member;

            this.router.navigateByUrl(
              this.referingNavigation.previousNavigation.extractedUrl.toString(),
            );
          } else if (
            this.referingNavigation &&
            this.referingNavigation.extras &&
            this.referingNavigation.extras.state &&
            this.referingNavigation.extras.state.redirect
          ) {
            this.router.navigateByUrl(
              this.referingNavigation.extras.state.redirect,
            );
          } else {
            this.router.navigate(["/dashboard"]);
          }
        }
      });
  }

  ngOnInit(): void {
    const bg: string = this.themeApp.value("signon_bg_image");
    this.elementRef.nativeElement.style.backgroundImage = "url(" + bg + ")";
  }

  /**
   *
   */
  public backOrClose(): void {
    this.router.navigateByUrl("/");
  }
}
