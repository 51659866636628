import { Injectable } from "@angular/core";

import { CurrentUser } from "@builder/users/user";
import { TrackingService, UserLogout, UserLogin } from "./";

const KEY_STORE_NAME = "ab_session_progress";

const TIMER_UPDATE = 1000;

/**
 * This is used to track current video playtime and article time on page
 * It expires when a user logs out
 *
 */
@Injectable()
export class SessionProgressTracker {
  private _lessonTimer: any;

  constructor(
    protected user: CurrentUser,
    protected trackingService: TrackingService,
  ) {
    /**
     * Clear the store on either login or logout
     */
    this.trackingService.events.subscribe((event) => {
      if (event instanceof UserLogout) {
        this.clearStore();
      } else if (event instanceof UserLogin) {
        this.clearStore();
      }
    });
  }

  /**
   * Get the video progress
   */
  public getVideoProgress(videoId: number): number {
    // get the current lesson time on page
    return this.getFromStore(videoId, "videos", 0);
  }

  /**
   * Set the video progress
   */
  public setVideoProgress(videoId: number | string, time: number): void {
    // store it
    this.setStore(videoId, "videos", time);
  }

  /**
   * Get the time in seconds a lesson has been viewed
   */
  public getLessonArticleProgress(lesson): number {
    // get the current lesson time on page
    return this.getFromStore(lesson.id, "lessons", 0);
  }

  /**
   * Start a lesson timer
   */
  public startLessonArticleProgress(lesson): Promise<any> {
    return new Promise((resolve, reject) => {
      // clear any existing timers
      this.stopLessonArticleProgress();

      // get the current lesson time on page
      let current: number = this.getLessonArticleProgress(lesson);

      // set the timer
      this._lessonTimer = setInterval(() => {
        // add time in seconds
        current += TIMER_UPDATE / 1000;

        // store it
        this.setStore(lesson.id, "lessons", current);

        // console.info( 'time on page: ' + current );

        if (current >= lesson.duration) {
          // stop timer
          this.stopLessonArticleProgress();

          // resolve
          resolve(lesson);
        }
      }, TIMER_UPDATE);
    });
  }

  /**
   * stop lesson timer
   */
  public stopLessonArticleProgress(): void {
    clearInterval(this._lessonTimer);
  }

  /**
   * Set a value in the local storage by type
   */
  protected setStore(key: string | number, type: string, value: any): void {
    const values = this.getStore();
    values[type][key] = value;
    localStorage.setItem(KEY_STORE_NAME, JSON.stringify(values));
  }

  /**
   * Get an item from the local storage by key and type
   */
  protected getFromStore(
    key: string | number,
    type: string,
    defaultValue: any = null,
  ): any {
    const values = this.getStore(type);
    return values[key] || defaultValue;
  }

  /**
   * Get the local storage, optionally by type
   */
  protected getStore(type?: string): any {
    const valueStr: string = localStorage.getItem(KEY_STORE_NAME);
    let values: object;

    if (valueStr) {
      values = JSON.parse(valueStr);
    } else {
      values = { lessons: {}, videos: {} };
    }

    return (type && values[type]) || values;
  }

  public clearStore(): void {
    localStorage.removeItem(KEY_STORE_NAME);
  }
}
