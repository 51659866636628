import { Injectable } from "@angular/core";

import { AppTheme } from "@builder/common/util/themeSupports";
import { HeapSettings } from "./heap-settings";

declare const heap: any;

/**
 * HeapService will attach the client JS library
 */
@Injectable()
export class HeapService {
  constructor(protected settings: HeapSettings) {
    this.initialize();
  }

  /**
   * Initialize tracker object with our settings
   */
  private initialize(): void {
    this.loadDeps();
  }

  /**
   * Create and attach script element for FBPixelService client js
   */
  private loadDeps(): void {
    (window["heap"] = window["heap"] || []),
      (heap.load = function (e, t) {
        (window["heap"].appid = e), (window["heap"].config = t = t || {});
        var r = document.createElement("script");
        (r.type = "text/javascript"),
          (r.async = !0),
          (r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js");
        var a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(r, a);
        for (
          var n = function (e) {
              return function () {
                heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              };
            },
            p = [
              "addEventProperties",
              "addUserProperties",
              "clearEventProperties",
              "identify",
              "resetIdentity",
              "removeEventProperty",
              "setEventProperties",
              "track",
              "unsetEventProperty",
            ],
            o = 0;
          o < p.length;
          o++
        )
          heap[p[o]] = n(p[o]);
      });
    heap.load(this.settings.id);
  }
}

export const heapSettingsProviderFactory = (appTheme: AppTheme) => {
  const obj = appTheme.value("tracking/heap-analytics");
  return new HeapSettings(obj);
};

export let heapSettingsProvider = {
  provide: HeapSettings,
  useFactory: heapSettingsProviderFactory,
  deps: [AppTheme],
};
