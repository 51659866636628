<div class="search-results content-wrapper">
  <search-form [searchTerm]="searchTerm"></search-form>
  <ng-container
    *ngIf="searchResults$ | async as results; else searchResultsLoading"
  >
    <ng-container *ngIf="results.length">
      <div class="search-filter">
        <a
          *ngIf="!showMobileFilters"
          class="filter-link"
          (click)="toggleFilterSection($event)"
        >
          <ab-icon name="filters">
            <span
              *ngIf="showFilter"
              i18n="
                searchFilters.action|Search Filters - Hide
                Filters@@searchFiltersActionsHideFilters"
            >
              Hide Filters
            </span>
            <span
              *ngIf="!showFilter"
              i18n="
                searchFilters.action|Search Filters - Show
                Filters@@searchFiltersActionsShowFilters"
            >
              Show Filters
            </span>
          </ab-icon>
        </a>
        <a *ngIf="showMobileFilters" class="filter-link" (click)="openDialog()">
          <ab-icon name="filters">
            <span
              i18n="
                searchFiltersDialog.text|Search Filters Dialog - Filter Search
                Results@@searchFiltersDialogTitle"
            >
              Filter Search Results
            </span>
          </ab-icon>
        </a>
        <p *ngIf="count" class="mya-p color-grey-60">
          <span i18n="common.words|Common Words - Showing@@commonWordsShowing">
            Showing
            {{ count.current // i18n: (ph="count_current") }}
            out of
            {{ count.total // i18n: (ph="count_total") }}
            results for
            {{ searchTerm // i18n: (ph="search_term") }}
          </span>
        </p>
      </div>

      <div class="search-results-columns">
        <search-filter
          *ngIf="!showMobileFilters"
          [ngClass]="{ 'hide-filter': !showFilter }"
          [filterDefaults]="filterParams"
          (filters)="handleValueChange($event)"
        ></search-filter>
        <search-result-list
          [resultSet]="results"
          [filters]="filterParams"
          (updateCountEvent)="updateResultCount($event)"
        ></search-result-list>
      </div>
    </ng-container>
    <ng-container *ngIf="!resultsCount">
      <p
        *ngIf="searchTerm"
        class="mya-h5 color-grey-50 font-weight-medium"
        i18n="noResults.msg|no results@@searchOptionsTerms"
      >
        Sorry, we didn't find any results for "{{ searchTerm }}"
      </p>
      <lookup-alpha
        *ngIf="isLookupAlphaVisible"
        (hideComponentEvent)="hideLookupAlpha()"
      ></lookup-alpha>
      <popular-search-terms
        [exclude]="[searchTerm]"
        (selectTerm)="
          router.navigate(['/search'], { queryParams: { q: $event.term } })
        "
      >
      </popular-search-terms>
    </ng-container>
  </ng-container>

  <ng-template #searchResultsLoading>
    <mat-progress-bar
      class="search-progress-bar"
      mode="indeterminate"
      color="accent"
    ></mat-progress-bar>
  </ng-template>
</div>
