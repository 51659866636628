import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { parseISO } from "date-fns";

import { ISearchTerm } from "./popular/popular-search-terms.component";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  private _endpoint = "wp-json/wp/v2/search";

  constructor(private http: HttpClient) {}

  public getTerms(): Observable<Array<ISearchTerm>> {
    return this.http.get<any>(this._endpoint + "/popularTerms").pipe(
      map((terms) => {
        return terms.map((t) => {
          t.created = parseISO(t.created);
          t.updated = parseISO(t.updated);
          return t;
        });
      }),
    );
  }

  public search(params: any): Observable<any> {
    return this.http.get<any>(this._endpoint, { params: { ...params } });
  }
}
