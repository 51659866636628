import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WhatsNewComponent } from "./whats-new.component";
import { BuilderIconModule } from "@builder/common/icons/ab-icon.module";
import { BuilderMaterialModule } from "@builder/common/ng-material";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [WhatsNewComponent],
  imports: [
    CommonModule,
    BuilderIconModule,
    BuilderMaterialModule,
    MatButtonModule,
  ],
  exports: [WhatsNewComponent],
})
export class WhatsNewModule {}
