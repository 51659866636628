import { Injectable } from "@angular/core";

import { AppTheme } from "@builder/common/util/themeSupports";

import {
  GoogleAnalyticsSettings,
  GoogleAnalyticsTrackerConfig,
} from "./ga-settings.class";

// global gtag function
declare const gtag;

/**
 * This class represents a tracker object connected to GA
 */
class GATracker {
  public sendPageViews = true;
  public sendEvents = true;

  public currentUrl: string;

  constructor(private account: GoogleAnalyticsTrackerConfig) {
    if (!account.pageviews) {
      this.sendPageViews = false;
    }
    if (!account.events) {
      this.sendEvents = false;
    }
  }

  public trackEvent(
    name: string,
    action: string,
    label: string,
    value?: any,
  ): void {
    gtag("event", name, { send_to: this.account.id });
  }

  public sendPageView(url: string, title: string = ""): void {
    if (this.currentUrl === url) {
      return;
    }
    this.currentUrl = url;

    gtag("event", "page_view", {
      page_location: url,
      page_title: title,
      send_to: this.account.id,
    });
  }
}

@Injectable()
export class GoogleAnalyticsService {
  private ga_source = "https://www.googletagmanager.com/gtag/js";

  private ga_trackers: Array<GATracker> = [];

  constructor(settings: GoogleAnalyticsSettings) {
    this.loadDependencies();

    settings.accounts.forEach((account: GoogleAnalyticsTrackerConfig) => {
      gtag("config", account.id, {
        //debug_mode : true,
        send_page_view: false,
        cookieDomain: "auto",
        name: account.trackerId,
      });

      this.ga_trackers.push(new GATracker(account));
    });
  }

  /**
   * Load the google-analytics js and handle the global object, assigning it to the 'api' on this class
   */
  private loadDependencies() {
    const sc = document.createElement("script");
    sc.async = true;
    sc.src = this.ga_source;
    document.head.prepend(sc);
    window["dataLayer"] = window["dataLayer"] || [];
    window["gtag"] = function () {
      window["dataLayer"].push(arguments);
    };
    gtag("js", new Date());
  }

  /**
   * Send a pageview
   */
  public sendPageView(url: string, title: string): void {
    this.ga_trackers.forEach((tracker: GATracker) => {
      if (tracker.sendPageViews) {
        tracker.sendPageView(url, title);
      }
    });
  }

  /**
   * Track an Event
   */
  public trackEvent(
    category: string,
    action?: string,
    label?: string,
    value?: number,
  ): void {
    this.ga_trackers.forEach((tracker: GATracker) => {
      if (tracker.sendEvents) {
        tracker.trackEvent(category, action, label, value);
      }
    });
  }
}

export const gaSettingsProviderFactory = (appTheme: AppTheme) => {
  return new GoogleAnalyticsSettings(
    (appTheme.value("tracking/google-analytics") || []).map(
      (item) => new GoogleAnalyticsTrackerConfig(item),
    ),
  );
};

export let gaSettingsProvider = {
  provide: GoogleAnalyticsSettings,
  useFactory: gaSettingsProviderFactory,
  deps: [AppTheme],
};
