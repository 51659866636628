import { NgModule } from "@angular/core";

import { FileSizePipe } from "@builder/common/pipes/file-size.pipe";
import { FileExtensionPipe } from "@builder/common/pipes/file-extension-pipe";

import { HasItemsPipe, BucketsHaveItemsPipe } from "./hasItems";
import { TimePipe } from "./timePipe";
import { FileIconPipe } from "./file-icon.pipe";
import { ABDatePipe } from "./ab-date-pipe/ab-date-pipe";
import { AlphaUserRolePipe } from "./alpha-user-role.pipe";

const PIPES: Array<any> = [
  TimePipe,
  FileSizePipe,
  FileExtensionPipe,
  HasItemsPipe,
  BucketsHaveItemsPipe,
  ABDatePipe,
  FileIconPipe,
  AlphaUserRolePipe,
];

@NgModule({
  declarations: PIPES,
  exports: PIPES,
})
export class CommonPipesModule {}
