import {
  Pipe,
  PipeTransform,
  Injectable,
  Inject,
  LOCALE_ID,
} from "@angular/core";

const date = new Date();

@Pipe({
  name: "playTime",
})
@Injectable()
export class TimePipe implements PipeTransform {
  protected formatter: Intl.NumberFormat;

  constructor(@Inject(LOCALE_ID) private numbering_locale: string) {}

  transform(duration: number, args: any = {}): any {
    const fmt: string = args.format || "text";

    if (fmt === "text") {
      const length: string = args.length || "hm"; // hours/minutes, weeks
      return this.formatText(duration || 0, length);
    } else if (fmt === "timer") {
      return this.formatTimer(duration || 0);
    }
  }

  private formatText(duration: number, length: string): string {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(duration);

    if (length === "hm") {
      const parts = [];
      const hours = date.getHours();
      const secs = date.getSeconds();
      let mins = date.getMinutes();

      // round up minutes
      if (secs > 30) {
        mins++;
      }

      if (hours > 0) {
        parts.push(hours + "h");
      }
      if (mins > 0) {
        parts.push(mins + "m");
      }
      return parts.join(" ");
    }

    if (length === "week") {
      const weeks = Math.ceil(duration / 604800);
      return weeks.toString();
    }
    return "";
  }

  private formatTimer(duration) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(duration);
    let mins: any = date.getMinutes(),
      secs: any = date.getSeconds();

    if (mins < 10) {
      mins = "0" + mins;
    }
    if (secs < 10) {
      secs = "0" + secs;
    }

    return mins + ":" + secs;
  }
}
