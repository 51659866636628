import * as React from 'react'
import { FunctionComponent, PropsWithChildren } from 'react'

export interface TabContentContainerProps {}

const TabContentContainer: FunctionComponent<
  PropsWithChildren<TabContentContainerProps>
> = ({ children }) => {
  return (
    <div
      className='flex flex-col md:gap-16 sm:gap-12 gap-6 my-16 mx-auto md:px-16 sm:px-8 px-4'
      style={{ maxWidth: 1280 }}
    >
      {children}
    </div>
  )
}

export default TabContentContainer
