import { Component } from "@angular/core";

import { TidioService, tidioSettingsProvider } from "./tidio.service";

/**
 * This class handles subscribing to app events and passing those to the tidio service class
 */
@Component({
  selector: "tidio",
  template: "",
  providers: [tidioSettingsProvider, TidioService],
})
export class TidioComponent {
  constructor(
    private service: TidioService   
  ) {
   
  }
}
