import {
  Component,
  TemplateRef,
  EventEmitter,
  Inject,
  Output,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from "@angular/material/dialog";
import { Observable, Observer } from "rxjs";

interface ModalData {
  canClose: boolean;
  contentTemplate: TemplateRef<any>;
  async: EventEmitter<any>;
}

@Component({
  selector: "modal-overlay",
  template: "<ng-template><ng-content></ng-content></ng-template>",
})
export class ModalOverlayComponent
  implements AfterViewInit, OnDestroy, OnChanges
{
  @ViewChild(TemplateRef) contentTemplate: TemplateRef<any>;

  @Input() canClose: boolean;
  @Input() autoFocus = false;

  @Input() options: MatDialogConfig;

  @Input() open = true;

  private dialogRef: MatDialogRef<OverlayDialogComponent>;

  constructor(private dialog: MatDialog) {}

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngAfterViewInit(): void {
    if (this.open) {
      this.show();
    }
  }

  ngOnChanges(c: SimpleChanges): void {
    if (c.open && !c.open.firstChange) {
      if (c.open.currentValue) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  public hide(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public show(): void {
    const opts = Object.assign(
      {
        width: "480px",
        closeOnNavigation: true,
        disableClose: !this.canClose,
        autoFocus: this.autoFocus,
      },
      this.options || {},
    );

    opts.data = {
      contentTemplate: this.contentTemplate,
      canClose: this.canClose,
    };

    this.dialogRef = this.dialog.open(OverlayDialogComponent, opts);
  }
}

@Component({
  selector: "modal-dialog",
  template: ` <button id="close" mat-dialog-close mat-icon-button>
      <ab-icon name="close"></ab-icon>
    </button>
    <div mat-dialog-content>
      <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
    </div>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isConfirmingAsync"
      color="accent"
    ></mat-progress-bar>`,
  styles: [
    `
      mat-progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
      }
      #close {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    `,
  ],
})
export class OverlayDialogComponent {
  public contentTemplate: TemplateRef<any>;

  public asyncConfirm: EventEmitter<any>;

  public isConfirmingAsync: boolean;

  public canClose: boolean;

  constructor(
    public dialogRef: MatDialogRef<OverlayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
    this.contentTemplate = data.contentTemplate;
    this.asyncConfirm = data.async;
    this.canClose = data.canClose;
  }
  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onYesClick(): void {
    if (this.asyncConfirm) {
      new Observable((observer: Observer<any>) => {
        this.isConfirmingAsync = true;

        this.asyncConfirm.emit(observer);
      }).subscribe((result) => {
        this.isConfirmingAsync = false;

        this.dialogRef.close();
      });
    } else {
      this.dialogRef.close(true);
    }
  }
}
