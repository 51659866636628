import { Pipe, PipeTransform } from "@angular/core";

const validAmount = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const parsableUnit = (u) => {
  return u.match(/\D*/).pop() === u;
};

const incrementBases = {
  2: [
    [["b", "bit", "bits"], 1 / 8],
    [["B", "Byte", "Bytes", "bytes"], 1],
    [["Kb"], 128],
    [["k", "K", "kb", "KB", "KiB", "Ki", "ki"], 1024],
    [["Mb"], 131072],
    [["m", "M", "mb", "MB", "MiB", "Mi", "mi"], Math.pow(1024, 2)],
    [["Gb"], 1.342e8],
    [["g", "G", "gb", "GB", "GiB", "Gi", "gi"], Math.pow(1024, 3)],
    [["Tb"], 1.374e11],
    [["t", "T", "tb", "TB", "TiB", "Ti", "ti"], Math.pow(1024, 4)],
    [["Pb"], 1.407e14],
    [["p", "P", "pb", "PB", "PiB", "Pi", "pi"], Math.pow(1024, 5)],
    [["Eb"], 1.441e17],
    [["e", "E", "eb", "EB", "EiB", "Ei", "ei"], Math.pow(1024, 6)],
  ],
  10: [
    [["b", "bit", "bits"], 1 / 8],
    [["B", "Byte", "Bytes", "bytes"], 1],
    [["Kb"], 125],
    [["k", "K", "kb", "KB", "KiB", "Ki", "ki"], 1000],
    [["Mb"], 125000],
    [["m", "M", "mb", "MB", "MiB", "Mi", "mi"], 1.0e6],
    [["Gb"], 1.25e8],
    [["g", "G", "gb", "GB", "GiB", "Gi", "gi"], 1.0e9],
    [["Tb"], 1.25e11],
    [["t", "T", "tb", "TB", "TiB", "Ti", "ti"], 1.0e12],
    [["Pb"], 1.25e14],
    [["p", "P", "pb", "PB", "PiB", "Pi", "pi"], 1.0e15],
    [["Eb"], 1.25e17],
    [["e", "E", "eb", "EB", "EiB", "Ei", "ei"], 1.0e18],
  ],
};

export const fileSizeReadableToNumber = (input, options?) => {
  options = options ?? {};
  const base = parseInt(options.base || 2, 10);

  const parsed = input.toString().match(/^([0-9\.,]*)(?:\s*)?(.*)$/);
  const amount = parsed[1].replace(",", ".");
  const unit = parsed[2];

  const validUnit = (sourceUnit) => sourceUnit === unit;

  if (!validAmount(amount) || !parsableUnit(unit)) {
    return input;
  }
  if (unit === "") return Math.round(Number(amount));

  const increments = incrementBases[base];
  for (let i = 0; i < increments.length; i++) {
    const _increment = increments[i];

    if (_increment[0].some(validUnit)) {
      return Math.round(amount * _increment[1]);
    }
  }

  return input;
};

const byteTest = /^(b|B)$/,
  symbol = {
    iec: {
      bits: ["b", "Kib", "Mib", "Gib", "Tib", "Pib", "Eib", "Zib", "Yib"],
      bytes: ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"],
    },
    jedec: {
      bits: ["b", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"],
      bytes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    },
  },
  fullform = {
    iec: ["", "kibi", "mebi", "gibi", "tebi", "pebi", "exbi", "zebi", "yobi"],
    jedec: [
      "",
      "kilo",
      "mega",
      "giga",
      "tera",
      "peta",
      "exa",
      "zetta",
      "yotta",
    ],
  };
/**
 * filesize
 *
 * @method filesize
 * @param  {Mixed}   arg        String, Int or Float to transform
 * @param  {Object}  descriptor [Optional] Flags
 * @return {String}             Readable file size String
 */
export const fileSizeToHumanReadable = (arg, descriptor: any = {}) => {
  const result = [];
  let val = 0,
    e,
    base,
    bits,
    ceil,
    full,
    fullforms,
    neg,
    num,
    output,
    round,
    unix,
    separator,
    spacer,
    standard,
    symbols;

  if (isNaN(arg)) {
    throw new Error("Invalid arguments");
  }

  bits = descriptor.bits === true;
  unix = descriptor.unix === true;
  base = descriptor.base || 2;
  round = descriptor.round !== void 0 ? descriptor.round : unix ? 1 : 2;
  separator = descriptor.separator !== void 0 ? descriptor.separator || "" : "";
  spacer = descriptor.spacer !== void 0 ? descriptor.spacer : unix ? "" : " ";
  symbols = descriptor.symbols || {};
  standard = base === 2 ? descriptor.standard || "jedec" : "jedec";
  output = descriptor.output || "string";
  full = descriptor.fullform === true;
  fullforms = descriptor.fullforms instanceof Array ? descriptor.fullforms : [];
  e = descriptor.exponent !== void 0 ? descriptor.exponent : -1;
  num = Number(arg);
  neg = num < 0;
  ceil = base > 2 ? 1000 : 1024;

  // Flipping a negative number to determine the size
  if (neg) {
    num = -num;
  }

  // Determining the exponent
  if (e === -1 || isNaN(e)) {
    e = Math.floor(Math.log(num) / Math.log(ceil));

    if (e < 0) {
      e = 0;
    }
  }

  // Exceeding supported length, time to reduce & multiply
  if (e > 8) {
    e = 8;
  }

  // Zero is now a special case because bytes divide by 1
  if (num === 0) {
    result[0] = 0;
    result[1] = unix ? "" : symbol[standard][bits ? "bits" : "bytes"][e];
  } else {
    val = num / (base === 2 ? Math.pow(2, e * 10) : Math.pow(1000, e));

    if (bits) {
      val = val * 8;

      if (val >= ceil && e < 8) {
        val = val / ceil;
        e++;
      }
    }

    result[0] = Number(val.toFixed(e > 0 ? round : 0));
    result[1] =
      base === 10 && e === 1
        ? bits
          ? "kb"
          : "kB"
        : symbol[standard][bits ? "bits" : "bytes"][e];

    if (unix) {
      result[1] =
        standard === "jedec"
          ? result[1].charAt(0)
          : e > 0
            ? result[1].replace(/B$/, "")
            : result[1];

      if (byteTest.test(result[1])) {
        result[0] = Math.floor(result[0]);
        result[1] = "";
      }
    }
  }

  // Decorating a 'diff'
  if (neg) {
    result[0] = -result[0];
  }

  // Applying custom symbol
  result[1] = symbols[result[1]] || result[1];

  // Returning Array, Object, or String (default)
  if (output === "array") {
    return result;
  }

  if (output === "exponent") {
    return e;
  }

  if (output === "object") {
    return { value: result[0], symbol: result[1] };
  }

  if (full) {
    result[1] = fullforms[e]
      ? fullforms[e]
      : fullform[standard][e] +
        (bits ? "bit" : "byte") +
        (result[0] === 1 ? "" : "s");
  }

  if (separator.length > 0) {
    result[0] = result[0].toString().replace(".", separator);
  }

  return result.join(spacer);
};

@Pipe({
  name: "fileSize",
})
export class FileSizePipe implements PipeTransform {
  transform(value: string | number, options: any = {}): string {
    const cfg = Object.assign({ to: "string", round: 0 }, options);

    if (cfg.to === "string") {
      return fileSizeToHumanReadable(value, cfg);
    } else {
      return fileSizeReadableToNumber(value);
    }
  }
}
