import {
  Input,
  Directive,
  HostBinding,
  OnInit,
  ElementRef,
  ChangeDetectorRef,
  NgModule,
} from "@angular/core";
import { AppTheme } from "../util/themeSupports";

/**
 * <a link='link_id'>Link to Somewhere</a>
 * Pulls a value from the theme.config.json to use as href/target
 */
@Directive({
  selector: "[link]",
})
export class AppConfiguredLinkDirective implements OnInit {
  @Input("link") linkId: string;

  @HostBinding("href") linkHref = "";
  @HostBinding("target") linkTarget = "";

  constructor(
    private appTheme: AppTheme,
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    const link = this.appTheme.link(this.linkId);

    if (!link) {
      console.error(
        "An element tried to use a [link] attribute of: " +
          this.linkId +
          " but no link is defined with that name.",
      );
      console.error(this.elementRef.nativeElement);
      return;
    }

    this.linkHref = link.url;
    this.linkTarget =
      this.elementRef.nativeElement.getAttribute("target") || link.target;

    this.changeDetector.markForCheck();
  }
}

@NgModule({
  declarations: [AppConfiguredLinkDirective],
  exports: [AppConfiguredLinkDirective],
})
export class AppLinkModule {}
