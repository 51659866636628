<div class="search-result">
  <div
    *ngIf="image"
    class="search-result-image"
    [ngClass]="{ 'search-result-image-blocked': isBlockedContent }"
    [style.backgroundImage]="!image ? 'none' : 'url(' + image + ')'"
  >
    <ab-icon *ngIf="isBlockedContent" name="deny" size="24"></ab-icon>
  </div>

  <div
    *ngIf="!image"
    class="search-result-image search-result-image-background"
    [ngClass]="{ 'search-result-image-blocked': isBlockedContent }"
  >
    <ab-icon *ngIf="isBlockedContent" name="deny" size="24"></ab-icon>
    <ng-container
      *ngIf="!isBlockedContent && resource.type !== 'link'"
      [ngSwitch]="attachedFileType"
    >
      <ab-icon *ngSwitchCase="'video'" name="play" size="20"></ab-icon>
      <ab-icon *ngSwitchCase="'article'" name="document" size="24"></ab-icon>
      <ab-icon *ngSwitchCase="'link'" name="link" size="24"></ab-icon>
      <p *ngSwitchCase="'zip'">{{ attachedFileType }}</p>
      <p *ngSwitchCase="'pdf'">{{ attachedFileType }}</p>
      <ab-icon *ngSwitchDefault name="document" size="24"></ab-icon>
    </ng-container>
    <ab-icon *ngIf="resource.type === 'link'" name="link" size="24"></ab-icon>
  </div>

  <div class="search-result-content">
    <div class="mya-h5 color-grey-90">{{ resource.title }}</div>

    <div class="search-result-details color-grey-70">
      <mat-chip
        *ngIf="category"
        highlighted
        disableRipple
        class="material"
        color="accent"
      >
        <span class="material-label">{{ category }}</span>
      </mat-chip>

      <ng-container *ngIf="!isBlockedContent">
        <p *ngIf="featuredInCount === 1; else elseBlock">
          <span
            i18n="
              searchResult.text|Search Result Text - Featured
              in@@searchResultFeaturedInSeries"
          >
            Featured in
            {{ featureList[0] // i18n: (ph="series") }}
          </span>
        </p>

        <ng-template #elseBlock>
          <mat-form-field *ngIf="featuredInCount" class="search-result-select">
            <mat-label>
              <span
                i18n="
                  searchResult.text|Search Result Text -Featured in Many
                  Series@@searchResultFeaturedInManySeries"
              >
                Featured in
                {{ featuredInCount // i18n: (ph="featured_in_count") }}
                series
              </span>
            </mat-label>
            <mat-select class="feature-list">
              <mat-option
                *ngFor="let user of featureList"
                class="search-result-select-option"
              >
                {{ user }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>

        <p *ngIf="videoDuration" class="search-result-duration">
          {{ videoDuration | playTime }}
          <span
            i18n="
              searchResult.type|Search Result Type -
              Video@@searchResultTypeVideo"
            >Video</span
          >
        </p>
        <p *ngIf="resource?.attachedDocument?.file">
          {{ resource?.attachedDocument?.file }}
        </p>
        <p *ngIf="resource?.attachedDocument?.filesize">
          {{ resource?.attachedDocument?.filesize }}
        </p>
      </ng-container>

      <span
        *ngIf="isBlockedContent"
        i18n="
          searchResult.text|Search Result Text - Run this Alpha course to access
          series material@@searchResultRunThisAlpha"
        >Run this Alpha course to access series material</span
      >
    </div>
  </div>

  <div class="search-result-actions">
    <ng-container *ngIf="!isBlockedContent">
      <button
        *ngIf="resource.canPreview"
        class="mya-text-button"
        (click)="onClickResult(resource)"
        mat-button
      >
        <span
          i18n="
            searchResult.action|Search Result Action -
            Preview@@searchResultActionPreview"
          >Preview</span
        >
        <ng-container [ngSwitch]="attachedFileType">
          <span
            *ngSwitchCase="'video'"
            i18n="
              searchResult.type|Search Result Type -
              Video@@searchResultTypeVideo"
            >Video</span
          >
          <span *ngSwitchCase="'zip'">ZIP</span>
          <span *ngSwitchCase="'pdf'">PDF</span>
          <span
            *ngSwitchDefault
            i18n="
              searchResult.type|Search Result Type - File@@searchResultTypeFile"
            >File</span
          >
        </ng-container>
        <ab-icon name="eyes"></ab-icon>
      </button>

      <button
        *ngIf="!resource.is_lesson"
        class="mya-text-button"
        mat-button
        (click)="handleResourceClick(resource, $event)"
      >
        <span
          *ngIf="resource.type !== 'link'"
          i18n="
            searchResult.action|Search Result Action -
            Download@@searchResultActionDownload"
          >Download</span
        >

        <span
          *ngIf="resource.type === 'link'"
          i18n="
            searchResult.action|Search Result Action -
            Follow@@searchResultActionFollow"
          >Follow</span
        >
        <ng-container [ngSwitch]="attachedFileType">
          <span
            *ngSwitchCase="'video'"
            i18n="
              searchResult.type|Search Result Type -
              Video@@searchResultTypeVideo"
            >Video</span
          >
          <span *ngSwitchCase="'zip'">ZIP</span>
          <span *ngSwitchCase="'pdf'">PDF</span>
          <span *ngSwitchCase="'link'" i18n="common.words|Link@@commonWordLink"
            >Link</span
          >
          <span
            *ngSwitchDefault
            i18n="
              searchResult.type|Search Result Type - File@@searchResultTypeFile"
            >File</span
          >
        </ng-container>
        <ab-icon *ngIf="resource.type !== 'link'" name="download"></ab-icon>
        <ab-icon *ngIf="resource.type === 'link'" name="arrow-right"></ab-icon>
      </button>

      <button
        *ngIf="resource.is_lesson"
        class="mya-text-button"
        mat-button
        (click)="onClickResult(resource)"
      >
        <span
          i18n="
            searchResult.action|Search Result Action - View Learning
            Module@@searchResultActionViewLearningModule"
          >View Learning Module</span
        >
        <ab-icon name="arrow-right"></ab-icon>
      </button>
    </ng-container>

    <button
      *ngIf="isBlockedContent"
      class="mya-text-button"
      mat-button
      (click)="onNavigateToSeriesDetails(resource)"
    >
      <span
        i18n="
          searchResult.action|Search Result Action - View Alpha
          Series@@searchResultActionViewAlphaSeries"
        >View Alpha Series</span
      >
      <ab-icon name="arrow-right"></ab-icon>
    </button>
  </div>
</div>
