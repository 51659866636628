import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PageData } from "./page-data.class";

@Injectable()
export class PageService {
  private _endpoint = "wp-json/wp/v2/page";

  constructor(private http: HttpClient) {}

  /**
   * Get page data by the page path
   */
  getPage(path: string): Observable<PageData> {
    return this.http
      .get(this._endpoint + "/" + path)
      .pipe(map((result) => new PageData(result)));
  }
}
