export const browserSubtitleLanguageMap: { [key: string]: string[] } = {
  'en-US': [
    'en',
    'en-us',
    'en-au',
    'en-ca',
    'en-nz',
    'en-ie',
    'en-za',
    'en-jm',
    'en-bz'
  ],
  en: [
    'en',
    'en-us',
    'en-au',
    'en-ca',
    'en-nz',
    'en-ie',
    'en-za',
    'en-jm',
    'en-bz',
    'en-gb'
  ],
  'en-GB': ['en-gb'],
  'es-419': [
    'es',
    'es-ar',
    'es-gt',
    'es-cr',
    'es-pa',
    'es-do',
    'es-mx',
    'es-ve',
    'es-co',
    'es-pe',
    'es-ec',
    'es-cl',
    'es-uy',
    'es-py',
    'es-bo',
    'es-sv',
    'es-hn',
    'es-ni',
    'es-pr',
    'es-419'
  ],
  es: [
    'es',
    'es-ar',
    'es-gt',
    'es-cr',
    'es-pa',
    'es-do',
    'es-mx',
    'es-ve',
    'es-co',
    'es-pe',
    'es-ec',
    'es-cl',
    'es-uy',
    'es-py',
    'es-bo',
    'es-sv',
    'es-hn',
    'es-ni',
    'es-pr',
    'es-419'
  ],
  hi: ['hi'],
  'id-ID': ['id', 'id-id'],
  'ko-KR': ['ko', 'ko-kr'],
  ko: ['ko', 'ko-kr'],
  'ms-MY': ['ms', 'ms-my', 'ms-sg', 'ms-id'],
  pt: ['pt', 'pt-pt', 'pt-br', 'pt-ao', 'pt-mz', 'pt-cv', 'pt-gw', 'pt-st'],
  'pt-BR': [
    'pt',
    'pt-pt',
    'pt-br',
    'pt-ao',
    'pt-mz',
    'pt-cv',
    'pt-gw',
    'pt-st'
  ],
  sv: ['sv', 'sv-se'],
  ta: ['ta', 'ta-in', 'ta-lk'],
  'th-TH': ['th', 'th-th'],
  zh: ['zh', 'zh-cn', 'zh-sg', 'zh-mo'],
  'zh-TW': ['zh-tw', 'zh-hk'],
  'zh-HK': ['zh-tw', 'zh-hk']
}
