import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core'
import { Router } from '@angular/router'
import { takeUntil } from 'rxjs/operators'
import { CurrentUser } from '@builder/users'
import { AppTheme } from '@builder/common/util/themeSupports'
import { Locale } from '@builder/common/lang/locale'
import { expandDown } from '@builder/common/animations'
import { TrackingService, PageView } from '@builder/tracking'
import { WithDestroy } from '@builder/common/mixins/with-destroy'
import { FeaturesService } from '@builder/common/features/features.service'
import {
  FEATURE_SIMPLE_COURSE_CREATE,
  FEATURE_CORA
} from '../features/feature-flag'

@Component({
  selector: 'side-menu',
  templateUrl: 'side-menu.component.html',
  styleUrls: ['side-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandDown('expandDown')]
})
export class SideMenuComponent extends WithDestroy() implements OnDestroy {
  @Output() toggleSearchPanelEvent: EventEmitter<any> = new EventEmitter()

  @HostBinding('hidden') get isHidden(): boolean {
    return this.menuHidden
  }

  public menuVisible: boolean
  public isSearchPage: boolean
  private menuHidden: boolean // some views may request that the entire nav be hidden
  public additionalMenuItems: { title: string; icon: string; slug: string }[]
  public simpleCreateCourseIsEnabled: boolean = false
  public versionAndYear: string = ''
  public coraEnabled: boolean = false

  constructor(
    public theme: AppTheme,
    private cd: ChangeDetectorRef,
    public currentUser: CurrentUser,
    public router: Router,
    public locale: Locale,
    private trackingService: TrackingService,
    private features: FeaturesService
  ) {
    super()

    this.trackingService.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event instanceof PageView) {
          this.menuVisible = false
          this.isSearchPage = event.data.url.indexOf('/search') === 0
          this.cd.markForCheck()
        }
      })

    this.additionalMenuItems = theme.value('additional_menu_items')
    this.simpleCreateCourseIsEnabled = this.features.isOn(
      FEATURE_SIMPLE_COURSE_CREATE
    )
    this.versionAndYear =
      this.theme.value('version') + ' ' + new Date().getFullYear()
    this.coraEnabled = this.features.isOn(FEATURE_CORA)
  }

  public toggleSearch(): void {
    this.toggleSearchPanelEvent.emit()
    this.toggleMenu()
  }

  public toggleMenu(): void {
    this.menuVisible = !this.menuVisible
    this.cd.detectChanges()
  }
}
