<div
  *ngIf="!isMobileView"
  [matMenuTriggerFor]="menu"
  class="account-settings-container"
  data-qa-name="account-container-menu"
>
  <div
    class="account-settings-title"
    [class.active]="showAccountMenu"
    [attr.data-driver]="'overview5'"
  >
    <span
      i18n="
        userHeader.action|User Header Action - My Account
        Settings@@userHeaderMyAccountSettings"
      >My account settings</span
    >
    <ab-icon name="profile" size="24"></ab-icon>
  </div>

  <mat-menu
    #menu="matMenu"
    xPosition="before"
    class="header-account-menu"
    data-qa-name="account-dropdown-menu"
  >
    <div class="account-settings-title">
      <span
        i18n="
          userHeader.action|User Header Action - My Account
          Settings@@userHeaderMyAccountSettings"
        >My account settings</span
      >
      <ab-icon name="profile" size="24"></ab-icon>
    </div>
    <a
      mat-menu-item
      class="header-account-menu-option link"
      data-qa-name="account-dropdown-accountDetails"
      (click)="navigateToAccountDetails()"
    >
      <span
        class="account-settings-link-text"
        i18n="
          userHeader.link|User Header Link - Account
          Details@@userHeaderAccountDetails"
        >Account details</span
      >
    </a>
    <a
      mat-menu-item
      class="header-account-menu-option link"
      data-qa-name="account-dropdown-notificationSettings"
      (click)="navigateToNotificationSettings()"
    >
      <span
        class="account-settings-link-text"
        i18n="
          userHeader.link|User Header Link - Notification
          Settings@@userHeaderNotificationSettings"
        >Notification settings</span
      >
    </a>
    <div
      mat-menu-item
      routerLinkActive
      data-qa-name="account-dropdown-courseSettings"
      class="header-account-menu-option"
      [routerLink]="'profile/course-settings'"
    >
      <span i18n="page.title|Course Settings Label@@labelCourseSettings"
        >Course settings</span
      >
    </div>
  </mat-menu>
</div>
<div
  *ngIf="isMobileView"
  class="account-settings-container-mobile"
  data-qa-name="account-container-menuMobile"
>
  <div
    class="account-settings-title-mobile"
    (click)="toggleAccountMenu()"
    [attr.data-driver]="'overview5'"
  >
    <ab-icon class="link" name="profile" size="24"></ab-icon>
  </div>

  <div
    class="account-settings-title-mobile-active"
    [@accountMenuToggle]="showAccountMenu ? 'open' : 'closed'"
    (@accountMenuToggle.done)="accountAnimationDone($event)"
    data-qa-name="account-pannel-menuMobile"
  >
    <div class="account-menu-mobile-grey-box"></div>
    <div
      *ngIf="showAccountMenu"
      class="account-back-btn"
      (click)="toggleAccountMenu()"
      role="button"
      data-qa-name="account-button-back"
    >
      <ab-icon class="link" name="chevron-left" size="18"></ab-icon>
    </div>
    <div *ngIf="showAccountMenu" class="account-menu-mobile-heading">
      <h1
        class="mya-h1"
        i18n="
          userHeader.action|User Header Action - My Account
          Settings@@userHeaderMyAccountSettings"
      >
        My account settings
      </h1>
    </div>
    <div
      *ngIf="showAccountMenu && isMobileView"
      class="header-account-menu-mobile"
    >
      <a
        class="header-account-menu-option-mobile"
        data-qa-name="account-panel-accountDetails"
        (click)="navigateToAccountDetails()"
      >
        <h2
          class="mya-h2"
          i18n="
            userHeader.link|User Header Link - Account
            Details@@userHeaderAccountDetails"
        >
          Account details
        </h2>
        <ab-icon
          name="chevron-right"
          class="icon-chevron-right"
          size="18"
        ></ab-icon>
      </a>
      <a
        class="header-account-menu-option-mobile"
        data-qa-name="account-panel-notificationSettings"
        (click)="navigateToNotificationSettings()"
      >
        <h2
          class="mya-h2"
          i18n="
            userHeader.link|User Header Link - Notification
            Settings@@userHeaderNotificationSettings"
        >
          Notification settings
        </h2>
        <ab-icon
          name="chevron-right"
          class="icon-chevron-right"
          size="18"
        ></ab-icon>
      </a>
      <div
        routerLinkActive
        data-qa-name="account-panel-courseSettings"
        class="header-account-menu-option-mobile"
        [routerLink]="'profile/course-settings'"
      >
        <h2
          class="mya-h2"
          i18n="page.title|Course Settings Label@@labelCourseSettings"
        >
          Course settings
        </h2>
        <ab-icon
          name="chevron-right"
          class="icon-chevron-right"
          size="18"
        ></ab-icon>
      </div>
    </div>
  </div>
</div>
