import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  DoCheck,
  SimpleChanges,
  OnChanges,
} from "@angular/core";

@Component({
  selector: "ab-icon",
  template: `<span #icon></span><ng-content></ng-content>`,
  styleUrls: ["ab-icon.component.less"],
})
export class BuilderIconComponent implements OnChanges {
  @Input() name: string;
  @Input() color: string;
  @Input() size: number;
  @Input() rotation: number;
  @Input() position: "before" | "after" = "before";
  @Input() flip: boolean = false;

  @Input() iconStyle = "";

  @ViewChild("icon", { static: true }) iconDiv: ElementRef;

  constructor(private el: ElementRef) {}

  ngOnChanges(c: SimpleChanges) {
    this.setIcon();
  }

  private setIcon(): void {
    const icon: HTMLElement = this.iconDiv?.nativeElement;
    if (!icon) {
      return;
    }

    let className = `icon-${this.position}`;

    if (this.color) {
      icon.style.color = this.getColor(this.color);
    }
    if (this.name) {
      className += ` builder-icon builder-icon-${this.name}`;
    }
    if (this.size) {
      icon.style.fontSize = this.size + "px";
    }
    if (this.rotation) {
      icon.style.transform = "rotateZ(" + this.rotation + "deg)";
    }
    if (this.flip) {
      icon.style.transform =
        icon.style.transform + "rotateX(0deg) rotateY(180deg)";
    }
    if (this.iconStyle) {
      className += ` style-${this.iconStyle}`;
    }

    icon.className = className;

    if (this.position === "after") {
      this.el.nativeElement.insertBefore(icon, null);
    } else {
      this.el.nativeElement.insertBefore(
        icon,
        this.el.nativeElement.firstChild,
      );
    }
  }

  private getColor(val: string): string {
    if (val === "red") {
      val = "#e42312";
    }
    return val;
  }
}
