export class Announcement {
  public id: number;
  public title: string;
  public content: string;
  public date: string;

  constructor(data: any) {
    this.id = data.id ?? 0;
    this.title = data.title ?? "";
    this.content = data.content ?? "";
    this.date = data.date ?? null;
  }
}
