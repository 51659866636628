<div class="resource-preview">
  <ng-container *ngIf="isVideo">
    <video-player
      [autoPlay]="videoArgs.autoPlay"
      [src]="media.url"
      (videoReady)="ready = true"
    ></video-player>
  </ng-container>

  <ng-container *ngIf="isPDF">
    <iframe allowfullscreen [src]="iframeSrc" (load)="ready = true"></iframe>
  </ng-container>

  <div class="image-container" *ngIf="isImage">
    <img
      [src]="media.url"
      alt=""
      class="image-container-image"
      (load)="ready = true"
    />
  </div>
</div>
<div class="resource-info">
  <div class="resource-info-text">
    <h6 class="resource-info-title">{{ mediaTitle }}</h6>
    <span class="resource-info-description">{{ description }}</span>
  </div>
  <button
    *ngIf="media.file || media.meta?.download?.length"
    (click)="onNextClick()"
    mat-flat-button
    color="primary"
    i18n="alpha.link|Download text@@download"
  >
    Download
  </button>
</div>
