import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CurrentUser } from "@builder/users";
import { AppTheme } from "../util/themeSupports";
import { Locale } from "../lang/locale";
import { UserService } from "@builder/users/service";
import { CookieService } from "../cookies/cookies.service";
import { UserPreferences } from "@builder/users/preferences";
import { FeaturesService } from "../features/features.service";
import { FEATURE_PRODUCT_TOUR } from "../features/feature-flag";

@Component({
  selector: "user-header",
  templateUrl: "./user-header.component.html",
  styleUrls: ["./user-header.component.less"],
})
export class UserHeaderComponent {
  public productTourOverviewIsEnabled: boolean = false;

  constructor(
    public theme: AppTheme,
    public currentUser: CurrentUser,
    public router: Router,
    private userService: UserService,
    public locale: Locale,
    private cookies: CookieService,
    public userPreferences: UserPreferences,
    public featuresService: FeaturesService,
  ) {
    this.featuresService.whenChanged(FEATURE_PRODUCT_TOUR).subscribe((status) => (this.productTourOverviewIsEnabled = status.enabled));
  }

  public doLogout() {
    this.cookies.deleteAllWithName("dismissed_user_modal", "/");
    sessionStorage.clear();
    setTimeout(() => {
      this.userService.logout().subscribe();
    }, 2000);
  }
}
