import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "alphaUserRole",
})
export class AlphaUserRolePipe implements PipeTransform {
  protected i18n = {
    author: $localize`:alpha.users|Alpha User Roles - Administrator@@userRoleLabel_author:Administrator`,
    team_member: $localize`:alpha.users|Alpha User Roles - Team Member@@userRoleLabel_team_member:Team Member`,
  };

  transform(type: string): string {
    return this.i18n[type] ?? type;
  }
}
