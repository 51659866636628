import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  PopularSearchTermsComponent,
  ExcludeTermsPipe,
} from "./popular-search-terms.component";

@NgModule({
  imports: [CommonModule],
  declarations: [PopularSearchTermsComponent, ExcludeTermsPipe],
  exports: [PopularSearchTermsComponent],
})
export class PopularSearchTermsModule {}
