import * as React from 'react'
import { useEffect, useRef, FunctionComponent } from 'react'
import Player from '@vimeo/player'
interface VimeoVideoPlayerProps {
  // videoId: string
  url?: string
  onPlay?: () => void
  onProgress?: (data: { secondsElapsed?: number; percentage?: number }) => void
}

const VimeoVideoPlayer: FunctionComponent<VimeoVideoPlayerProps> = ({
  url,
  onPlay,
  onProgress
}) => {
  const iframeRef = useRef(null)
  const playerRef = useRef(null)

  // Method to create the Vimeo player
  const createPlayer = () => {
    if (!iframeRef.current) return
    const player = new Player(iframeRef.current, {
      // id: videoId,
      autoplay: false
    })

    playerRef.current = player

    player
      .ready()
      .then(() => {
        iframeRef.current.style.visibility = 'visible'
      })
      .catch((err) => {
        //show the playback error
        console.error(err)
      })

    // Add player event listeners
    player.on('play', () => {
      if (onPlay) onPlay()
    })

    player.on('timeupdate', (progressData) => {
      const progressPercentage =
        (progressData.seconds / progressData.duration) * 100
      if (onProgress) onProgress(progressData)
    })
  }

  useEffect(() => {
    createPlayer()
  }, [url])

  return (
    <iframe
      ref={iframeRef}
      src={url}
      allowFullScreen={true}
      allow='autoplay'
      className='aspect-video w-full'
    />
  )
}

export default VimeoVideoPlayer
