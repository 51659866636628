import { Injectable } from "@angular/core";

import { AppTheme } from "@builder/common/util/themeSupports";

import { TidioSettings } from "./tidio-settings";

/**
 * TidioService will attach the client JS library
 */
@Injectable()
export class TidioService {

  constructor(protected settings: TidioSettings) {
    this.initialize();
  }

  /**
   * Initialize tracker object with our settings
   */
  private initialize(): void {
    this.loadDeps();
  }

  /**
   * Create and attach script element for IntercomService client js
   */
  private loadDeps(): void {
    const s = document.createElement("script");
    s.async = true;
    s.src = `//code.tidio.co/${this.settings.tidio_id}.js`;

    document.body.appendChild(s);
  }
}

export const tidioSettingsProviderFactory = (appTheme: AppTheme) => {
  const obj = appTheme.value("tracking/tidio");
  return new TidioSettings(obj);
};

export let tidioSettingsProvider = {
  provide: TidioSettings,
  useFactory: tidioSettingsProviderFactory,
  deps: [AppTheme],
};
