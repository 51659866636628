import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class BackLinkService {
  private history: Array<string> = [];
  public previousRoute = new BehaviorSubject(undefined);

  constructor(
    private router: Router,
    private location: Location,
  ) {
    /**
     * Listen to router navigation end events, pushing the url into our local history
     */
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.history[this.history.length - 1] !== event.urlAfterRedirects) {
          this.history.push(event.urlAfterRedirects);
        }
      }
    });
  }

  /**
   * Navigate the browser back if local history or do nothing
   */
  public navigateBack(): boolean {
    if (this.history.length > 1) {
      this.location.back();
      return true;
    }
    return false;
  }

  public navigate(instructions: Array<string>): void {
    this.router.navigate(instructions);
  }
}
