import * as React from 'react'
import { FunctionComponent, useState, useEffect, useContext } from 'react'
import { Select, SelectItem } from '@alpha-international/alpha-ui'
import { IoChevronDown as ChevronDownIcon } from 'react-icons/io5'
import VideoPlayerModalDownloadButton from './video-player-modal-download-button'
import {
  VideoPlayerModalContext,
  VideoPlayerModalShareUrlContext
} from './video-player-modal'
import { BRIGHTCOVE, VIMEO } from '../video-player/video-player'
import VideoPlayerModalDownloadHelperText from './video-player-modal-download-helper-text'
import { createShareUrlById } from './video-player-utils'

const VideoPlayerModalDownloadOptions: FunctionComponent = () => {
  const { options, provider } = useContext(VideoPlayerModalContext)
  const { setShareUrl } = useContext(VideoPlayerModalShareUrlContext)

  const [language, setLanguage] = useState<{
    audio: string | null
    subtitle: string | null
  }>({
    audio: null,
    subtitle: null
  })

  const [languageLabel, setLanguageLabel] = useState<string>('')
  const [renditionLabel, setRenditionLabel] = useState<string>('')

  const [languageOptions, setLanguageOptions] = useState(
    options.languageOptions
  )
  const [renditionOptions, setRenditionOptions] = useState<any>()
  const [rendition, setRendition] = useState<string | null>('')
  const [download, setDownload] = useState<{ id: string; url: string }>()

  useEffect(() => {
    if (languageOptions && languageOptions.length > 0) {
      const firstOption = languageOptions[0].value
      setLanguage({
        audio: firstOption.audio,
        subtitle: firstOption.subtitle || ''
      })
      setLanguageLabel(languageOptions[0].label)
    }
  }, [])

  useEffect(() => {
    const { audio, subtitle } = language
    if (
      audio &&
      subtitle &&
      options.audioLanguages[audio].subtitles[subtitle]
    ) {
      setRenditionOptions(options.audioLanguages[audio].subtitles[subtitle])
      setLanguageLabel(options.audioLanguages[audio].subtitles[subtitle].label)
      return
    }
    if (audio) {
      setRenditionOptions(options.audioLanguages[audio].videoData)
      setLanguageLabel(options.audioLanguages[audio].videoData.label)
      return
    }
  }, [language])

  useEffect(() => {
    if (!renditionOptions) return

    if (renditionOptions.list.includes(rendition)) {
      setDownload({
        id: renditionOptions.id,
        url: renditionOptions.renditions[rendition].isExpired
          ? ''
          : renditionOptions.renditions[rendition].link
      })
    } else {
      setRendition(renditionOptions.list[0])
    }
    if (provider === VIMEO && renditionOptions.videoId) {
      setShareUrl(createShareUrlById(renditionOptions.videoId))
    }
  }, [renditionOptions])

  useEffect(() => {
    if (!rendition) return

    setRenditionLabel(renditionOptions.renditions[rendition].label)
    setDownload({
      id: renditionOptions.id,
      url: renditionOptions.renditions[rendition].isExpired
        ? ''
        : renditionOptions.renditions[rendition].link
    })
  }, [rendition])

  const selectStyles =
    'max-w-none bg-white p-3 text-start rounded border border-alpha-oatmeal-30 flex-row-reverse'

  const handleLanguage = (input) => {
    const value = JSON.parse(input)
    setLanguage(value)
  }

  const handleRendition = (input) => {
    setRendition(input)
  }

  if (!languageOptions || !renditionOptions) {
    return <p>Loading..</p>
  }

  if (languageOptions.length < 2 && renditionOptions.list.length < 2) {
    return (
      <div className='flex flex-col gap-6 py-6 px-4 bg-alpha-oatmeal'>
        {provider === BRIGHTCOVE ? (
          <VideoPlayerModalDownloadHelperText />
        ) : null}
        <VideoPlayerModalDownloadButton
          download={download}
          rendition={rendition}
        />
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-6 py-6 px-4 bg-alpha-oatmeal'>
      <div className='flex flex-col gap-3 md:min-w-80'>
        <label className='font-display font-medium text-sm'>
          {$localize`:videoDownloadOptions.label|Video Download Options label@@VideoDownloadOptionsLabel:Video Download Options`}
        </label>

        {languageOptions && languageOptions.length > 1 ? (
          <Select
            name='language'
            value={languageLabel}
            options={{
              hideCaratOnSmallScreen: true,
              customClassNames: {
                select: selectStyles
              }
            }}
            setValue={handleLanguage}
            icon={<ChevronDownIcon size={24} />}
          >
            {languageOptions.map((option) => (
              <SelectItem
                value={JSON.stringify(option.value)}
                key={JSON.stringify(option.value)}
              >
                {option.label}
              </SelectItem>
            ))}
          </Select>
        ) : null}

        {renditionOptions && renditionOptions.list.length > 1 ? (
          <Select
            name='renditions'
            value={renditionLabel}
            options={{
              hideCaratOnSmallScreen: true,
              customClassNames: {
                select: selectStyles
              }
            }}
            setValue={handleRendition}
            icon={<ChevronDownIcon size={24} />}
          >
            {Object.keys(renditionOptions.renditions).map((rendition, idx) => {
              const { label } = renditionOptions.renditions[rendition]
              return (
                <SelectItem value={rendition} key={rendition + idx}>
                  {label}
                </SelectItem>
              )
            })}
          </Select>
        ) : null}

        {provider === BRIGHTCOVE ? (
          <VideoPlayerModalDownloadHelperText />
        ) : null}
      </div>
      <VideoPlayerModalDownloadButton
        download={download}
        rendition={rendition}
      />
    </div>
  )
}

export default VideoPlayerModalDownloadOptions
