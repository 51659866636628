import { Inject, Injectable } from '@angular/core'
import { createElement, FunctionComponent, ReactElement } from 'react'
import { createRoot, Root } from 'react-dom/client'
import hash from 'object-hash'
import ReactUI from '@builder/react-ui'
import { DOCUMENT } from '@angular/common'

type ReactComponentName =
  | 'VideoPlayerModal'
  | 'VideoPlayer'
  | 'Example'
  | 'SubtitlesPage'
  | 'ErrorBoundary'

type ReactErrorNotificationName = 'ErrorNotification'

@Injectable({
  providedIn: 'root'
})
export class ReactService {
  private reactRootCache: { [key: string]: Root } = {}
  constructor(@Inject(DOCUMENT) private document: Document) {}

  render(
    reactComponentName: ReactComponentName | ReactErrorNotificationName,
    props: any | undefined
  ) {
    const component = ReactUI[reactComponentName] as FunctionComponent<
      ReactElement<any, string>
    >

    if (!component) {
      throw `There is no React component matching this name:
      ${reactComponentName}`
    }
    const id = hash({ reactComponentName, props })

    if (this.reactRootCache[id]) {
      document.getElementById(id).remove()
    }
    const domNode = this.document.createElement('div')
    domNode.id = id
    this.document.body.appendChild(domNode)
    this.reactRootCache[id] = createRoot(domNode)
    this.reactRender(this.reactRootCache[id], component, props)
  }

  reactRender(
    root: Root,
    component: FunctionComponent<ReactElement<any, string>>,
    props: any | undefined
  ) {
    const wrapperWithErrorBoundary = ReactUI['ErrorBoundary']
    root.render(
      createElement(wrapperWithErrorBoundary, {
        component,
        componentProps: {
          ...props,
          unmount: () => {
            root.unmount()
          }
        }
      })
    )
  }
}
