import { APP_INITIALIZER, Inject, Injectable, Optional } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable, Subject, tap } from 'rxjs'

import {
  TokenValidated,
  TrackingService,
  UserLogin,
  UserLogout
} from '@builder/tracking'

import { Feature, FeaturesResponse } from './features'
import { FeaturesMiddleWare, FEATURES_MIDDLEWARE } from './features-middleware'
import { environment } from '@builder/environments/environment'
import { AppTheme } from '../util/themeSupports'

// API Endpoint
const SETTINGS_ENDPOINT = 'wp-json/wp/v2/features-config'

type UserAttributes = {
  loggedIn?: boolean
  id?: number
  country?: string
  organizationId?: string | string[]
  role?: string | string[]
  myaBlogId?: number
}

/**
 * Service for loading features from myAlpha
 * If a FeaturesMiddleWare is provided the setting/testing of features is off-loaded to that class
 */
@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  private features: Map<string, Feature> = new Map()
  protected userAttributes: UserAttributes

  constructor(
    private http: HttpClient,
    private tracking: TrackingService,
    @Optional()
    @Inject(FEATURES_MIDDLEWARE)
    public middleware: FeaturesMiddleWare,
    private appTheme: AppTheme
  ) {
    this.initEvents()
    this.userAttributes = {}
  }

  /**
   * Tap into App events to possibly update the user attributes
   */
  private initEvents(): void {
    this.tracking.events.subscribe((event) => {
      /**
       * Login or Token Validated ( site ready )
       */
      if (event instanceof TokenValidated || event instanceof UserLogin) {
        this.updateUserAttributes({
          loggedIn: true,
          id: event.data.user.id,
          country: event.data.user.country,
          role: event.data.user.user_roles[0],
          organizationId: event.data.user.organizationId,
          myaBlogId: this.appTheme.value('blogId')
        })
      } else if (event instanceof UserLogout) {
        this.updateUserAttributes({ loggedIn: false })
      }
    })
  }

  /**
   * Load the Settings
   */
  public load(): Observable<any> {
    if ((environment as Record<string, any>).localEnvironment) {
      return this.loadFromLocalGrowthbook()
    } else {
      return this.loadFromGrowthbook()
    }
  }

  /**
   * Load the Settings
   */
  public loadFromLocalGrowthbook(): Observable<any> {
    const _endpoint = (environment as Record<string, any>)
      .GROWTHBOOK_SDK_ENDPOINT

    return this.http
      .get(window.location.origin + '/api/features/' + _endpoint)
      .pipe(
        tap((result) => {
          const featuresObj = Object.entries(
            result.features as Record<string, any>
          ).reduce((acc, curr) => {
            const [key, val] = curr
            acc[key] = {
              key,
              enabled: val.defaultValue,
              data: null,
              rules: []
            }
            return acc
          }, {})
          this.onLoadFeatures(featuresObj as FeaturesResponse)
        })
      )
  }

  /**
   * Load the Settings
   */
  public loadFromGrowthbook(): Observable<any> {
    return this.http
      .get<FeaturesResponse>(SETTINGS_ENDPOINT)
      .pipe(tap((result) => this.onLoadFeatures(result)))
  }

  private onLoadFeatures(result: FeaturesResponse) {
    if (this.middleware) {
      this.middleware.setFeatures(result)
      return
    }

    Object.values(result).forEach((feature: Feature) =>
      this.setFeature(feature.key, feature)
    )
  }

  /**
   * Set a feature and create a Subject for it's observation
   */
  private setFeature(key: string, feature: Feature) {
    if (this.middleware) {
      this.middleware.setFeature(key, feature)
      return
    }
    this.features.set(key, feature)
  }

  /**
   * Check if a feature is enabled
   */
  public isOn(key: string): boolean {
    if (this.middleware) {
      return this.middleware.isOn(key)
    }

    const feature = this.features.get(key)
    return feature && feature.enabled
  }

  /**
   * Check if a feature is disabled
   */
  public isOff(key: string): boolean {
    if (this.middleware) {
      return this.middleware.isOff(key)
    }

    const feature = this.features.get(key)
    return !feature || !feature.enabled
  }

  /**
   * whenChanged for when status of a flag changes
   */
  public whenChanged(key: string): Subject<Feature> {
    if (this.middleware) {
      return this.middleware.whenChanged(key)
    }
    console.warn(
      'whenChanged is not supported for default feature implementation'
    )

    // return a subject that will never notify
    return new Subject()
  }

  /**
   * Get features
   */
  public getFeatures() {
    if (this.middleware) {
      return this.middleware.getFeatures()
    }
    return this.features
  }

  /**
   * Update user attributes
   */
  public updateUserAttributes(val: UserAttributes): void {
    if (this.middleware) {
      this.userAttributes = { ...this.userAttributes, ...val }
      return this.middleware.updateUserAttributes(this.userAttributes)
    }
    console.warn(
      'User Attributes not supported for default feature implementation'
    )
  }
}

/**
 * Provider for import into app module
 */
export const featuresProvider = {
  provide: APP_INITIALIZER,
  deps: [FeaturesService],
  useFactory: (service: FeaturesService) => () => service.load(),
  multi: true
}
