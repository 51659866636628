import { AppTheme } from "@builder/common/util/themeSupports";

export class TrainingSettings {
  public numberFreePlaylistViews: number;

  constructor(data?) {
    for (const prop in data) {
      this[prop] = data[prop];
    }
  }
}

export const trainingSettingsProviderFactory = (
  appTheme: AppTheme,
): TrainingSettings => new TrainingSettings(appTheme.value("training"));

export const trainingSettingsProvider = {
  provide: TrainingSettings,
  deps: [AppTheme],
  useFactory: trainingSettingsProviderFactory,
};
