import VideoPlayerModal from './video-player-modal'
import VideoPlayer from './video-player'
import Example from './example'
import ResourceCard from './resource-card'
import ResourceList from './resource-list'
import ProductCard from './product-card'
import Tabs from './tabs'
import TabContentContainer from './tab-content-container'
import SubtitlesPage from './subtitles-page'
import SeriesMaterialsPage from './series-materials-page'
import ErrorNotification from './error-notification'
import ErrorBoundary from './error-boundary'

const components = {
  VideoPlayerModal,
  Example,
  VideoPlayer,
  ResourceCard,
  ProductCard,
  ResourceList,
  Tabs,
  TabContentContainer,
  SubtitlesPage,
  SeriesMaterialsPage,
  ErrorNotification,
  ErrorBoundary
}
export default components
