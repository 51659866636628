import { Component, Input } from "@angular/core";

@Component({
  selector: "progress-circle",
  templateUrl: "progress-circle.component.html",
  styleUrls: ["progress-circle.component.less"],
})
export class ProgressCircleComponent {
  @Input() diameter = 25;
  @Input() strokeWidth = 2;
  @Input() value = 0;

  @Input() showNotStarted = true;

  public collapsed = true;
}
