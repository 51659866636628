import {
  Directive,
  Input,
  HostBinding,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Directive({
  selector: "[image-placement]",
})
export class ImagePositionDirective implements OnInit, OnDestroy {
  @Input("image-placement") imagePlacement: any;

  @HostBinding("class.image-on-top") get imageOnTop() {
    return this.currentImagePlacement === "top";
  }

  @HostBinding("class.image-on-right") get imageOnRight() {
    return this.currentImagePlacement === "right";
  }

  @HostBinding("class.image-on-bottom") get imageOnBottom() {
    return this.currentImagePlacement === "bottom";
  }

  @HostBinding("class.image-on-left") get imageOnLeft() {
    return this.currentImagePlacement === "left";
  }

  @HostBinding("class.image-none") get imageHidden() {
    return this.currentImagePlacement === "none";
  }

  public currentImagePlacement = "top";

  private subscription: Subscription;

  constructor(
    private bo: BreakpointObserver,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.imagePlacement) {
      if (typeof this.imagePlacement !== "string") {
        let defaultImagePosition: string = this.currentImagePlacement;

        if (this.imagePlacement[""]) {
          defaultImagePosition = this.imagePlacement[""];
          delete this.imagePlacement[""];
        }

        const keys = Object.keys(this.imagePlacement);

        if (!keys.length) {
          this.currentImagePlacement = defaultImagePosition;
          return;
        }

        const observer: Observable<BreakpointState> = this.bo.observe(keys);

        this.subscription = observer.subscribe((result) => {
          let imagePosition: string;

          for (const key in result.breakpoints) {
            if (this.imagePlacement[key] && result.breakpoints[key]) {
              imagePosition = this.imagePlacement[key];
            }
          }
          this.currentImagePlacement = imagePosition || defaultImagePosition;

          this.cd.markForCheck();
        });
      } else {
        this.currentImagePlacement = this.imagePlacement;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
