import {
  trigger,
  state,
  animate,
  transition,
  style,
  AnimationTriggerMetadata,
} from "@angular/animations";

export function expandDown(triggerName: string): AnimationTriggerMetadata {
  return trigger(triggerName, [
    state("void", style({ height: 0, opacity: 1 })),
    transition(":enter", [style({ opacity: 0 }), animate(150)]),
    transition(":leave", [animate(150, style({ opacity: 0 }))]),
  ]);
}

export function fadeInOut(triggerName: string): AnimationTriggerMetadata {
  return trigger(triggerName, [
    state("*", style({ opacity: 1 })),
    transition(":enter", [style({ opacity: 0 }), animate(100)]),
    transition(":leave", [animate(100, style({ opacity: 0 }))]),
  ]);
}

export function fadeIn(triggerName: string): AnimationTriggerMetadata {
  return trigger(triggerName, [
    state("*", style({ opacity: 1 })),
    transition(":enter", [style({ opacity: 0 }), animate(100)]),
  ]);
}

export function fadeOut(triggerName: string): AnimationTriggerMetadata {
  return trigger(triggerName, [
    state("*", style({ opacity: 1 })),
    transition(":leave", [style({ opacity: 0 }), animate(100)]),
  ]);
}
