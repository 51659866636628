<ab-icon name="logo" color="red" size="37"></ab-icon>

<h3 *ngIf="formMode === RecoverPwdModes.Reset && showNameFields">
  Welcome to Alpha
</h3>
<h3 *ngIf="formMode !== RecoverPwdModes.Reset || !showNameFields">
  Recover Your Password
</h3>

<form [formGroup]="form" (submit)="onFormSubmit()" [ngClass]="getFormClasses()">
  <ng-container *ngIf="formMode === RecoverPwdModes.Reset && showNameFields">
    <p>We only ask for your name to get started.</p>

    <div class="form-row">
      <label for="firstName" class="field-label">First Name</label>
      <input type="text" formControlName="firstName" id="firstName" />
    </div>

    <div class="form-row">
      <label for="lastName" class="field-label">Last Name</label>
      <input type="text" formControlName="lastName" id="lastName" />
    </div>
  </ng-container>

  <div class="messages-above">
    <p *ngIf="formMode === RecoverPwdModes.Recover" class="signon-description">
      <span>Please enter your email address to recover your password.</span>
    </p>
    <p
      *ngIf="
        formMode === RecoverPwdModes.Reset &&
        currentResponseType !== availableResponseTypes.Completed &&
        !showNameFields
      "
      class="signon-description"
    >
      <span>To complete your password recovery, please choose a password.</span>
    </p>
  </div>

  <div class="form-row" *ngIf="formMode === RecoverPwdModes.Recover">
    <label class="field-label" for="emailField">Email</label>
    <input type="text" id="emailField" formControlName="user_login" />
  </div>
  <ng-container *ngIf="formMode === RecoverPwdModes.Reset">
    <password-editor formControlName="password"></password-editor>
  </ng-container>

  <!--
	<p *ngIf="currentResponseType === availableResponseTypes.TeamInviteResent"
	   class="form-row"
	   i18n="users.message|Team Member Pwd Reset Message@@teamPasswordReset">It appears that you've been invited to a team but haven't completed your signup. We've resent you the invite email with a link to complete your registration.</p>
	-->

  <p
    *ngIf="currentResponseType === availableResponseTypes.VerificationSent"
    class="form-row"
  >
    A verification email has been sent to you. Visit the link in that email to
    confirm the password reset and choose your password.
  </p>

  <p
    *ngIf="
      formMode === RecoverPwdModes.Verifying &&
      currentResponseType !== availableResponseTypes.VerificationFailed
    "
    class="form-row"
  >
    Verifying recovery key...
  </p>

  <p
    *ngIf="
      formMode === RecoverPwdModes.Verifying &&
      currentResponseType === availableResponseTypes.VerificationFailed
    "
    class="form-row"
  >
    We were unable to verify your recovery key.
  </p>

  <div *ngIf="formMode === RecoverPwdModes.Complete" class="form-row">
    <p>Your password has been reset</p>
    <div class="button-wrap">
      <a
        href="javascript:void(0)"
        mat-flat-button
        color="primary"
        [routerLink]="['../', 'signon']"
      >
        <goto><span>Login</span></goto>
      </a>
    </div>
  </div>

  <p *ngIf="errorMessage" class="error-msg form-row">{{ errorMessage }}</p>

  <div class="form-row">
    <button
      mat-flat-button
      color="primary"
      type="submit"
      [disabled]="loading || !form.valid"
      [ngClass]="{ hide: !isMainBtnActive() }"
    >
      {{ buttonLabel }}
    </button>
  </div>
</form>

<p
  *ngIf="
    formMode === RecoverPwdModes.Recover ||
    currentResponseType === availableResponseTypes.VerificationFailed
  "
  class="user-link-container"
>
  <span>Already have an account? </span>
  <a [routerLink]="['../', 'signon']" class="active">Login</a>
</p>

<mat-progress-bar
  mode="indeterminate"
  *ngIf="loading"
  color="accent"
></mat-progress-bar>
