import { BRIGHTCOVE_ACC_ID, BRIGHTCOVE_PLAYER_ID } from '../constants'
import { VIMEO, BRIGHTCOVE, GOOGLE_DRIVE } from '../video-player/video-player'

interface VideoRenditions {
  [key: string]: {
    isExpired: boolean
    label: string
    link: string
  }
}

type LanguageOptionValue = {
  audio: string
  subtitle: string
}

type LanguageOption = {
  label: string
  value: LanguageOptionValue
}

interface VideoOptions {
  audioLanguages: {
    [key: string]: {
      id: number
      subtitlesList: string[]
      subtitles: {
        [key: string]: {
          id: number
          label: string
          list: string[]
          renditions: VideoRenditions
        }
      }
      videoData: {
        id: number
        label: string
        list: string[]
        renditions: VideoRenditions
      }
    }
  }
  languageOptions: LanguageOption[]
  languageList: string[]
}

export interface VideoProps {
  title: string
  provider: typeof VIMEO | typeof BRIGHTCOVE | typeof GOOGLE_DRIVE
  downloadTrackingByResolutionAndTitle: (rendition, episodeName) => {}
  fetchDownloadLinkById: (id) => any
  localeTranslate: (id, defaultValue) => {}
  options?: VideoOptions
  shareUrl?: string
  url?: string
  id?: string
  tag?: string
  description?: string
  relatedDocuments?
  resourceClickHandler: (resource) => any
  unmount?: () => {}
}

const getSubtitleLanguage = (localeTranslate, language) => {
  if (!localeTranslate) {
    return `Subtitle ${language}`
  }
  return localeTranslate(
    'writtenLanguageLabel_' + transformLanguageCode(language),
    language
  )
}

const getAudioLanguage = (localeTranslate, language) => {
  if (!localeTranslate) {
    return `Audio ${language}`
  }
  return localeTranslate(
    'languageLabel_' + transformLanguageCode(language),
    language
  )
}
const transformLanguageCode = (code: string): string => {
  // TW and CN should be in uppercase
  return code
    .toLowerCase()
    .replace('_', '-')
    .replace(/(cn|tw)/, (match) => match.toUpperCase())
}

const extractVideoIdFromUrl = (link: string) => {
  return link?.split('download/')?.at(1)?.split('/')?.at(0)
}

const formatResolutionLabel = ({ name, height, width, size }) =>
  `${name} - ${width}x${height}${size ? ` / ${size}` : ''}`

export const createShareUrlById = (id) => `https://player.vimeo.com/video/${id}`

const videoRenditionsReducerForVimeo = (props) => {
  const {
    id,
    download,
    audioLanguage,
    subtitleLanguage,
    localeTranslate
  }: {
    id: number
    download: any
    audioLanguage: string
    subtitleLanguage?: string
    localeTranslate
  } = props

  const videoId = extractVideoIdFromUrl(download?.at(0)?.link)

  if (!videoId) {
    throw 'Video ID could not be determined for Vimeo video'
  }

  return {
    list: download.map(({ rendition }) => rendition).reverse(),
    label: `${audioLanguage ? `${getAudioLanguage(localeTranslate, audioLanguage)} audio` : ''}${subtitleLanguage ? ` with ${getSubtitleLanguage(localeTranslate, subtitleLanguage)} subtitles` : ''}`,
    id,
    videoId,
    renditions: download.reduce(
      (
        resolutionObj,
        { rendition, link, name, height, width, size_short: size, expires }
      ) => {
        resolutionObj[rendition] = {
          label: formatResolutionLabel({
            name,
            height,
            width,
            size
          }),
          link,
          id,
          videoId: extractVideoIdFromUrl(link),
          isExpired: new Date().getTime() > new Date(expires).getTime()
        }
        return resolutionObj
      },
      {}
    )
  }
}

const formatVideoDownloadOptionsForVimeo = ({
  courseLanguage,
  languages,
  localeTranslate
}): VideoOptions => {
  const filteredLanguagesByCourseLanguage = languages
    .map((currentLanguage) => {
      if (currentLanguage.language === courseLanguage) {
        return currentLanguage
      }

      const { subtitles, ...otherProperties } = currentLanguage
      const subtitlesFilteredByCourseLanguage = subtitles.filter(
        ({ language }) => language === courseLanguage
      )

      if (subtitlesFilteredByCourseLanguage?.length) {
        return {
          ...otherProperties,
          subtitles: subtitles.filter(
            ({ language }) => language === courseLanguage
          )
        }
      }

      return null
    })
    .filter(Boolean)

  const languageList = filteredLanguagesByCourseLanguage
    .map(({ language }) => language)
    .reduce((acc, curr) => {
      return curr === courseLanguage ? [curr, ...acc] : [...acc, curr]
    }, [])

  const audioLanguages = filteredLanguagesByCourseLanguage.reduce(
    (
      languageObj,
      { language: audioLanguage, subtitles, ID: id, meta: { download } }
    ) => {
      languageObj[audioLanguage] = {
        subtitlesList: subtitles.map(({ language }) => language),
        id,
        videoData: videoRenditionsReducerForVimeo({
          id,
          audioLanguage,
          download,
          localeTranslate
        }),
        subtitles: subtitles.reduce(
          (
            subtitleObj,
            {
              language: subtitleLanguage,
              video: {
                meta: { download },
                ID: id
              }
            }
          ) => {
            subtitleObj[subtitleLanguage] = videoRenditionsReducerForVimeo({
              id,
              audioLanguage,
              subtitleLanguage,
              download,
              localeTranslate
            })
            return subtitleObj
          },
          {}
        )
      }

      return languageObj
    },
    {}
  )

  const languageOptions = languageList.reduce((list, audio) => {
    const audioOption = {
      label: audioLanguages[audio].videoData.label,
      value: {
        audio: audio,
        subtitle: ''
      }
    }
    const subtitlesList = [
      ...audioLanguages[audio].subtitlesList.map((subtitle) => {
        audioLanguages[audio].subtitles[subtitle].label
        return {
          label: audioLanguages[audio].subtitles[subtitle].label,
          value: {
            audio: audio,
            subtitle: subtitle
          }
        }
      })
    ]
    return audio === courseLanguage
      ? [...list, audioOption, ...subtitlesList]
      : [...list, ...subtitlesList]
  }, [])

  return {
    languageOptions,
    languageList,
    audioLanguages
  }
}

const googleDriveVideoRenditionsReducer = ({
  id,
  download,
  audioLanguage,
  subtitleLanguage,
  localeTranslate
}: {
  id: number
  download: any
  audioLanguage: string
  subtitleLanguage?: string
  localeTranslate
}) => {
  return {
    list: download.map(({ name }) => name).reverse(),
    label: `${audioLanguage ? `${getAudioLanguage(localeTranslate, audioLanguage)} audio` : ''}${subtitleLanguage ? ` with ${getSubtitleLanguage(localeTranslate, subtitleLanguage)} subtitles` : ''}`,
    id,
    renditions: download.reduce(
      (
        resolutionObj,
        { link, name, height, width, size_short: size, expires }
      ) => {
        resolutionObj[name] = {
          label: formatResolutionLabel({
            name,
            height,
            width,
            size
          }),
          link,
          isExpired: false
        }
        return resolutionObj
      },
      {}
    )
  }
}

const formatGoogleDriveVideoDownloadOptions = (
  languages,
  localeTranslate
): VideoOptions => {
  const languageList = languages.map(({ language }) => language)
  const audioLanguages = languages.reduce(
    (
      languageObj,
      { language: audioLanguage, subtitles, ID: id, meta: { download } }
    ) => {
      languageObj[audioLanguage] = {
        subtitlesList: subtitles.map(({ language }) => language),
        id,
        videoData: googleDriveVideoRenditionsReducer({
          id,
          audioLanguage,
          download,
          localeTranslate
        }),
        subtitles: subtitles.reduce(
          (
            subtitleObj,
            {
              language: subtitleLanguage,
              video: {
                meta: { download },
                ID: id
              }
            }
          ) => {
            subtitleObj[subtitleLanguage] = googleDriveVideoRenditionsReducer({
              id,
              audioLanguage,
              subtitleLanguage,
              download,
              localeTranslate
            })
            return subtitleObj
          },
          {}
        )
      }

      return languageObj
    },
    {}
  )

  const languageOptions = languageList.reduce((list, audio) => {
    const subList = [
      {
        label: audioLanguages[audio].videoData.label,
        value: {
          audio: audio,
          subtitle: ''
        }
      },
      ...audioLanguages[audio].subtitlesList.map((subtitle) => {
        audioLanguages[audio].subtitles[subtitle].label
        return {
          label: audioLanguages[audio].subtitles[subtitle].label,
          value: {
            audio: audio,
            subtitle: subtitle
          }
        }
      })
    ]
    return [...list, ...subList]
  }, [])

  return {
    languageOptions,
    languageList,
    audioLanguages
  }
}

const formatBrightcoveVideoDownloadOptions = (
  video,
  localeTranslate
): VideoOptions => {
  const {
    language: audioLanguage,
    ID: id,
    meta: { download }
  } = video

  const languageOptions: LanguageOption[] = [
    {
      label: `${getAudioLanguage(localeTranslate, audioLanguage)} audio`,
      value: { audio: audioLanguage, subtitle: '' }
    }
  ]

  const audioLanguages = {}
  audioLanguages[audioLanguage] = {
    id,
    subtitles: null,
    subtitlesList: [],
    videoData: {
      id,
      label: `${getAudioLanguage(localeTranslate, audioLanguage)} audio`,
      list: download.map(({ name }) => name),
      renditions: download.reduce(
        (renditionObj, { name, size, height, width, link }) => {
          const label = formatResolutionLabel({ name, height, width, size })

          let key = name

          // Check for duplicates
          let count = 1

          while (renditionObj[key]) {
            key = `${name}_${count}`
            count += 1
          }

          renditionObj[key] = { label, link }
          return renditionObj
        },
        {}
      )
    }
  }

  const languageList = [audioLanguage]

  return {
    languageOptions,
    languageList,
    audioLanguages
  }
}

const processVideoPropsForVimeo = (
  video,
  localeTranslate
): { shareUrl: string; url: string; options: VideoOptions } => {
  const { languages } = video

  return {
    shareUrl: video.external_url,
    url: video.external_url,
    options: formatVideoDownloadOptionsForVimeo({
      languages,
      localeTranslate,
      courseLanguage: video.language
    })
  }
}

const processVideoPropsForGoogleDrive = (props, localeTranslate) => {
  const {
    url,
    ID,
    meta: { download },
    languages
  } = props
  return {
    url: url,
    id: ID,
    shareUrl: url,
    downloadUrl: download[0]?.link,
    options: formatGoogleDriveVideoDownloadOptions(languages, localeTranslate)
  }
}

const processVideoPropsForBrightcove = (props, localeTranslate) => {
  const { url } = props
  return {
    id: url,
    shareUrl: `https://players.brightcove.net/${BRIGHTCOVE_ACC_ID}/${BRIGHTCOVE_PLAYER_ID}_default/index.html?videoId=${url}`,
    options: formatBrightcoveVideoDownloadOptions(props, localeTranslate)
  }
}

export const processVideoProps = (resource): VideoProps => {
  const {
    title,
    videoProvider: provider,
    video,
    downloadTrackingByResolutionAndTitle,
    fetchDownloadLinkById,
    localeTranslate,
    unmount,
    relatedDocuments,
    resourceClickHandler,
    description
  } = resource

  const generalProps = {
    title,
    provider,
    downloadTrackingByResolutionAndTitle,
    fetchDownloadLinkById,
    localeTranslate,
    unmount,
    relatedDocuments,
    resourceClickHandler,
    description,
    courseLanguage: video?.language
  }

  if (provider === VIMEO) {
    return {
      ...generalProps,
      ...processVideoPropsForVimeo(video, localeTranslate)
    }
  }

  if (provider === BRIGHTCOVE) {
    return {
      ...generalProps,
      ...processVideoPropsForBrightcove(video, localeTranslate)
    }
  }

  if (provider === GOOGLE_DRIVE) {
    return {
      ...generalProps,
      ...processVideoPropsForGoogleDrive(video, localeTranslate)
    }
  }

  return null
}

export const processPropsForVideoPlayer = (resource) => {
  const { videoProvider: provider, url, isPreviewView, autoPlay } = resource

  if (provider === VIMEO) {
    return {
      url,
      provider,
      isPreviewView
    }
  }

  if (provider === BRIGHTCOVE) {
    return {
      id: url,
      provider,
      isPreviewView,
      autoPlay
    }
  }

  if (provider === GOOGLE_DRIVE) {
    return {
      videoUrl: url,
      provider,
      isPreviewView
    }
  }

  return null
}
