import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HttpResponse } from "@angular/common/http";

import { Observable, forkJoin } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { PlaylistService } from "@builder/training/playlists/playlist-service";
import { Errors } from "@builder/common";

import { Playlist } from "../playlists";
import { LessonService } from "../lessons/lesson-service";
import { Lesson } from "../lessons";

/**
 * Playlist Resolver to load playlist paginator before rendering page
 */
@Injectable()
export class DashboardPlaylists
  
{
  constructor(
    private playlistService: PlaylistService,
    private errors: Errors,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<{
    modules: Array<Playlist>;
    totalPages: number;
    totalItems: number;
  }> {
    return this.playlistService
      .list({
        per_page: 6,
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return {
            modules: response.body,
            totalPages: parseInt(response.headers.get("x-wp-totalpages"), 10),
            totalItems: parseInt(response.headers.get("x-wp-total"), 10),
          };
        }),
      );
  }
}

export interface PopularLessonsResponse {
  videos: Array<Lesson>;
  articles: Array<Lesson>;
}

@Injectable()
export class PopularModuleLessonsResolver
  
{
  constructor(
    private lessonService: LessonService,
    private errors: Errors,
  ) {}

  /**
   * Resolve
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    return forkJoin([
      this.lessonService.list({ popular: 1, type: "article", per_page: 3 }),
      this.lessonService.list({ popular: 1, type: "video", per_page: 3 }),
    ]).pipe(
      map((resultSet) => {
        return { videos: resultSet[1].body, articles: resultSet[0].body };
      }),
      catchError((error) => {
        return this.errors.navigateToError(error.error || error);
      }),
    );
  }
}

@Injectable()
export class LessonCategoriesResolver  {
  constructor(
    private lessonService: LessonService,
    private errors: Errors,
  ) {}

  /**
   * Resolve
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Array<any>> {
    return this.lessonService.getLessonCategories().pipe(
      map((cats) =>
        cats.map((cat) => {
          cat.slug = decodeURI(cat.translated_slug || cat.slug);
          return cat;
        }),
      ),
    );
  }
}
