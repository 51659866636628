import { Injectable } from "@angular/core";
import { FeaturesService } from "@builder/common/features/features.service";

/**
 * Tidio Chat integration
 * Add script to page when feature is enabled
 */
@Injectable({
  providedIn: "root",
})
export class Tidio {
  constructor(features: FeaturesService) {
    features
      .whenChanged("tidio-chat-latam")
      .subscribe(status => {
        if ( status.enabled ) {
          this.initialize();
        }
      });
  }

  /**
   * Add the script to the page
   */
  private initialize(): void {
    const scriptId = "mya-tidio-js";
    let s: HTMLScriptElement = document.getElementById(
      scriptId,
    ) as HTMLScriptElement;
    if (s) {
      return;
    }

    s = document.createElement("script");
    s.id = scriptId;
    s.src = "https://code.tidio.co/ibpreyshuiz8wyhxmitduidnujiijles.js";
    s.async = true;
    const e = document.getElementsByTagName("script")[0];
    e.parentNode.insertBefore(s, e);
  }
}
