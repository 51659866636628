import {
  Component,
  Input,
  ViewChild,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { StepperSelectionEvent } from "@angular/cdk/stepper";

import { Resource, isResourcePreviewable } from "@builder/resources/resource";
import { VideoService } from "@builder/common/media/video";
import { ResourceService } from "@builder/resources/resource.service";

@Component({
  selector: "resource-download",
  templateUrl: "./resource-download.component.html",
  styleUrls: ["./resource-download.component.less"],
})
export class ResourceDownloadComponent implements OnChanges {
  @Input() resource: Resource;
  @Input() skipDownloadOption = false;
  @Input() skipPreview = false;
  @Input() isMobile = true;

  // close
  @Output() closing: EventEmitter<any> = new EventEmitter();
  @Output() stepChange: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatStepper) stepper: MatStepper;

  downloadLink: string;

  // the media we extract from resource;
  public media;

  public mediaTitle;

  public showPreviewStep: boolean;

  // the video info we fetch
  public video;

  // Are we loading download options
  public loadingOptions = false;

  // has previous step or npt
  public hasPrevious = false;

  constructor(
    private resourceService: ResourceService,
    private _videosService: VideoService,
    private _resourceService: ResourceService,
  ) {}

  public get stepperLabel(): string {
    if (this.stepper && this.stepper.selected && this.stepper.selected.label) {
      return this.stepper.selected.label;
    }
    return $localize`:alpha.link|Download text@@download:Download`;
  }

  ngOnChanges(changes) {
    if (changes.resource) {
      this.downloadLink = "";
      const newResource: Resource = changes.resource.currentValue;

      // update view count
      // this._resourceService.updateResourceViewCount( newResource.id, newResource.blog_id ).subscribe();
      this.media = newResource;
      this.mediaTitle = newResource.title;

      if (!this.skipPreview) {
        this.showPreviewStep = isResourcePreviewable(newResource);
      } else {
        this.showPreviewStep = false;
      }
      // we don't have any options for document;
      // if the resource is a document, skip download option step, and set download link
      if (newResource.type === "document") {
        this.skipDownloadOption = true;
        if (newResource.attachedDocument) {
          this.downloadLink = newResource.attachedDocument.url;
          this.media = newResource.attachedDocument;
        }
        // otherwise, it is a video, we fetch video data if it is not present
      } else {
        this.skipDownloadOption = false;
        if (newResource.video) {
          this.video = newResource.video;
          this.media = newResource.video;
        } else {
          this.loadingOptions = true;
          this._videosService.getVideo(newResource).subscribe((result) => {
            this.video = result;
            this.media = result;
            this.loadingOptions = false;
          });
        }
      }

      if (this.skipDownloadOption && !this.showPreviewStep) {
        this.download();
      }
    }
  }

  // update hasPrevious
  onSelectionChange(e: StepperSelectionEvent) {
    this.hasPrevious = e.selectedIndex !== 0;
    this.stepChange.emit(e);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 10);
  }

  // go to next step, if we are alreay at the last step, download!
  onNextStep() {
    if (
      !this.loadingOptions &&
      this.stepper.selectedIndex === this.stepper.steps.length - 1
    ) {
      this.download();
    } else {
      this.stepper.next();
    }
  }

  previous() {
    this.stepper.previous();
  }

  download() {
    if (this.downloadLink) {
      if (this.resource.type === "document" && this.downloadLink) {
        this.resourceService.downloadDocument(
          this.downloadLink,
          this.resource.title,
        );

        if (this.resource.id) {
          this._resourceService
            .updateResourceDownloadCount(
              this.resource.id,
              this.resource.blog_id,
            )
            .subscribe();
        }
        this.closing.emit();
      } else if (this.resource.type === "video") {
        if (this.resource.video.provider === "google-drive") {
          window.open(this.downloadLink, "_blank");
        } else {
          window.location.href = this.downloadLink;
        }
        if (this.resource.id) {
          this._resourceService
            .updateResourceDownloadCount(
              this.resource.id,
              this.resource.blog_id,
            )
            .subscribe();
        }
      }
    }
  }
}
