import * as React from 'react'
import { FunctionComponent, useContext } from 'react'
import {
  IoVideocam as VideoIcon,
  IoLink as LinkIcon,
  IoDocument as DocumentIcon
} from 'react-icons/io5'
import { ResourceClickHandlerContext } from '../context'

export interface ResourceCardProps {
  title: string
  description?: string
  image: string
  showNewTag?: boolean
  duration?: string
  type?: 'link' | 'document' | 'video'
  attachedDocument?: any
}

const ResourceCard: FunctionComponent<ResourceCardProps> = ({ ...props }) => {
  const {
    title,
    description,
    image,
    showNewTag,
    duration,
    type,
    attachedDocument
  } = props
  const resourceClickHandler = useContext(ResourceClickHandlerContext)

  const linkResourceClickHandler = (url: string): void => {
    if (url) {
      window.open(url, '_blank', 'noreferrer')
    }
  }

  const handleResourceClick = (resource?) => {
    if (type == 'link') {
      linkResourceClickHandler(resource.linkURL)
    }
    if (type == 'document') {
      resourceClickHandler(resource)
    } else {
      //TO DO: swap videos
      return
    }
  }
  const Icon =
    type === 'link' ? LinkIcon : type === 'document' ? DocumentIcon : VideoIcon
  return (
    <div>
      <div
        className='overflow-hidden rounded-2xl aspect-video bg-cover relative flex items-center justify-center'
        style={{
          backgroundImage: image ? `url(${image})` : '',
          backgroundColor: '#D6D2D0'
        }}
        onClick={() => handleResourceClick({ ...props })}
      >
        {!image ? (
          <div className='bg-alpha-white rounded-full text-alpha-oatmeal-10 text-6xl p-8'>
            <Icon />
          </div>
        ) : null}
        {showNewTag ? (
          <div className='bg-alpha-white uppercase rounded-full absolute top-3 right-3 text-alpha-black py-[2px] px-5 text-sm'>
            {$localize`:common.words|New@@commonWordNew:New`}
          </div>
        ) : null}
        {duration ? (
          <div className='bg-alpha-black uppercase rounded-full absolute bottom-3 right-3 text-alpha-white py-[2px] px-5 text-sm'>
            {duration}
          </div>
        ) : null}
      </div>
      {title ? (
        <p className='font-display font-medium mt-3 mb-[3px] text-base'>
          {title}
        </p>
      ) : null}
      {description ? (
        <p className='font-body text-sm m-0'>{description}</p>
      ) : null}
    </div>
  )
}

export default ResourceCard
