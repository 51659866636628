<div id="searchForm" *ngIf="!isSearchPage">
  <ab-icon id="closeSearch" name="close" (click)="hideSearchForm()"></ab-icon>
  <search-form></search-form>
  <section class="content-wrapper">
    <popular-search-terms
      (selectTerm)="
        router.navigate(['/search'], { queryParams: { q: $event.term } })
      "
    >
      <p
        i18n="
          learning.title|Can't find what you're looking
          for?@@learningOptionsTitle"
      >
        Can't find what you're looking for? Try one of the options below
      </p>
    </popular-search-terms>
  </section>
</div>
