import { Component, ChangeDetectorRef, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UserService } from "@builder/users/service";

@Component({
  selector: "recover-password",
  templateUrl: "recoverPwd.html",
  styleUrls: ["../styles/signon.common.less", "recoverPwd.less"],
})
export class RecoverPasswordComponent implements OnInit {
  public user_login: string;

  public password: string;
  public confirmPassword: string;

  public verificationKey: string;

  public showNameFields = false;
  public loading = false;
  public errorMessage: string;
  public buttonLabel;
  public formClasses: object = {};

  public formMode: RecoverPwdModes;
  public currentResponseType: RecoverPwdResponseTypes;
  public availableResponseTypes: any = RecoverPwdResponseTypes;
  public RecoverPwdModes: any = RecoverPwdModes;

  public form: FormGroup;

  constructor(
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _changeDetector: ChangeDetectorRef,
  ) {
    this.formMode = RecoverPwdModes.Recover;

    this._route.queryParams.subscribe((params) => {
      const key = params["key"];
      const user_login = params["login"];
      if (!key || !user_login) {
        return;
      }
      this.verificationKey = key;
      this.user_login = decodeURIComponent(user_login);

      if (this.verificationKey && this.user_login) {
        this.verifyKey();
      }
    });
  }

  ngOnInit() {
    const validateNames = (c) => {
      if (!this.showNameFields) {
        return null;
      }
      return !c.value ? { required: true } : null;
    };
    const validatePW = (c) => {
      if (this.formMode === RecoverPwdModes.Reset && !c.value) {
        return { required: true };
      }
      return null;
    };

    this.form = new FormGroup({
      user_login: new FormControl(this.user_login, [
        Validators.email,
        Validators.required,
      ]),
      password: new FormControl(null, validatePW),
      firstName: new FormControl("", validateNames),
      lastName: new FormControl("", validateNames),
    });

    this.buttonLabel = "Send Recovery Email";
  }

  onFormSubmit() {
    if (this.formMode === RecoverPwdModes.Recover) {
      this.requestRecovery();
    } else if (this.formMode === RecoverPwdModes.Reset) {
      this.setPassword();
    }
  }

  /**
   * Get the class object for the form modes, to be used in ngClass
   */
  getFormClasses() {
    this.formClasses["mode-recover"] =
      this.formMode === RecoverPwdModes.Recover;
    this.formClasses["mode-reset"] = this.formMode === RecoverPwdModes.Reset;
    return this.formClasses;
  }

  isMainBtnActive() {
    return (
      (this.formMode === RecoverPwdModes.Recover &&
        this.currentResponseType !==
          RecoverPwdResponseTypes.VerificationSent) ||
      (this.formMode === RecoverPwdModes.Reset &&
        this.currentResponseType === RecoverPwdResponseTypes.Verified)
    );
  }

  /**
   * Initiate a password recovery for the current user_login
   */
  requestRecovery() {
    this.errorMessage = "";

    // Send the password reset request to the server
    this.loading = true;

    this._userService
      .initPwdRecovery(this.form.controls.user_login.value)
      .subscribe(
        (result: any) => {
          this.currentResponseType = result.responseType;
          this.formMode = null;
          this.loading = false;
          this._changeDetector.detectChanges();
        },
        (errorResponse) => {
          this.errorMessage = errorResponse.error.message;
          this.loading = false;
          this._changeDetector.detectChanges();
        },
      );
  }

  /**
   * Verify that the specified login/key combo for a password reset is valid
   */
  verifyKey() {
    this.formMode = RecoverPwdModes.Verifying;

    this.loading = true;

    this._userService
      .verifyPwdRecoveryKey(this.verificationKey, this.user_login)
      .subscribe(
        (result: any) => {
          this.currentResponseType = result.responseType;
          if (result.responseType === RecoverPwdResponseTypes.Verified) {
            this.formMode = RecoverPwdModes.Reset;
            this.buttonLabel = "Set Password";
            this.form.controls.user_login.setValue(result.data.email, {
              emitEvent: false,
              onlySelf: true,
            });
            if (!result.data.first_name || !result.data.last_name) {
              this.showNameFields = true;
            }
            this.form.controls.firstName.setValue(result.data.first_name, {
              emitEvent: false,
              onlySelf: true,
            });
            this.form.controls.lastName.setValue(result.data.last_name, {
              emitEvent: false,
              onlySelf: true,
            });
          }
          this.loading = false;
          this.form.controls.password.setValue(null);
          this._changeDetector.detectChanges();
        },
        (errorResponse) => {
          this.currentResponseType = errorResponse.error.data.responseType;
          this.loading = false;

          this._changeDetector.detectChanges();
        },
      );
  }

  /**
   * Choose the password, supplying a valid password reset verification key and login.
   */
  setPassword() {
    const value = this.form.value;

    this.errorMessage = "";

    this.loading = true;

    this._userService
      .chooseRecoveryPassword(
        this.verificationKey,
        value.user_login,
        value.password.newPassword,
        { first_name: value.firstName, last_name: value.lastName },
      )
      .subscribe(
        (result: any) => {
          this.currentResponseType = result.responseType;
          if (result.responseType === RecoverPwdResponseTypes.Completed) {
            this.formMode = RecoverPwdModes.Complete;
          }
          this.errorMessage = "";
          this.loading = false;

          this._changeDetector.detectChanges();
        },
        (error) => {
          this.currentResponseType = error.responseType;
          this.errorMessage = error.message;
          this.loading = false;

          this._changeDetector.detectChanges();
        },
      );
  }
}

enum RecoverPwdModes {
  Recover,
  Verifying,
  Reset,
  Complete,
}

enum RecoverPwdResponseTypes {
  Error,
  VerificationSent,
  VerificationFailed,
  Verified,
  Completed,
}
