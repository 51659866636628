<h6 i18n="email.subjects|New User Subject@@emailSubjectNewUser">
  Welcome to Alpha
</h6>

<ng-container *ngIf="error">
  <div [innerHTML]="error.message"></div>
</ng-container>

<form *ngIf="!error" (submit)="onSubmit($event)" [formGroup]="userForm">
  <ng-container *ngIf="!teamMember.first_name || !teamMember.last_name">
    <p i18n="team|Team Member Welcome Info Prompt@@teamMemberInfoPrompt">
      We only ask for your name to get started.
    </p>
    <p>
      <label
        for="firstName"
        class="field-label"
        i18n="users.label|First Name Label@@labelFirstName"
        >First Name</label
      >
      <input type="text" formControlName="first_name" id="firstName" />
    </p>

    <p>
      <label
        for="lastName"
        class="field-label"
        i18n="users.label|Last Name Label@@labelLastName"
        >Last Name</label
      >
      <input type="text" formControlName="last_name" id="lastName" />
    </p>
  </ng-container>

  <password-editor
    *ngIf="teamMember.user_roles.includes('subscriber')"
    formControlName="password"
  ></password-editor>

  <p>
    <button
      mat-flat-button
      [disabled]="userForm.invalid || saving"
      color="primary"
      i18n="common.labels|Get Started@@commonLabelsGetStarted"
    >
      Get Started
    </button>
  </p>

  <p *ngIf="errorMessage" class="error-msg" [innerHTML]="errorMessage"></p>
</form>
<mat-progress-bar
  *ngIf="saving"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>
