import { Injectable } from "@angular/core";

import { AppTheme } from "@builder/common/util/themeSupports";

import { GeotargetlySettings } from "./geotargetly-settings";

/**
 * GeotargetlyService will attach the client JS library
 */
@Injectable()
export class GeotargetlyService {
  constructor(protected settings: GeotargetlySettings) {
    this.initialize();
  }

  /**
   * Initialize tracker object with our settings
   */
  private initialize(): void {
    this.loadDeps();
  }

  /**
   * Create and attach script element for GeotargetlyService client js
   */
  private loadDeps(): void {
    const styleId = `georedirect${this.settings.id}style`;

    ((g, e, o, t, a, r, ge, tl, y, s) => {
      //g.getElementsByTagName(o)[0].insertAdjacentHTML('afterbegin','<style id="$style_id">body{opacity:0.0 !important;}</style>');
      //s=function(){g.getElementById('$style_id').innerHTML='body{opacity:1.0 !important;}';};
      t = g.getElementsByTagName(o)[0];
      y = g.createElement(e);
      y.async = true;
      y.src =
        `https://g10300385420.co/gr?id=${this.settings.id}&refurl=${g.referrer}&winurl=` +
        encodeURIComponent((window as any).location);
      t.parentNode.insertBefore(y, t);
      //y.onerror=function(){s();};
      //georedirectLoaded = {};
      //georedirectLoaded['$geotargetly_id']=function(redirect){var to=0;if(redirect){to=5000};setTimeout(function(){s();},to)};
    })(document, "script", "head");
  }
}

export const geotargetlySettingsProviderFactory = (appTheme: AppTheme) => {
  const obj = appTheme.value("tracking/geotargetly");
  return new GeotargetlySettings(obj);
};

export let geotargetlySettingsProvider = {
  provide: GeotargetlySettings,
  useFactory: geotargetlySettingsProviderFactory,
  deps: [AppTheme],
};
