import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

/** A Simple label, value option for select boxes */
export interface SimpleOption {
  value: string;
  label: string;
}

/** A locality option */
export interface LocalityOption {
  id: number;
  parent: number;
  name: string;
  description: string;
  slug: string;
}

/**
 * Field Options Service
 */
@Injectable({
  providedIn: "root",
})
export class AlphaFieldOptions {
  private baseUrl = "wp-json/wp/v2";

  constructor(private _http: HttpClient) {}

  localities(): Observable<LocalityOption[]> {
    return this._http.get<LocalityOption[]>(this.baseUrl + "/localities");
  }

  roles(): Observable<SimpleOption[]> {
    return this._http
      .get<SimpleOption[]>(this.baseUrl + "/users/roles")
      .pipe(shareReplay());
  }

  teamRoles(): Observable<SimpleOption[]> {
    return this._http.get<SimpleOption[]>(this.baseUrl + "/team/roles");
  }

  denominations(): Observable<SimpleOption[]> {
    return this._http.get<SimpleOption[]>(this.baseUrl + "/denominations");
  }

  organizationTypes(): Observable<SimpleOption[]> {
    return this._http.get<SimpleOption[]>(this.baseUrl + "/organization-types");
  }
}

/** Resolver for User Roles ( Role at Church ) */
@Injectable()
export class UserRolesResolver  {
  constructor(private fo: AlphaFieldOptions) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<SimpleOption[]> {
    return this.fo.roles();
  }
}

/** Resolver for Team Roles ( Role at Alpha ) */
@Injectable()
export class TeamRolesResolver  {
  constructor(private fo: AlphaFieldOptions) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<SimpleOption[]> {
    return this.fo.teamRoles();
  }
}

/** Resolver for Localities */
@Injectable()
export class LocalitiesResolver  {
  constructor(private fo: AlphaFieldOptions) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LocalityOption[]> {
    return this.fo.localities();
  }
}

/** Resolver for Denominations */
@Injectable()
export class DenominationsResolver  {
  constructor(private fo: AlphaFieldOptions) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<SimpleOption[]> {
    return this.fo.denominations();
  }
}

/** Resolver for Organization Types */
@Injectable()
export class OrganizationTypesResolver  {
  constructor(private fo: AlphaFieldOptions) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<SimpleOption[]> {
    return this.fo.organizationTypes();
  }
}

/**
 * Import this class to use on a route resolver
 * @example
 * 			resolve: {
 * 			    roles: AlphaFieldResolvers.USER_ROLES
 * 			}
 */
export class AlphaFieldResolvers {
  public static USER_ROLES = UserRolesResolver;
  public static TEAM_ROLES = TeamRolesResolver;
  public static LOCALITIES = LocalitiesResolver;
  public static DENOMINATIONS = DenominationsResolver;
  public static ORGANIZATION_TYPES = OrganizationTypesResolver;
}
