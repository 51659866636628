import { Term } from '@builder/common/models/term'

export class Lesson {
  public title: { rendered: string }
  public content: { rendered: string }
  public excerpt: { rendered: string }
  public date: Date
  public image: any
  public id: number
  public blog_id: number
  public link: string
  public slug: string
  public duration: number
  public type: string
  public video_url: any
  public canView = true
  public complete: boolean
  public completed_on: Date
  public link_url: string
  public linkURL: string

  public related: Array<Lesson> = []
  public playlists: Array<number>

  public tags: Array<Term>
  public categories: Array<Term>

  constructor(obj = {}) {
    for (const prop in obj) {
      this[prop] = obj[prop]
    }

    if (this.completed_on) {
      this.completed_on = new Date(this.completed_on)
    }

    if (this.date) {
      this.date = new Date(this.date)
    }

    if (this.link_url) {
      this.linkURL = this.link_url
    }
  }
}
