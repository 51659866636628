import { Component, ChangeDetectorRef, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  ValidationErrors,
  AbstractControl,
} from "@angular/forms";
import { UserService } from "@builder/users/service";
import { CurrentUser } from "@builder/users";
import { AppTheme } from "@builder/common/util/themeSupports";
import { Locale, ILanguageOption } from "../../common/lang/locale";

import { expandDown } from "../../common/animations";

@Component({
  selector: "signup-component",
  templateUrl: "signup.component.html",
  styleUrls: ["../styles/signon.common.less", "signup.component.styles.less"],
  animations: [expandDown("expandDown")],
})
export class SignupComponent implements OnInit {
  public form: FormGroup;

  // current state
  public state: SignupFormState = SignupFormState.Create;
  public SignupFormState: any = SignupFormState;

  public response: { code: string; message: string };

  public loggedIn: boolean;

  public supportsLocationControl: boolean;
  public supportsCity: boolean;
  public supportsSalesForce: boolean;

  public hasTermsAndConditionsLink: boolean;

  public showLanguageMenu: boolean;

  public languages: ILanguageOption[];

  // gdpr signup message
  public gdprMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private appTheme: AppTheme,
    public locale: Locale,
    user: CurrentUser,
  ) {
    this.loggedIn = user.loggedIn();

    if (appTheme.link("terms-and-conditions")) {
      this.hasTermsAndConditionsLink = true;
    }

    this.supportsLocationControl =
      appTheme.supports("locality") || appTheme.supports("countries");
    this.supportsCity = appTheme.supports("city");
    this.supportsSalesForce = appTheme.supports("salesforce");
    this.gdprMessage = this.appTheme.value("gdpr_signup_message");

    this.languages = locale.languages.filter(
      (l) => l.public === true && l.code !== locale.code,
    );
  }

  /**
   *directly redirect to dashboard if user is logged in.
   */
  public ngOnInit(): void {
    const { email } = window.history.state;

    this.form = this.formBuilder.group(
      {
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        email: [email || "", Validators.required],
        mobileNumber: [""],
        role_at_alpha: ["", Validators.required],
        password: ["", Validators.required],
        agreedToTerms: [false, Validators.requiredTrue],
      },
      {
        validators: this.validateForm.bind(this),
      },
    );
  }

  private validateForm(group: FormGroup): ValidationErrors | null {
    const email: string = group.get("email").value;

    const errors: any = {};

    if (!email) {
      errors.emailRequired = true;
    } else if (!email.match(/[^@\s]+@[^@\s]+\.[^@\s]+/)) {
      errors.invalidEmail = true;
    }

    return errors;
  }

  public viewToS(): void {
    const link: { url: string; target: string } = this.appTheme.link(
      "terms-and-conditions",
    );

    if (link.target === "_blank") {
      window.open(link.url);
    } else {
      document.location.href = link.url;
    }
  }

  /**
   * Handle the signon form submission.
   */
  public onFormSubmit(): void {
    this.requestAccount();
  }

  /**
   * Request an account to be created for a user.
   * This won't result in an account immediately, verification will need to take place first.
   */
  private requestAccount(): void {
    this.state = SignupFormState.Submitting;

    const userData = this.form.value;

    if (userData.location) {
      if (userData.location.locality) {
        userData.locality = userData.location.locality.name;
      }
      if (userData.location.country) {
        userData.country = userData.location.country.slug;
      }
      delete userData.location;
    }

    if (userData.role_at_church) {
      userData.role_at_church = userData.role_at_church.value;
    }

    if (userData.role_at_alpha) {
      userData.role_at_alpha = userData.role_at_alpha.value;
    }

    userData.confirmPassword = userData.password.confirmPassword;
    userData.password = userData.password.newPassword;

    this.response = null;

    this.form.disable();

    this.changeDetector.detectChanges();

    this.userService.requestAccount(userData).subscribe(
      (result: any) => {
        this.state = SignupFormState.Complete;
        this.response = result;

        this.changeDetector.detectChanges();
      },
      (errorResponse) => {
        this.response = errorResponse.error;
        this.state = SignupFormState.Error;
        this.form.enable();
        this.changeDetector.detectChanges();
      },
    );
  }

  public get confirmPasswordControl(): AbstractControl {
    return this.form ? this.form.get("confirmPassword") : null;
  }

  public get passwordControl(): AbstractControl {
    return this.form ? this.form.get("password") : null;
  }
}

/**
 * The login form can be in different form modes.
 */
enum SignupFormState {
  Create,
  Submitting,
  Complete,
  Error,
}
