export class MatomoSettings {
  public enableLinkTracking: boolean;
  public trackerJSHost: string;
  public siteId: number;
  public type: "tag_manager" | "analytics";
  public naoIdentifier: string;
  public regionIdentifier: string;

  constructor(params) {
    for (const prop in params) {
      this[prop] = params[prop];
    }
  }
}
