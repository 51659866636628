import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

import { CurrentUser } from "@builder/users";

import { Playlist } from "../playlist-class";

@Component({
  selector: "playlist-meta-bar",
  templateUrl: "playlist-meta-bar.component.html",
  styleUrls: ["playlist-meta-bar.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistMetaBarComponent {
  @Input() playlist: Playlist;

  constructor(public currentUser: CurrentUser) {}
}
