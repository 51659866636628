import { Component } from "@angular/core";

import { HeapService, heapSettingsProvider } from "./heap.service";

/**
 * This class handles subscribing to app events and passing those to the fb pixel service class
 */
@Component({
  selector: "heap-analytics",
  template: "",
  providers: [heapSettingsProvider, HeapService],
})
export class HeapAnalyticsComponent {
  constructor(service: HeapService) {}
}
