import { Component, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router, ResolveStart } from "@angular/router";
import { Subscription } from "rxjs";

import { CurrentUser } from "@builder/users";

@Component({
  selector: "resource-access-denied",
  templateUrl: "access-denied.component.html",
  styles: [
    `
      :host {
        display: block;
        max-width: 420px;
      }
    `,
  ],
})
export class ResourceAccessDeniedComponent implements OnDestroy {
  private subscription: Subscription;

  constructor(
    dialogRef: MatDialogRef<ResourceAccessDeniedComponent>,
    @Inject(MAT_DIALOG_DATA) public error: any,
    public user: CurrentUser,
    router: Router,
  ) {
    this.subscription = router.events.subscribe((e) => {
      if (e instanceof ResolveStart) {
        dialogRef.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
