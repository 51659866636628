import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";

const get_extension = (url: string): string => {
  const ext = url.substring(url.lastIndexOf(".")).toLowerCase();
  return ext;
};

@Component({
  selector: "resource-preview",
  templateUrl: "./resource-preview.component.html",
  styleUrls: ["./resource-preview.component.less"],
})
export class ResourcePreviewComponent implements OnChanges {
  @Input() media;

  @Input() mediaTitle;

  // args for video player
  @Input() videoArgs: { autoPlay: boolean } = { autoPlay: false };

  // next event
  @Output() next: EventEmitter<any> = new EventEmitter();

  public isPDF: boolean;
  public isVideo: boolean;
  public isImage: boolean;
  public iframeSrc: any;
  public extension: string;
  public description: string;

  public ready: boolean;

  // constructor
  constructor(private sanitizer: DomSanitizer) {}

  /**
   *
   * @param changes
   */
  ngOnChanges(changes) {
    if (changes.media) {
      this.parseMedia(changes.media.currentValue);
    }
  }

  /**
   * Handle the media item, determining if pdf, video or image
   * update description
   *
   * @param resource
   */
  private parseMedia(media): void {
    this.extension = get_extension(media.url);

    if (this.extension === ".jpg" || this.extension === ".png") {
      this.isImage = true;
    } else if (this.extension === ".pdf") {
      this.isPDF = true;
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(media.url);
    } else if (this.extension === ".doc" || this.extension === ".docx") {
      // @todo: can we preview doc?
    } else {
      this.isVideo = true;
    }

    if (this.media.type === "video") {
      this.description = $localize`:common.words|Video@@commonWordVideo:Video`;
    } else {
      if (this.media.file) {
        this.description = this.media.file
          .substring(this.media.file.lastIndexOf(".") + 1)
          .toUpperCase();
        this.description += this.media.filesize
          ? ` | ${this.media.filesize}`
          : "";
      }
    }
  }

  onNextClick() {
    this.next.emit();
  }
}
