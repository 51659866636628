import { Component, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AuthHttp } from "@builder/http";

@Component({
  selector: "team-welcome",
  templateUrl: "team-welcome.component.html",
  styles: [
    `
      :host {
        display: block;
        max-width: 480px;
      }
      mat-progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    `,
  ],
})
export class TeamWelcomeComponent {
  public saving: boolean;

  public userForm: FormGroup;

  public teamMember;
  public token: string;

  public error: { code: string; message: string };
  public errorMessage: string;

  constructor(
    public dialogRef: MatDialogRef<TeamWelcomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    fb: FormBuilder,
    private service: AuthHttp,
  ) {
    switch (data.code) {
      case "invalid_invitation":
        this.error = data;
        break;
      case "subscriber_user":
      case "fields_missing":
        this.teamMember = data.data.user;
        this.token = data.data.token;
        break;
    }

    if (this.teamMember) {
      this.userForm = fb.group({
        first_name: new FormControl(
          this.teamMember.first_name,
          Validators.required,
        ),
        last_name: new FormControl(
          this.teamMember.last_name,
          Validators.required,
        ),
        password: new FormControl(
          null,
          data.code === "subscriber_user" ? Validators.required : [],
        ),
      });
    }
  }

  public onSubmit(event): void {
    event.preventDefault();

    const value = this.userForm.value;

    if (value.password) {
      value.user_pass = value.password.newPassword;
      delete value.password;
    }

    this.saving = true;

    const headers = {
      Authorization: "Bearer " + this.token,
    };

    this.service
      .post(
        "wp-json/wp/v2/team/" + this.teamMember.id,
        { profile: value },
        { headers },
      )
      .subscribe(
        (result) => {
          this.saving = false;
          this.dialogRef.close(result);
        },
        (error) => {
          this.saving = false;
          this.errorMessage = error.error.message ?? error.message;
        },
      );
  }
}
