import {
  DateUtils,
  WEEK_IN_MILLISECONDS,
  DAY_IN_MILLISECONDS,
} from "../common/util/date";
import { Organization } from "../organizations/organization";

import { last, concat, each, clone } from "../common/util";
import { PlaceLocation } from "@builder/common/google/places/place-location";
import { Resource } from "@builder/resources/resource";
import { Post } from "@builder/common/models/post";

export interface AlphaPlanInterface {
  training?: Array<Resource>;
  locked?: Array<Resource>;
  standard?: Array<Resource>;
  weekend?: Array<Resource>;
  postweekend?: Array<Resource>;
  available?: Array<Resource>;
}

export class Alpha extends Post {
  public product: any;
  public parent_blog_id: number;

  public original_date: Date;
  public promote: boolean;

  public phone_number: string;
  public email_address: string;

  public noStartDate: boolean;
  public additional_information: string;

  private explicitWeekendDate: Date;

  public canDelete: boolean;
  public resetPlan: boolean;

  public user_role: string;
  public demographic: string;

  public progress: {
    complete: number;
    total: number;
    pct: number;
    next: { title: string };
  };

  public author: {
    id: number;
    display_name: string;
    email: string;
    phone: string;
  };

  public survey_id: number;

  public alpha_now_url: string;
  public questionIds: Array<number>;

  /**
   * Online Delivery
   */
  public get isHostedOnline(): boolean {
    return this.onlineDelivery === "online";
  }
  public onlineDelivery: string;

  /**
   * Organization
   */
  public set organization(val: Organization) {
    this._organization = new Organization(val);
  }
  public get organization(): Organization {
    return this._organization;
  }
  private _organization: Organization;

  /**
   * Planning
   */
  public set planning(planning) {
    for (const section in planning) {
      planning[section] = planning[section].map((item) => new Resource(item));
    }
    this._planning = planning;
  }
  public get planning(): AlphaPlanInterface {
    return this._planning;
  }
  private _planning: AlphaPlanInterface;

  /**
   * End Date
   */
  public set end_date(val) {
    if (val) this._end_date = DateUtils.parse(val);
  }
  public get end_date(): Date {
    return this._end_date;
  }
  private _end_date: Date;

  /**
   * Date Created
   */
  public set date_created(val) {
    if (val) this._date_created = DateUtils.parse(val);
  }
  public get date_created(): Date {
    return this._date_created;
  }
  private _date_created: Date;

  constructor(data: any = {}) {
    super(data);
    if (this.date) {
      this.original_date = new Date(this.date.getTime());
    }
  }

  /**
   * Location stuff
   */

  private _location: PlaceLocation;
  public get location(): PlaceLocation {
    if (!this._location) {
      this._location = new PlaceLocation();
    }
    return this._location;
  }

  /**
   * Place Id
   */
  private _placeId: string;
  public set place_id(val: string) {
    this._placeId = val;
    this.location.place_id = val;
  }
  public get place_id(): string {
    return this._placeId;
  }

  /**
   * Address
   */
  private _address: string;
  public set address(val: string) {
    this._address = val;
    this.location.address = val;
  }
  public get address(): string {
    return this._address;
  }

  /**
   * City
   */
  private _city: string;
  public set city(val: string) {
    this._city = val;
    this.location.city = val;
  }
  public get city(): string {
    return this._city;
  }

  /**
   * Postal Code
   */
  private _postal_code: string;
  public set postal_code(val: string) {
    this._postal_code = val;
    this.location.postal_code = val;
  }
  public get postal_code(): string {
    return this._postal_code;
  }

  /**
   * Locality
   */
  private _locality: any;
  public set locality(val: any) {
    this._locality = val;
    this.location.locality = val;
  }
  public get locality(): any {
    return this._locality;
  }

  /**
   * Country
   */
  private _country: any;
  public set country(val: any) {
    this._country = val;
    this.location.country =
      typeof val === "string" ? { slug: val, name: "" } : val;
  }
  public get country(): any {
    return this._country;
  }

  /**
   * Latitude
   */
  private _lat: any;
  public set lat(val: any) {
    this._lat = val;
    this.location.position.lat = val;
  }
  public get lat(): any {
    return this._lat;
  }

  /**
   * Longitude
   */
  private _lng: any;
  public set lng(val: any) {
    this._lng = val;
    this.location.position.lng = val;
  }
  public get lng(): any {
    return this._lng;
  }

  /**
   * Formatted Address
   */
  private _formatted_address: string;
  public set formatted_address(val: string) {
    this._formatted_address = val;
    this.location.formatted_address = val;
  }
  public get formatted_address(): string {
    return this._formatted_address;
  }

  /**
   * Place Name
   */
  public set place_name(val: string) {
    this.location.place_name = val;
  }
  public get place_name(): string {
    return this.location.place_name
      ? this.location.place_name
      : this.formatted_address;
  }

  /**
   * Venue Label ( the original text from the places result )
   */
  public set venue_label(val: string) {
    this.location.place_text = val;
  }

  public setPlanning(value): void {
    this.planning = value;
    if (!this.noStartDate) {
      this.preparePlanningDates();
    }
  }

  /**
   * Set the time of day on the alpha
   * @param timeStr
   */
  public setTime(date: Date): void {
    this.date.setHours(date.getHours());
    this.date.setMinutes(date.getMinutes());
    this.date.setSeconds(0);
  }

  public changeDate(date: Date, skipPlan: boolean = false): void {
    const dayChange: boolean =
      !this.date || !DateUtils.isSameDate(this.date, date);
    this.date = new Date(date.getTime());

    // only reset the dates if the day has changed, if it's just start time it's ok
    if (!skipPlan) {
      if (this.planning) {
        this.preparePlanningDates(dayChange);
      } else {
        this.resetPlan = true;
      }
    }
  }

  /**
   * Set the date on the alpha, use default time if no prev date
   * @param newDate
   * @param defaultStartTime
   */
  public setDate(newDate: Date, defaultStartTime: string): void {
    const timeStr = this.date
      ? DateUtils.formatTime(this.date)
      : defaultStartTime;
    this.date = new Date(newDate.toDateString() + ", " + timeStr);
  }

  /**
   * Prepare the plan item dates if none have been saved
   * Lots of crazy logic in here.
   * The idea is that we:
   *       reset everything (start date change)
   *       reset dates in 1 section only
   *       reset all dates after a particular item
   */
  preparePlanningDates(
    reset: boolean = false,
    sortEvent?: any,
    resetAfterItemSortableOnly?: boolean,
    inCollectionOnly?: any,
  ) {
    const afterItem = sortEvent ? sortEvent.item : null;
    let startDate: Date = new Date(this.date.getTime());
    const startDateDay: number = startDate.getDay();
    const weekendStart: Date =
      sortEvent && sortEvent.weekendStart ? sortEvent.weekendStart : null;
    let lastItemProcessed: any;
    const changedCollection =
      sortEvent && sortEvent.receivedBy ? sortEvent.receivedBy.items : null;
    const movedCollection = sortEvent && sortEvent.sender !== undefined;
    const firstTrainingDate = new Date(
      startDate.getTime() - 2 * WEEK_IN_MILLISECONDS,
    );
    const lastTrainingItem =
      this.planning.training &&
      this.planning.training[this.planning.training.length - 1]
        ? this.planning.training[this.planning.training.length - 1]
        : null;
    const hasAlphaWeekend =
      this.planning.weekend && this.planning.weekend.length > 0;
    if (reset) {
      this.explicitWeekendDate = null;
    }
    if (weekendStart) {
      this.explicitWeekendDate = weekendStart;
    }
    /**
     * Training Sessions
     */
    if (
      this.planning.training &&
      (!inCollectionOnly || inCollectionOnly === this.planning.training)
    ) {
      // let now = new Date();
      // if(firstTrainingDate.getTime() < now.getTime()){
      // firstTrainingDate = now;
      // }
      const numTraining: number = this.planning.training.length;
      this.planning.training.forEach((item: { date: any }, index: number) => {
        if (afterItem && afterItem === item) {
          reset = true;
          if (item.date) {
            startDate = item.date;
          } else {
            startDate = lastItemProcessed.date;
          }
        }
        if ((item !== lastTrainingItem && !item.date) || reset) {
          item.date = firstTrainingDate;
        } else if (item.date) {
          item.date = new Date(item.date);
        }
        // Don't update lastItemProcess if it is last training item and we don't have a date,
        // otherwise we will run into errors when process standard session:
        // startDate = DateUtils.addWeekToDate( lastItemProcessed.date );
        if (!(item === lastTrainingItem && !item.date)) {
          lastItemProcessed = item;
        }
      });
      if (resetAfterItemSortableOnly) {
        reset = false;
      }
    }

    /**
     * Locked Sessions
     */
    if (
      this.planning.locked &&
      (!inCollectionOnly || inCollectionOnly === this.planning.locked)
    ) {
      this.planning.locked.forEach((item: { date: any }, index: number) => {
        if (afterItem && afterItem === item) {
          reset = true;
          if (item.date) {
            startDate = item.date;
          } else {
            startDate = lastItemProcessed.date;
          }
        }
        if (!item.date || reset) {
          const time: number = startDate.getTime();
          item.date = new Date(time);
          startDate.setTime(time + WEEK_IN_MILLISECONDS);
        } else if (item.date) {
          item.date = new Date(item.date);
        }
        lastItemProcessed = item;
      });
      if (resetAfterItemSortableOnly) {
        reset = false;
      }
    }
    /**
     * Standard Sessions
     */
    if (
      this.planning.standard &&
      (!inCollectionOnly || inCollectionOnly === this.planning.standard)
    ) {
      if (lastItemProcessed) {
        startDate = DateUtils.addWeekToDate(lastItemProcessed.date);
      }
      if (afterItem && resetAfterItemSortableOnly) {
        // startDate = DateUtils.addWeekToDate(afterItem.date);
      }
      this.planning.standard.forEach((item: { date: any }, index: number) => {
        if (
          changedCollection === this.planning.standard &&
          (index === sortEvent.originalIndex || movedCollection)
        ) {
          reset = true;
        }

        if (!item.date || reset) {
          const time: number = startDate.getTime();
          item.date = new Date(time);
          startDate.setTime(time + WEEK_IN_MILLISECONDS);
        } else if (item.date) {
          item.date = new Date(item.date);
          startDate = DateUtils.addWeekToDate(item.date);
        }
        lastItemProcessed = item;
      });
      if (resetAfterItemSortableOnly) {
        reset = false;
      }
    }
    if (
      this.explicitWeekendDate &&
      lastItemProcessed &&
      lastItemProcessed.date > this.explicitWeekendDate
    ) {
      this.explicitWeekendDate = null;
    }
    /**
     * Weekend Sessions
     */
    if (
      this.planning.weekend &&
      (!inCollectionOnly || inCollectionOnly === this.planning.weekend)
    ) {
      if (lastItemProcessed) {
        startDate = new Date(lastItemProcessed.date);
      }
      if (this.explicitWeekendDate) {
        startDate = this.explicitWeekendDate;
        reset = true;
      }
      startDate = DateUtils.getFollowingSaturday(startDate);
      this.planning.weekend.forEach((item: { date: any }, index: number) => {
        if (
          changedCollection === this.planning.weekend &&
          (index === sortEvent.originalIndex || movedCollection)
        ) {
          reset = true;
        }

        if (!item.date || reset) {
          item.date = startDate;
        } else if (item.date) {
          item.date = new Date(item.date);
        }
        if (index === 0 && lastTrainingItem) {
          // only change last training date when creating or reseting
          if (!lastTrainingItem.date || reset) {
            // the last training item has its date 2 days before the alpha weekend
            lastTrainingItem.date = new Date(
              item.date.getTime() - 1 * DAY_IN_MILLISECONDS,
            );
          } else if (lastTrainingItem.date) {
            lastTrainingItem.date = new Date(lastTrainingItem.date);
          }
        }
      });
      if (resetAfterItemSortableOnly) {
        reset = false;
      }
    }
    // if no alpha weekend, last training session date needs to be set
    if (lastTrainingItem && !lastTrainingItem.date && !hasAlphaWeekend) {
      lastTrainingItem.date = firstTrainingDate;
    }
    if (!lastItemProcessed) {
      lastItemProcessed = last(
        concat(this.planning.standard, this.planning.weekend),
      );
    }

    /**
     * Post Weekend Sessions
     */
    if (
      this.planning.postweekend &&
      (!inCollectionOnly || inCollectionOnly === this.planning.postweekend)
    ) {
      if (lastItemProcessed) {
        // start date for post weekend is next day of the week the original start date was on
        startDate = DateUtils.getNextDayFromDate(
          startDateDay,
          lastItemProcessed.date,
        );

        // if start date is the same day of the last item of alpha weekend, pushed it to the next week
        const lastItem =
          last(this.planning.weekend) || this.getDefaultWeekendDate();
        if (DateUtils.isSameDate(lastItem.date, startDate)) {
          startDate = DateUtils.addWeekToDate(startDate);
        }
      }
      if (afterItem && resetAfterItemSortableOnly) {
        // startDate = DateUtils.addWeekToDate(afterItem.date);
      }
      this.planning.postweekend.forEach(
        (item: { date: any }, index: number) => {
          if (
            changedCollection === this.planning.postweekend &&
            (index === sortEvent.originalIndex || movedCollection)
          ) {
            reset = true;
          }

          if (!item.date || reset) {
            const time: number = startDate.getTime();
            item.date = new Date(time);
            startDate.setTime(time + WEEK_IN_MILLISECONDS);
          } else if (item.date) {
            item.date = new Date(item.date);
            startDate = DateUtils.addWeekToDate(item.date);
          }
          lastItemProcessed = item;
        },
      );
      if (resetAfterItemSortableOnly) {
        reset = false;
      }
    }

    /**
     * Removed/Available Sessions
     */
    if (this.planning.available) {
      this.planning.available.forEach((item: { date: any }, index: number) => {
        if (!item.date || reset) {
          const time: number = startDate.getTime();
          item.date = new Date(time);
          startDate.setTime(time + WEEK_IN_MILLISECONDS);
        } else if (item.date) {
          item.date = new Date(item.date);
        }
        lastItemProcessed = item;
      });
      if (resetAfterItemSortableOnly) {
        reset = false;
      }
    }

    this.updateEndDate();
  }

  /**
   * Take an item with a new date, and put it into the right collection, resorting
   */
  public replaceItemByDate(changedItem: any) {
    // get current info
    const itemInfo = this.getPlanningItemIndex(changedItem);
    if (itemInfo.collection === this.planning.weekend) {
      let wstart;
      // item dropped on a saturday, start weekend 1 day before
      if (changedItem.date.getDay() === 5) {
        wstart = DateUtils.subtractDaysFromDate(changedItem.date, 1);
      } else {
        // go to the next weekend
        wstart = DateUtils.getNextWeekendStart(changedItem.date);
        changedItem.date = wstart;
      }
      this.preparePlanningDates(false, { weekendStart: wstart });
      return;
    }
    const insertBefore = this.placeItemByDate(changedItem, itemInfo.collection);
    this.explicitWeekendDate = null;
    this.preparePlanningDates(false, {
      item: changedItem,
      originalIndex: insertBefore + 1,
      receivedBy: { items: itemInfo.collection },
    });
  }

  /**
   * Find the first item that has a date before the requested date
   */
  public placeItemByDate(insertItem: any, collection: any) {
    collection.sort((a, b) => {
      const at = a.date.getTime();
      const bt = b.date.getTime();
      return at === bt ? 0 : bt < at ? 1 : -1;
    });
    const atIndex = collection.indexOf(insertItem);

    return atIndex;
  }

  public getPlanningItemIndex(pItem: any): any {
    let matched = null;
    each(
      [
        this.planning.locked,
        this.planning.standard,
        this.planning.weekend,
        this.planning.postweekend,
      ],
      (collection: any) => {
        collection.forEach((item: any, index: any) => {
          if (pItem === item) {
            matched = { collection, index };
          }
        });
      },
    );
    return matched;
  }

  public updateEndDate(): void {
    if (this.lastScheduledDate) {
      this.end_date = new Date(this.lastScheduledDate.toString());
      this.end_date.setHours(this.date.getHours());
      this.end_date.setMinutes(this.date.getMinutes());
    }
  }

  /**
   * get the first scheduled date
   */
  public get firstScheduledDate(): Date {
    const all = concat(
      this.planning.standard,
      this.planning.weekend,
      this.planning.postweekend,
    );
    const firstDate = all[0];
    return firstDate ? firstDate.date : null;
  }

  /**
   * get the last scheduled date
   */
  public get lastScheduledDate(): Date {
    const all = concat(
      this.planning.standard,
      this.planning.weekend,
      this.planning.postweekend,
    );
    const lastDate = last(all);
    return lastDate ? lastDate.date : null;
  }

  public getDefaultWeekendDate() {
    const lastItemBeforeWeekend = last(
      concat(
        this.planning.training,
        this.planning.locked,
        this.planning.standard,
      ),
    );

    if (lastItemBeforeWeekend) {
      return DateUtils.getNextWeekendStart(lastItemBeforeWeekend.date);
      // return DateUtils.getFollowingSaturday(lastItemBeforeWeekend.date);
    }
    return DateUtils.getFollowingSaturday(DateUtils.addWeekToDate(this.date));
  }

  public getDefaultPostWeekendDate() {
    const lastItem = last(this.planning.weekend);
    const startDateDay: number = this.date.getDay();
    let lastDate: Date;

    if (lastItem) {
      // use default post weekend date
      lastDate = DateUtils.getNextDayFromDate(startDateDay, lastItem.date);
    } else {
      lastDate = DateUtils.getNextDayFromDate(
        startDateDay,
        this.getDefaultWeekendDate(),
      );
    }
    return lastDate;
  }

  public getDefaultStandardScheduleDate() {
    const lastItem = last(concat(this.planning.training, this.planning.locked));
    if (lastItem) {
      return DateUtils.addWeekToDate(lastItem.date);
    }
    return DateUtils.addWeekToDate(this.date);
  }

  public getSessionsByDate(): Resource[] {
    const sessions: Array<Resource> = concat(
      this.planning.training,
      this.planning.locked,
      this.planning.standard,
      this.planning.weekend,
      this.planning.postweekend,
    );
    return sessions.sort((a, b) => {
      const ta = a.date.getTime();
      const tb = b.date.getTime();
      if (ta === tb) {
        return 0;
      }
      return ta < tb ? -1 : 1;
    });
  }

  public get nextSession(): Resource {
    return this.getNextSession();
  }
  public getNextSession(): Resource {
    const sessions: Array<Resource> = this.getSessionsByDate(),
      nextIndex: number = this.getNextSessionIndex();
    return nextIndex !== null ? sessions[nextIndex] : null;
  }

  public getNextSessionIndex(): number {
    const sessions: Array<Resource> = this.getSessionsByDate();
    const len: number = sessions.length;
    const now: Date = new Date();
    let i = 0;

    for (i; i < len; i++) {
      if (sessions[i].date > now) {
        return i;
      }
    }

    return null;
  }

  public getSessionCount(): number {
    return concat(
      this.planning.training,
      this.planning.locked,
      this.planning.standard,
      this.planning.weekend,
      this.planning.postweekend,
    ).length;
  }
}

Array.prototype["move"] = function (old_index, new_index) {
  while (old_index < 0) {
    old_index += this.length;
  }
  while (new_index < 0) {
    new_index += this.length;
  }
  if (new_index >= this.length) {
    let k = new_index - this.length;
    while (k-- + 1) {
      this.push(undefined);
    }
  }
  this.splice(new_index, 0, this.splice(old_index, 1)[0]);
  return this; // for testing purposes
};
