import { Injectable } from "@angular/core";
import { AuthHttp } from "@builder/http";

import { map, tap } from "rxjs/operators";

import { CookieService } from "@builder/common/cookies/cookies.service";
import { CurrentUser } from "@builder/users";
import { Announcement } from "./announcement.class";
import { Observable } from "rxjs";

// expiration of the users cookie in days
const COOKIE_EXPIRATION = 30;

// base name for the cookie
const COOKIE_NAME = "dismissedAnnouncements";

// wp rest endpoint
const ANNOUNCEMENTS_ENDPOINT = "wp-json/wp/v2/announcement";

/**
 * Announcements Service for loading announcements through REST API and handling dismissals
 */
@Injectable({
  providedIn: "root",
})
export class AnnouncementsService {
  private announcements: Announcement[] = [];

  constructor(
    private http: AuthHttp,
    private cookies: CookieService,
    private user: CurrentUser,
  ) {}

  /**
   * Cookie name is unique by user
   */
  public get announcementSessionKey(): string {
    return `${COOKIE_NAME}-${this.user.id}`;
  }

  /**
   * Get the most recent Announcement that hasn't been dismissed
   */
  public get latestAnnouncement(): Announcement {
    const notDismissed = this.announcements.filter(
      (a) => !this.dismissedAnnouncements.includes(a.id),
    );
    return notDismissed[0] ?? null;
  }

  /**
   * Get the dismissed announcements from the cookie
   */
  public get dismissedAnnouncements(): number[] {
    const storage = this.cookies.get(this.announcementSessionKey);
    return storage ? JSON.parse(storage) : [];
  }

  /**
   * Load the Announcements
   */
  public load(): Observable<Announcement[]> {
    return this.http.get(ANNOUNCEMENTS_ENDPOINT).pipe(
      map((results) => results.map((r) => new Announcement(r))),
      tap((results) => (this.announcements = results)),
    );
  }

  /**
   * Dismiss an Announcement by id
   */
  public dismiss(id: number): boolean {
    // find the announcement by it's id
    const ann = this.announcements.find((a) => a.id === id);

    // if there's no announcement found by this id, return false
    if (!ann) {
      return false;
    }

    // get the dismissed announcements out of the cookie
    const dismissed = this.dismissedAnnouncements;

    // if the id to dismiss has already been dismissed, return false
    if (dismissed.includes(id)) {
      return false;
    }

    // push the dismissed id onto the storage array
    dismissed.push(id);

    // cookie params
    const cookieParameters = {
      expires: COOKIE_EXPIRATION, // expiration in days
    };

    // store the array back into the cookie
    this.cookies.set(
      this.announcementSessionKey,
      JSON.stringify(dismissed),
      cookieParameters,
    );

    return true;
  }
}
