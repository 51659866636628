import { NgModule, ModuleWithProviders } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { CachingInterceptor } from "./cache.interceptor";
import { UserCache } from "./cache.service";

let cacheInstance: CachingInterceptor;

export const cachingInterceptorFactory = (uCache: UserCache) => {
  if (!cacheInstance) {
    cacheInstance = new CachingInterceptor(uCache);
  }
  return cacheInstance;
};

@NgModule({
  providers: [
    {
      provide: CachingInterceptor,
      useFactory: cachingInterceptorFactory,
      deps: [UserCache],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: cachingInterceptorFactory,
      multi: true,
      deps: [UserCache],
    },
  ],
})
export class CachingModule {}
