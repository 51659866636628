import * as React from 'react'
import { FunctionComponent } from 'react'

interface TabsProps {
  // provider: VideoProviders
  // id?: string
  // url?: string
  // onPlay?: () => void
  // onProgress?: (data: { secondsElapsed?: number; percentage?: number }) => void
}

const Tabs: FunctionComponent<TabsProps> = ({ ...props }) => {
  return <p>Tabs</p>
}

export default Tabs
