import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { forkJoin } from "rxjs";
import { showError } from "./app.error";

import { AppModule } from "./app.module";
import { environment } from "./environments/environment";
import { preloadTranslations } from "./i18n-loader";

if (environment.production) {
  enableProdMode();
}

/**
 * The only way to reliably load data before DI Services are instantiated is to make an XHR request before bootstrapping the application.
 * We do that here in order to preload any data that's critical to bootstrapping. ( Translations )
 */
forkJoin([preloadTranslations()]).subscribe({
  next: (providers) =>
    platformBrowserDynamic(providers).bootstrapModule(AppModule),
  error: (error) => {
	if ( error.redirect ) {
		document.location = error.redirect;
		return;
	} else if ( error.data?.switch_language ) {
    document.location = document.location + '?lang=' + error.data.switch_language;
  } else if ( ! error.message ) {
		error.message = "An uspecified error occurred.";
	}
	showError(error);
  },
});
