<form (submit)="onFormSubmit($event)" class="search-bar">
  <mat-form-field appearance="outline" hideRequiredMarker="true">
    <ab-icon matPrefix name="search"></ab-icon>
    <input
      matInput
      [(ngModel)]="searchTerm"
      appearance="outline"
      autocomplete="off"
      #searchInput
      id="searchTerm"
      name="searchTerm"
      placeholder="Search for Alpha course materials, videos or documents"
      i18n-placeholder="
        searchBar.text|Search Bar - Placeholder
        Text@@searchBarPlaceholderText:Search for Alpha course materials, videos
        or documents"
      subscriptSizing="false"
    />
    <ab-icon
      *ngIf="searchTerm && searchTerm.length > 0"
      matSuffix
      size="12"
      name="close"
      (click)="clearSearchField()"
    ></ab-icon>
  </mat-form-field>

  <button
    (click)="onFormSubmit($event)"
    mat-flat-button
    color="primary"
    class="mya-button-black"
  >
    <span
      class="search-button-text"
      i18n="common.words|Search@@commonWordSearch"
      >Search</span
    >
    <ab-icon class="search-button-icon" size="20" name="search"></ab-icon>
  </button>
</form>
