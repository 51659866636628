import { NgModule } from "@angular/core";

import { BuilderIconModule } from "@builder/common/icons/ab-icon.module";
import { BuilderMaterialModule } from "@builder/common/ng-material";

import { ClipboardCopyComponent } from "./clipboard-copy.component";

@NgModule({
  imports: [BuilderIconModule, BuilderMaterialModule],
  declarations: [ClipboardCopyComponent],
  exports: [ClipboardCopyComponent],
})
export class ClipboardCopyModule {}
