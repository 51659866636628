import * as React from 'react'
import { FunctionComponent, useContext } from 'react'
import { PiDownloadSimpleBold } from 'react-icons/pi'
import { Button } from '@alpha-international/alpha-ui'
import { VideoPlayerModalContext } from './video-player-modal'

type DownloadButtonProps = {
  download: {
    id: string
    url: string
  }
  rendition: string
}

export const VideoPlayerModalDownloadButton: FunctionComponent<
  DownloadButtonProps
> = (props: DownloadButtonProps) => {
  const { download, rendition } = props

  const {
    title,
    downloadTrackingByResolutionAndTitle,
    provider,
    fetchDownloadLinkById
  } = useContext(VideoPlayerModalContext)

  const downloadFile = (url) => {
    downloadTrackingByResolutionAndTitle(rendition, title)
    window.open(url, '_blank')
  }

  const handleDownload = async () => {
    if (download.url) {
      return downloadFile(download.url)
    }

    const observable = fetchDownloadLinkById(download.id)
    observable.subscribe((videoRenditions) => {
      const updatedUrl = videoRenditions.find(
        (videoRendition) => videoRendition.rendition === rendition
      )
      if (updatedUrl) {
        downloadFile(updatedUrl.link)
      } else {
        // TO DO
        console.log('file not found')
      }
    })
  }

  return (
    <Button
      onClick={handleDownload}
      className='w-full flex gap-1 justify-center h-fit text-lg items-center'
    >
      <PiDownloadSimpleBold size={'1.5rem'} color='white' />
      {$localize`:common.words|Common Words - Download@@commonWordsDownload:Download`}
    </Button>
  )
}

export default VideoPlayerModalDownloadButton
