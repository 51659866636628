<div [formGroup]="form">
  <input type="text" formControlName="username" style="display: none" />

  <div *ngIf="user" class="form-row">
    <label for="oldPassword" class="field-label">Enter your old password</label>
    <input
      type="password"
      id="oldPassword"
      autocomplete="current-password"
      formControlName="oldPassword"
    />
    <ng-container *ngIf="oldPassword.touched && oldPassword.invalid">
      <p class="error-msg" *ngIf="!!oldPassword.errors.required">
        Old password is required.
      </p>
    </ng-container>
  </div>

  <div class="form-row">
    <label for="newPassword" class="field-label">Password</label>
    <input
      type="password"
      id="newPassword"
      autocomplete="new-password"
      formControlName="newPassword"
    />

    <div
      *ngIf="$any(form.errors)?.passwordMinRules && newPassword.touched"
      class="error-message"
    >
      <span> Your password needs to: </span>
      <div
        [ngClass]="
          $any(form.errors)?.passwordMinLength && newPassword.touched
            ? 'error-message'
            : 'error-message-green'
        "
      >
        <span> be at least {{ minPasswordLength }} characters long </span>
      </div>
      <div
        [ngClass]="
          $any(form.errors)?.passwordMinRulesUpperLowercase &&
          newPassword.touched
            ? 'error-message'
            : 'error-message-green'
        "
      >
        <span> include both uppercase and lowercase characters </span>
      </div>
      <div
        [ngClass]="
          $any(form.errors)?.passwordMinRulesNumbers && newPassword.touched
            ? 'error-message'
            : 'error-message-green'
        "
      >
        <span> include at least one number </span>
      </div>
      <div
        [ngClass]="
          $any(form.errors)?.passwordMinRulesSymbols && newPassword.touched
            ? 'error-message'
            : 'error-message-green'
        "
      >
        <span>
          include at least one symbol
          <!-- of !"#$%&'()*+,-./:;=<?>@[\]^_`{{ '{' }}|}~ -->
        </span>
      </div>
    </div>
  </div>

  <div class="form-row">
    <label for="confirmPassword" class="field-label">Confirm Password</label>
    <input
      type="password"
      id="confirmPassword"
      autocomplete="new-password"
      formControlName="confirmPassword"
    />
    <ng-container *ngIf="confirmPassword.touched && confirmPassword.dirty">
      <p
        class="error-message"
        *ngIf="form.invalid && !!form.errors?.passwordMismatch"
      >
        Passwords must match.
      </p>
    </ng-container>
  </div>
</div>
