import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "search-filters-dialog",
  templateUrl: "./search-filters-dialog.component.html",
  styleUrls: ["./search-filters-dialog.component.less"],
})
export class SearchFiltersDialogComponent {
  description: string;
  selectedFilters: any;

  constructor(
    private dialogRef: MatDialogRef<SearchFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.description = data.description;
    this.selectedFilters = data.filters;
  }

  save() {
    this.dialogRef.close(this.selectedFilters);
  }

  close() {
    this.dialogRef.close();
  }

  handleValueChange(newValue: any) {
    this.selectedFilters = newValue;
  }
}
