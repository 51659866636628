import { NgModule } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { MatChipsModule } from "@angular/material/chips";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatLegacyTabsModule } from "@angular/material/legacy-tabs";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatDialogModule } from "@angular/material/dialog";
import { MatStepperModule } from "@angular/material/stepper";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";

const MATERIALS = [
  MatMenuModule,
  MatChipsModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  MatCheckboxModule,
  MatLegacyTabsModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatTableModule,
  MatSortModule,
  MatDialogModule,
  MatStepperModule,
  MatInputModule,
  MatSelectModule,
  MatRadioModule,
];

@NgModule({
  imports: MATERIALS,
  exports: MATERIALS,
})
export class BuilderMaterialModule {}
