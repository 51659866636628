import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Route, RouterModule } from "@angular/router";

import { BuilderMaterialModule } from "@builder/common/ng-material";
import { AlphaLocaleModule } from "@builder/common/lang/locale.module";
import { BuilderIconModule } from "@builder/common/icons/ab-icon.module";
import { CommonPipesModule } from "@builder/common/pipes/common-pipes.module";
import { UserService } from "@builder/users/service";

import { PlaylistDetailComponent } from "./playlist-detail.component";
import { PlaylistDetailResolver } from "./playlist-detail.resolver";
import { PlaylistMetaBarModule } from "../meta-bar/playlist-meta-bar.module";
import { RelatedPlaylistsModule } from "../related/related-playlists.module";
import { RelatedTextModule } from "@builder/common/text";
import { PlaylistDetailTitleResolver } from "@builder/training/playlists/detail/playlist-detail.title.resolver";

export const PLAYLIST_DETAIL_ROUTE: Route = {
  path: ":id",
  title: PlaylistDetailTitleResolver,
  component: PlaylistDetailComponent,
  canActivate: [UserService],
  resolve: {
    playlist: PlaylistDetailResolver,
  },
  runGuardsAndResolvers: "always",
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BuilderMaterialModule,
    AlphaLocaleModule,
    BuilderIconModule,
    CommonPipesModule,
    PlaylistMetaBarModule,
    RelatedPlaylistsModule,
    RelatedTextModule,
  ],
  declarations: [PlaylistDetailComponent],
  exports: [PlaylistDetailComponent],
})
export class PlaylistDetailModule {}
