import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { UserService } from "@builder/users/service";

/**
 * SSOHTTPInterceptor is provided for HTTP_INTERCEPTORS which allows it to intercept every Http request.
 * If there is an error and the code is 'sso_bad_request' we'll log the user out to clear out any storage/cache issues
 * They'll be redirected back to IDP login
 */
@Injectable()
export class SSOHTTPInterceptor implements HttpInterceptor {
  private doneOnce: boolean = false;

  private logoutErrorCodes = ["sso_bad_request", "jwt_auth_invalid_token"];

  constructor(private userService: UserService) {}

  /**
   * intercept method responsible for catching the error
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse, caught: Observable<any>) => {
        if (
          !this.doneOnce &&
          err.error?.code &&
          this.logoutErrorCodes.includes(err.error.code)
        ) {
          this.doneOnce = true;
          return this.userService.logout();
        }
        throw err;
      }),
    );
  }
}

/**
 * Export for app module
 */
export const ssoErrorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: SSOHTTPInterceptor,
  deps: [UserService],
  multi: true,
};
