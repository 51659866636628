import * as React from 'react'
import { FunctionComponent } from 'react'

export interface ProductCardDetailProps {
  text: string
  icon: FunctionComponent
}

const ProductCardDetail: FunctionComponent<ProductCardDetailProps> = ({
  text,
  icon
}) => {
  const Icon = icon
  return (
    <div className='flex gap-2 text-alpha-oatmeal-70'>
      <Icon />
      <span className='font-body text-base m-0 text-alpha-black'>{text}</span>
    </div>
  )
}

export default ProductCardDetail
