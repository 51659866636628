import { NgModule, APP_INITIALIZER } from "@angular/core";

import { Locale } from "./locale";
import { PublicLanguagesPipe } from "./public-languages.pipe";
import { LanguageInfoPipe } from "./language-info-pipe";
import { LanguageLinkDirective } from "./language-link.directive";

import { initLocaleForApp } from "./locale-initializer";
import { LanguageOptionLabelPipe } from "./language-option-label.pipe";
import { ssoLanguageInterceptor } from "./signon-interceptor";

@NgModule({
  imports: [],
  declarations: [
    PublicLanguagesPipe,
    LanguageLinkDirective,
    LanguageInfoPipe,
    LanguageOptionLabelPipe,
  ],
  exports: [
    PublicLanguagesPipe,
    LanguageLinkDirective,
    LanguageInfoPipe,
    LanguageOptionLabelPipe,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initLocaleForApp,
      multi: true,
      deps: [Locale],
    },
    ssoLanguageInterceptor,
    LanguageInfoPipe,
  ],
})
export class AlphaLocaleModule {}

export { Locale };
