import * as React from 'react'
import { FunctionComponent } from 'react'
import Carousel from '../carousel'
import ResourceCard from '../resource-card'
import { ResourceCardProps } from '../resource-card/resource-card'
interface ResourceListProps {
  title?: string
  resources: ResourceCardProps[]
  isFeatured?: boolean
}

const ResourceList: FunctionComponent<ResourceListProps> = (props) => {
  const { title, resources, isFeatured = false } = props

  const responsive = {
    '3xl': {
      breakpoint: { max: 6000, min: 5000 },
      items: isFeatured ? 5 : 6,
      partialVisibilityGutter: isFeatured ? 100 : 0
    },
    '2xl': {
      breakpoint: { max: 1920, min: 1600 },
      items: isFeatured ? 4 : 5,
      partialVisibilityGutter: isFeatured ? 70 : 0
    },
    xl: {
      breakpoint: { max: 1600, min: 1440 },
      items: isFeatured ? 3 : 4,
      partialVisibilityGutter: isFeatured ? 60 : 0
    },
    lg: {
      breakpoint: { max: 1440, min: 1024 },
      items: isFeatured ? 2 : 3,
      partialVisibilityGutter: isFeatured ? 50 : 0
    },
    md: {
      breakpoint: { max: 1024, min: 464 },
      items: isFeatured ? 1 : 2,
      partialVisibilityGutter: isFeatured ? 80 : 0
    },
    sm: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0
    }
  }

  return (
    <div className='relative'>
      {title ? <h4 className='mt-12 sm:mt-0 xl:text-lg text-md'>{title}</h4> : null}
      {resources?.length > 0 ? (
        <Carousel
          arrows={false}
          infinite={false}
          renderButtonGroupOutside={true}
          responsive={responsive}
          partialVisible={isFeatured}
          slides={resources}
          slideComponent={ResourceCard}
        />
      ) : null}
    </div>
  )
}

export default ResourceList
