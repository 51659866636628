import { AfterViewInit, Component, ViewChild } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { UserPreferences } from '@builder/users/preferences'
import { WhatsNewDialogComponent } from './whats-new-modal/whats-new-modal.component'
import { ProductTourService } from '@builder/product-tour/product-tour.service'
import { WHATS_NEW_OPENED_PREVIOUSLY } from '@builder/product-tour/product-tour.constants'
import { PageLoadingService } from '@builder/common/page-loading/page-loading.service'

@Component({
  selector: 'whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.less']
})
export class WhatsNewComponent implements AfterViewInit {
  public newItemsCount: number = 1
  public dialogRef: MatDialogRef<WhatsNewDialogComponent, any>
  public whatsNewTourOpened: boolean = false
  public productTourOpened: boolean = false

  constructor(
    public userPreferences: UserPreferences,
    private whatsNewDialog: MatDialog,
    private tour: ProductTourService,
    private pageLoadingService: PageLoadingService
  ) {}

  ngAfterViewInit() {
    this.pageLoadingService.isLoading().subscribe((v) => {
      if (
        !v &&
        !Boolean(this.userPreferences.get(WHATS_NEW_OPENED_PREVIOUSLY))
      ) {
        this.tour.initializeWhatsNewHighlight()
      }
    })
  }

  public openWhatsNewModal() {
    this.dialogRef = this.whatsNewDialog.open(WhatsNewDialogComponent, {
      panelClass: 'whats-new-dialog',
      maxHeight: '100vh',
      minWidth: 382,
      disableClose: true
    })
  }
}
