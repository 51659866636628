<ab-icon
  id="logo"
  name="logo"
  color="red"
  size="32"
  [routerLink]="['/']"
></ab-icon>

<nav>
  <div
    routerLinkActive="menu-item-current"
    *ngIf="currentUser.loggedIn()"
    [routerLink]="['/dashboard']"
    class="menu-item"
  >
    <ab-icon name="dashboard"></ab-icon>
    <a i18n="common.words|Dashboard@@commonWordDashboard">Dashboard</a>
  </div>
  <div
    routerLinkActive="menu-item-current"
    *ngIf="currentUser.loggedIn()"
    [routerLink]="['/alphas']"
    class="menu-item"
  >
    <ab-icon name="computer"></ab-icon>
    <a i18n="alpha.mine|My Alphas@@alphaMyAlphas">My Alphas</a>
  </div>

  <div
    routerLinkActive="menu-item-current"
    [routerLink]="['/preview-resources']"
    class="menu-item"
    [attr.data-driver]="'overview1'"
  >
    <ab-icon name="tvstand"></ab-icon>
    <a i18n="common.resources|Preview Resources Label@@previewResourcesLabel"
      >Preview Resources</a
    >
  </div>

  <div
    routerLinkActive="menu-item-current"
    *ngIf="!theme.supports('disable_training') && locale.trainingCenter.enable"
    [routerLink]="['/training']"
    class="menu-item"
    [attr.data-driver]="'overview3'"
  >
    <ab-icon name="book"></ab-icon>
    <a i18n="training|Learning Centre Title@@learningCenterTitle"
      >Learning Centre</a
    >
  </div>

  <div
    routerLinkActive="menu-item-current"
    *ngFor="let page of additionalMenuItems"
    [routerLink]="['/' + page.slug]"
    class="menu-item"
  >
    <ab-icon [name]="page.icon"></ab-icon>
    <a [innerHTML]="page.title"></a>
  </div>

  <div
    [class.menu-item-current]="isSearchPage"
    (click)="toggleSearch()"
    class="menu-item"
    [attr.data-driver]="'overview4'"
  >
    <ab-icon name="search"></ab-icon>
    <a i18n="common.words|Search@@commonWordSearch">Search</a>
  </div>
</nav>

<div id="navActions" [class.open]="menuVisible">
  <ng-container *ngIf="currentUser.loggedIn() && !coraEnabled">
    <a
      mat-flat-button
      *ngIf="!simpleCreateCourseIsEnabled"
      id="navCreateAlphaButton"
      color="primary"
      [routerLink]="['/alphas/new/step/1']"
      [state]="{ ack: true }"
      i18n="common.buttons|Create an Alpha@@buttonCreateAlpha"
      track-event="create-alpha-click"
      [attr.data-driver]="'overview8'"
    >
      Create an Alpha</a
    >

    <a
      mat-flat-button
      *ngIf="simpleCreateCourseIsEnabled"
      id="navCreateAlphaButton"
      color="primary"
      [routerLink]="['/preview-resources']"
      [state]="{ ack: true }"
      i18n="common.buttons|Select a course button@@selectCourseButton"
      track-event="create-alpha-click"
      [attr.data-driver]="'overview8'"
    >
      Select a course</a
    >
  </ng-container>

  <a
    mat-flat-button
    *ngIf="!currentUser.loggedIn()"
    id="navLoginButton"
    color="primary"
    [routerLink]="['/user', 'signon']"
    i18n="common.labels|Login@@commonLabelsLogin"
    >Login</a
  >

  <div
    *ngIf="currentUser.loggedIn()"
    class="button-container grey"
    id="profileMenuItem"
  >
    <ab-icon
      [routerLink]="['/user/profile']"
      class="link"
      name="profile"
      [attr.data-driver]="'overview5'"
    >
      <span>{{ currentUser.first_name || currentUser.email }}</span>
    </ab-icon>

    <a
      id="logoutLink"
      class="link"
      *ngIf="currentUser.loggedIn()"
      i18n="common.labels|Logout@@commonLabelsLogout"
      (click)="doLogout()"
      >Logout</a
    >
  </div>

  <div id="navActionsFooter">
    <div id="menuLanguages">
      <div
        (click)="showLanguageMenu = !showLanguageMenu"
        class="link"
        id="menuLanguagesText"
        [attr.data-driver]="'overview6'"
      >
        {{ locale.code | languageInfo }}
        <ab-icon
          [class.toggle]="showLanguageMenu"
          name="chevron-down"
        ></ab-icon>
      </div>
      <div id="menuLanguagesOptions" @expandDown *ngIf="showLanguageMenu">
        <div
          class="menu-language-option link"
          *ngFor="let language of languages"
          [language-link]="language.code"
          [title]="language.code"
        >
          {{ language.native_name }}
        </div>
      </div>
    </div>

    <a
      *ngIf="theme.value('support_site_url')"
      [href]="theme.value('support_site_url')"
      class="link"
      target="_blank"
      i18n="common.labels|Header Support Label@@commonLabelsSupport"
      [attr.data-driver]="'overview7'"
    >
      Support</a
    >
  </div>
</div>
