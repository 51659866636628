<div id="contentHeader">
  <div class="content-wrapper has-back-link">
    <ab-icon class="back-link" name="arrow-left" (click)="goBack()"></ab-icon>

    <h1 id="pageTitle" [innerHTML]="playlist.title"></h1>
  </div>
</div>

<section id="playlistDetails" class="content-wrapper">
  <div id="playlistDetailsInfo">
    <div
      id="playlistDetailsContent"
      [innerHTML]="playlist.content.rendered"
    ></div>

    <playlist-meta-bar [playlist]="playlist"></playlist-meta-bar>

    <div id="playlistDetailsButton" *ngIf="playlist.lessonCount > 0">
      <a
        *ngIf="playlist.lessonsComplete === 0"
        [routerLink]="[playlist.firstIncompleteLesson.slug]"
        mat-flat-button
        color="primary"
        i18n="learning.link|Begin Module Button Text@@beginModuleLink"
        >Begin Module</a
      >

      <a
        *ngIf="playlist.firstIncompleteLesson && playlist.lessonsComplete > 0"
        [routerLink]="[playlist.firstIncompleteLesson.slug]"
        mat-flat-button
        color="primary"
        i18n="learning.link|Continue Module Button Text@@continueModuleLink"
        >Continue Module</a
      >

      <a
        *ngIf="playlist.complete"
        (click)="resetModule()"
        mat-flat-button
        color="primary"
        i18n="learning.link|Redo Module Button Text@@redoModuleLink"
        >Redo Module</a
      >
    </div>
  </div>

  <div id="playlistDetailsImage" *ngIf="playlist.image">
    <img [src]="playlist.image.sizes.lesson_feature.url" alt="" />
  </div>
</section>

<section
  id="playlistSteps"
  *ngIf="playlist.lessonCount > 0"
  class="content-wrapper"
>
  <div id="playlistStepsHeader">
    <h6 i18n="learning.words|Learning Words - Steps@@learningWordsSteps">
      Steps
    </h6>
    <div id="playlistStepsHeaderCompletion">
      {{ playlist.lessonsComplete }} / {{ playlist.lessonCount }}
      <span i18n="common.words|Common Words - Completed@@commonWordsCompleted"
        >Completed</span
      >
    </div>
  </div>

  <div id="playlistStepsContainer">
    <div *ngFor="let lesson of playlist.lessons" class="playlist-step lesson">
      <ab-icon
        class="lesson-complete-icon"
        name="check"
        *ngIf="lesson.complete"
      ></ab-icon>

      <a
        class="lesson-title"
        [routerLink]="[lesson.slug]"
        [innerHTML]="lesson.title"
      ></a>

      <div class="lesson-meta">
        <div class="lesson-type">
          <span
            i18n="common.words|Article@@commonWordArticle"
            *ngIf="lesson.type === 'article'"
            >Article</span
          >

          <span
            i18n="common.words|Link@@commonWordLink"
            *ngIf="lesson.type === 'link'"
            >Link</span
          >

          <span
            i18n="common.words|Video@@commonWordVideo"
            *ngIf="lesson.type === 'video'"
            >Video</span
          >
        </div>

        <div class="lesson-duration">
          {{ lesson.duration | playTime }}
        </div>
      </div>
    </div>
  </div>
</section>

<section id="relatedModules" *ngIf="!userSurvey">
  <div
    class="content-wrapper"
    [@openClose]="relatedPlaylists.hasItems ? 'open' : 'closed'"
  >
    <h6>
      <related
        ><span i18n="common.words|Common Words - Modules@@commonWordModules"
          >Modules</span
        ></related
      >
    </h6>

    <related-playlists
      #relatedPlaylists
      class="column-boxes"
      [playlist]="playlist"
    ></related-playlists>
  </div>
</section>
