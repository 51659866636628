<ng-container *ngIf="terms$ | async as terms">
  <div class="popular-search-terms">
    <div class="text-group">
      <h4
        class="mya-h4"
        i18n="searchResult.title|list of common terms title@@popularTermsTitle"
      >
        Not sure where to start?
      </h4>
      <p
        class="mya-p"
        i18n="searchResult.msg|list of common terms msg@@popularTermsMsg"
      >
        Here’s a list of common searches.
      </p>
    </div>
    <div class="search-terms">
      <ng-container *ngIf="(terms | excludeTerms: exclude).length">
        <ng-content></ng-content>
      </ng-container>
      <div class="terms">
        <a
          class="active"
          *ngFor="let term of terms | excludeTerms: exclude"
          (click)="selectTerm.emit(term)"
          >{{ term.term }}</a
        >
      </div>
    </div>
  </div>
</ng-container>
