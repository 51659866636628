export class IntercomSettings {
  public intercom_id: string;
  public salesforce_url: string;

  constructor(params) {
    for (const prop in params) {
      this[prop] = params[prop];
    }
  }
}
