import { HttpClient } from "@angular/common/http";
import { APP_INITIALIZER, Injectable } from "@angular/core";

import { map, tap } from "rxjs/operators";

import { AppError } from "@builder/common/errors";

// wp rest endpoint
const SETTINGS_ENDPOINT = "wp-json/wp/v2/app-config";
const VERSION_ENDPOINT = "/version.php";
/**
 * ThemeData can be data passed in from the WP env, defined in the theme.config.json
 */
export class ThemeData {
  public errors: Array<AppError>;

  // an object containing features and whether they are enabled or not
  public supports: any;

  // an object containing localized values
  public values: any;

  // an object containing links
  public links: any;

  // the application hash
  public hash: string;

  constructor(data: any) {
    this.supports = {};
    this.values = {};
    this.links = {};

    for (const prop in data) {
      this[prop] = data[prop];
    }
  }
}

/**
 * AppTheme can be injected into classes to provide access to ThemeData
 */
@Injectable({
  providedIn: "root",
})
export class AppTheme {
  public settings: ThemeData;
  public version;

  /**
   * Load the Settings
   */
  public load(): Promise<ThemeData> {
    return new Promise((resolve, reject) => {
      this.http
        .get(SETTINGS_ENDPOINT)
        .pipe(
          map((results) => new ThemeData(results)),
          tap((themeData) => {
            this.settings = themeData;
          }),
        )
        .subscribe((result) => {
          resolve(result);
          //this.fetchVersion(); // Call fetchVersion once settings are loaded
        });
    });
  }

  public get errors(): Array<AppError> {
    return this.settings && this.settings.errors;
  }

  constructor(private http: HttpClient) {}

  /**
   * Check whether the Theme supports a feature defined in theme.config.json
   */
  public supports(feature: string): boolean {
    if (this.settings.supports.hasOwnProperty(feature)) {
      if (this.settings.supports[feature] === false) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * Set the support flag for a feature
   */
  public setSupport(feature: string, supported: boolean): void {
    this.settings.supports[feature] = supported;
  }

  /**
   * Return a value defined by a key in the theme.config.json
   */
  public value(key: string, defaultValue: any = null): any {
    const paths: Array<string> = key.split("/");
    const settings = this.settings.values;
    let value = this.settings.values;
    let i = 0;
    const len: number = paths.length;

    for (i; i < len; i++) {
      const path = paths[i];
      if (typeof value[path] !== "undefined") {
        value = value[path];
      } else {
        return defaultValue;
      }
    }
    return value === this.settings ? defaultValue : value;
  }

  /**
   *
   * @param key
   */
  public link(key: string): { url: string; target: string } {
    return this.settings.links[key] ? this.settings.links[key] : null;
  }

  public tracks(key: string): boolean {
    return this.settings.values.tracking && this.settings.values.tracking[key];
  }

  public fetchVersion() {
    this.http.get(VERSION_ENDPOINT).subscribe((result: any) => {
      this.version = result;
    });
  }
}

export const themeSupportsProvider = {
  provide: APP_INITIALIZER,
  deps: [AppTheme],
  useFactory: (service: AppTheme) => () => service.load(),
  multi: true,
};
