import * as React from 'react'
import { FunctionComponent, useEffect, useRef } from 'react'
import {
  BRIGHTCOVE_ACC_ID,
  BRIGHTCOVE_PLAYER_ID,
  BRIGHTCOVE_PLAYER_ID_ALT
} from '../constants'
import { browserSubtitleLanguageMap } from '../browserLanguageCodes'

interface ComponentProps {
  id: string;
  isPreviewView?: boolean;
  autoPlay?: boolean;
}

const BrightCoveVideoPlayer: FunctionComponent<ComponentProps> = ({
  id,
  isPreviewView = false,
  autoPlay = false
}) => {
  const playerRef = useRef(null) // Ref for the video.js player
  const videoContainerRef = useRef<HTMLDivElement>(null)
  const formatLanguageCode = (code: string) =>
    code.toLowerCase().replace('_', '-')

  const playerId = isPreviewView
    ? BRIGHTCOVE_PLAYER_ID_ALT
    : BRIGHTCOVE_PLAYER_ID

  useEffect(() => {
    if (!videoContainerRef.current) return null; // Exit if ref is not available
    // Check for an existing player and pause it if it's playing
    const existingPlayer = (window as any).videojs?.getPlayer('myPlayerID');
    if (existingPlayer && !existingPlayer.paused()) {
      existingPlayer.pause();
    }
    // Create and append the video-js tag
    const videoJsTag = document.createElement('video-js');
    videoJsTag.id = 'myPlayerID';
    videoJsTag.setAttribute('data-account', BRIGHTCOVE_ACC_ID);
    videoJsTag.setAttribute('data-player', playerId);
    videoJsTag.setAttribute('data-embed', 'default');
    videoJsTag.setAttribute('controls', '');
    videoJsTag.setAttribute('data-video-id', id);
    videoJsTag.setAttribute('width', '960');
    videoJsTag.setAttribute('height', '540');
    if (autoPlay) {
      videoJsTag.setAttribute('autoplay', "true");
    }
    videoJsTag.className = 'vjs-fill';

    videoContainerRef.current.appendChild(videoJsTag);

    // Load the Brightcove script
    const script = document.createElement('script');
    script.src = `https://players.brightcove.net/${BRIGHTCOVE_ACC_ID}/${playerId}_default/index.min.js`;
    script.async = true;

    // After the script loads, initialize the player
    script.onload = () => {
      const videoElement = document.getElementById('myPlayerID');
      if (!videoElement) {
        console.error('Video element not found!');
        return;
      }
      if (playerRef.current) {
        console.warn('Player already initialized.');
        return; // Skip re-initialization if player already exists
      }

      const player = (window as any).videojs(videoElement, {}, () => {
       
        player.on('loadeddata', () => {
          // Get the browser language
          const browserLanguage = formatLanguageCode(navigator.language);
          // Get the captions
          const tracks = player.textTracks();

          // Loop through captions and set the default language
          let languageMatch = false;

          // First pass: try to find an exact match
          for (let i = 0; i < tracks.length; i++) {
            const trackLanguage = tracks[i].language;
            const languageArray = browserSubtitleLanguageMap[trackLanguage as string];

          // Check if the track matches the browser language (exact or through array)
            if (trackLanguage === browserLanguage || (Array.isArray(languageArray)  && languageArray.indexOf(browserLanguage) !== -1)) {
              tracks[i].mode = 'showing'; // Exact match found
              languageMatch = true;
              break; // Exit loop after finding a match
            } else {
              tracks[i].mode = 'disabled'; // Disable the track if no match
            }
          }

          // Second pass: only look for prefix matches if no exact match was found
          if (!languageMatch) {
            for (let i = 0; i < tracks.length; i++) {
              const trackLanguage = tracks[i].language;

              // Only check for prefix match if no exact match was found and if the track is still 'disabled'
              if (browserLanguage.substring(0, 2) === trackLanguage.substring(0, 2) && tracks[i].mode !== 'showing') {
                tracks[i].mode = 'showing'; // Set mode to 'showing' for prefix match
                languageMatch = true; // Once a match is found, stop looking
                break; // Exit loop after finding the match
              }
            }
          }

        });
        player.on('error', () => {
          console.error('Video playback error:', player.error());
        });
        player.on('loadedmetadata', function() {
          if(!player.playing && autoPlay)
          { // Autoplay the video
            player.play();
          }
      });
      });

      playerRef.current = player; // Store the player reference
    };

    script.onerror = () => {
      console.error('Failed to load Brightcove script');
      if (videoContainerRef.current) {
        videoContainerRef.current.innerHTML = '<p>Failed to load video. Please try again later.</p>';
      }
    }
    // Append the script to the body
    document.body.appendChild(script)

    // Cleanup on component unmount
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose(); // Properly dispose of the player
      }
      document.body.removeChild(script); // Remove the script from the DOM
      if (videoContainerRef.current) {
        videoContainerRef.current.innerHTML = ''; // Clear the video-js tag
      }
    };
  }, []); // Empty dependency array to ensure it only runs once on mount

  return <div ref={videoContainerRef} className='aspect-video' />
}

export default BrightCoveVideoPlayer
