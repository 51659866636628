import { InjectionToken } from "@angular/core";

import { Term } from "./term";
import { AppTheme } from "../util/themeSupports";

/** LocalitiesCollection injector token */
export const LOCALITIES = new InjectionToken<LocalitiesCollection>(
  "LOCALITIES",
);

/**
 * Localities Collection
 */
export class LocalitiesCollection {
  protected allTerms: CountryTerm[] | LocalityTerm[];

  constructor(terms: any[]) {
    this.allTerms = terms.map((t) =>
      t.parent === 0 ? new CountryTerm(t) : new LocalityTerm(t),
    );
  }

  public get countries(): CountryTerm[] {
    return this.allTerms.filter((t) => t.parent === 0) as CountryTerm[];
  }
  public get localities(): LocalityTerm[] {
    return this.allTerms.filter((t) => t.parent !== 0);
  }
}

/**
 * Localities Collection Provider Factory
 */
export const localitiesCollectionFactory = (appTheme: AppTheme) =>
  new LocalitiesCollection(appTheme.value("localities") ?? []);

/**
 * Localities Collection Provider object
 */
export const localitiesCollectionProvider = {
  provide: LOCALITIES,
  useFactory: localitiesCollectionFactory,
  deps: [AppTheme],
};

export class CountryTerm extends Term {
  public lat: number;
  public lng: number;
  public zoom: number;
  public locality_type_label: string;
  public currency: string;
  public measurement: string;
}

export class LocalityTerm extends Term {}
