import { Injectable } from "@angular/core";
import {
  Router,
  ResolveStart,
  NavigationEnd,
  GuardsCheckEnd,
  ActivationEnd,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";

const getPageLoaderTarget = (): HTMLElement => {
  const targetElement = document.querySelectorAll("*[page-loading-target]");
  if (!targetElement.length) {
    return document.body;
  }
  return targetElement.item(targetElement.length - 1) as HTMLElement;
};

@Injectable({
  providedIn: "root",
})
export class PageLoadingService {
  private loader: HTMLElement;

  private initialLoad: boolean;

  private isResolved: boolean;
  private isLoadingSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  constructor(private router: Router) {
    this.router.events.pipe().subscribe((event) => {
      // show loading after validate permission
      if (event instanceof GuardsCheckEnd) {
        if (event.shouldActivate) {
          this.show();
        }
      }

      /**
       * Navigation End
       */
      if (event instanceof NavigationEnd) {
        if (this.isResolved) {
          this.hide();
          this.isResolved = false;
        }
      }

      /**
       * Resolve End (scroll to top + hide loader)
       */
      if (event instanceof ActivationEnd) {
        this.isResolved = true;
      }

      /**
       * Resolve Start (show loader)
       * I think the best way to determine if we are navigating within the same section is to use the segments from the current and previous navigation
       *
       */
      if (event instanceof ResolveStart) {
        this.isResolved = false;
      }
    });
  }

  // Observable to listen to the loading state
  isLoading(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  private setLoaderState(isLoading: boolean) {
    this.isLoadingSubject.next(isLoading);
  }

  public getLoader(): HTMLElement {
    if (!this.loader) {
      this.loader = document.querySelector("page-loading");
    }
    return this.loader;
  }

  public show(): boolean {
    const target: HTMLElement = getPageLoaderTarget();

    const loader: HTMLElement = this.getLoader();

    if (!loader) {
      return false;
    }

    target.appendChild(loader);

    document.documentElement.classList.add("loading");

    this.setLoaderState(true);
    const contentContainer = document.getElementById("contentContainer");
    if (contentContainer) contentContainer.scrollTop = 0;
    return true;
  }
  public hide(): void {
    if (!this.initialLoad) {
      document.documentElement.classList.remove("preloading");
      const preloader: HTMLElement = document.getElementById("preloader");
      preloader.parentElement.removeChild(preloader);
      this.initialLoad = true;
    }

    const loader: HTMLElement = this.getLoader();
    if (loader?.parentElement) {
      loader.parentElement.removeChild(loader);
    }
    this.setLoaderState(false);
    document.documentElement.classList.remove("loading");
  }
}
