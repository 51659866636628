import { Subject } from "rxjs";

const getFSChangeEvent = () => {
  if (document["fullscreenEnabled"]) {
    return "fullscreenchange";
  }
  if (document["webkitFullscreenEnabled"]) {
    return "webkitfullscreenchange";
  }
  if (document["mozFullScreenEnabled"]) {
    return "mozfullscreenchange";
  }
  if (document["onmsfullscreenchange"]) {
    return "msfullscreenchange";
  }
  return null;
};

export class FullScreen {
  static subscriberMaps: any = {
    open: {},
    close: {},
  };

  static openEvents: Subject<any> = new Subject();
  static closeEvents: Subject<any> = new Subject();

  static change = getFSChangeEvent();
  static enabled: boolean =
    document.fullscreenEnabled ||
    document["mozFullScreenEnabled"] ||
    document["webkitFullscreenEnabled"];

  static init(): void {
    document.addEventListener(getFSChangeEvent(), (e) =>
      FullScreen.onScreenChangeEvent(e),
    );
  }

  static on(event: string, cb) {
    if (event === "open") {
      FullScreen.subscriberMaps.open[cb] = FullScreen.openEvents.subscribe(cb);
    } else if (event === "close") {
      FullScreen.subscriberMaps.close[cb] =
        FullScreen.closeEvents.subscribe(cb);
    }
  }

  static off(event: string, cb) {
    if (event === "open" && FullScreen.subscriberMaps.open[cb]) {
      FullScreen.subscriberMaps.open[cb].unsubscribe();
    } else if (event === "close" && FullScreen.subscriberMaps.close[cb]) {
      FullScreen.subscriberMaps.close[cb].unsubscribe();
    }
  }

  static onScreenChangeEvent(e: Event): void {
    if (FullScreen.isFullScreen()) {
      FullScreen.openEvents.next(null);
    } else {
      FullScreen.closeEvents.next(null);
    }
  }

  static open(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }
  static close() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document["mozCancelFullScreen"]) {
      document["mozCancelFullScreen"]();
    } else if (document["webkitExitFullscreen"]) {
      document["webkitExitFullscreen"]();
    }
  }

  static isFullScreen() {
    const fullscreenElement =
      document["fullscreenElement"] ||
      document["mozFullScreenElement"] ||
      document["webkitFullscreenElement"];
    return fullscreenElement !== null;
  }
}

FullScreen.init();
